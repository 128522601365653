/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 1/13/2021
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import * as singleActions from '../../../store/actions/single';
import Select from '../../Common/Select';
import FormButtons from '../../Common/FormButtons';
import { Add } from 'react-ionicons';
import Table from 'react-bootstrap/Table';
import { Trash } from 'react-ionicons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DeleteModalContainer from '../../Common/DeleteModal';
import { List } from 'react-ionicons';

const schema = yup.object({
  name: yup.string().required('This field is required')
});

const ages = [
  {
    id: 'all',
    name: 'All Ages'
  },
  {
    id: 'only5',
    name: '5 years'
  },
  {
    id: 'only6',
    name: '6 years'
  },
  {
    id: 'only7',
    name: '7 years'
  },
  {
    id: 'only8',
    name: '8 years'
  },
  {
    id: 'only9',
    name: '9 years'
  },
  {
    id: 'only10',
    name: '10 years'
  },
  {
    id: 'only11',
    name: '11 years'
  },
  {
    id: 'only12',
    name: '12 years'
  },
  {
    id: 'only13',
    name: '13 years'
  },
  {
    id: 'only14',
    name: '14 years'
  },
  {
    id: 'only15',
    name: '15 years'
  },
  {
    id: 'only16',
    name: '16 years'
  },
  {
    id: 'only17',
    name: '17 years'
  },
  {
    id: 'only18',
    name: '18 years'
  },
  {
    id: 'only19',
    name: '19 years'
  },
  {
    id: 'only20',
    name: '20 years'
  },
  {
    id: 'only21',
    name: '21 years'
  },
  {
    id: 'U5',
    name: 'Under 5'
  },
  {
    id: 'U6',
    name: 'Under 6'
  },
  {
    id: 'U7',
    name: 'Under 7'
  },
  {
    id: 'U8',
    name: 'Under 8'
  },
  {
    id: 'U9',
    name: 'Under 9'
  },
  {
    id: 'U10',
    name: 'Under 10'
  },
  {
    id: 'U11',
    name: 'Under 11'
  },
  {
    id: 'U12',
    name: 'Under 12'
  },
  {
    id: 'U13',
    name: 'Under 13'
  },
  {
    id: 'U14',
    name: 'Under 14'
  },
  {
    id: 'U15',
    name: 'Under 15'
  },
  {
    id: 'U16',
    name: 'Under 16'
  },
  {
    id: 'U17',
    name: 'Under 17'
  },
  {
    id: 'U18',
    name: 'Under 18'
  },
  {
    id: 'U19',
    name: 'Under 19'
  },
  {
    id: 'U20',
    name: 'Under 20'
  },
  {
    id: 'U21',
    name: 'Under 21'
  },
  {
    id: '8&O',
    name: '8 & Over'
  },
  {
    id: '9&O',
    name: '9 & Over'
  },
  {
    id: '10&O',
    name: '10 & Over'
  },
  {
    id: '11&O',
    name: '11 & Over'
  },
  {
    id: '12&O',
    name: '12 & Over'
  },
  {
    id: '13&O',
    name: '13 & Over'
  },
  {
    id: '14&O',
    name: '14 & Over'
  },
  {
    id: '15&O',
    name: '15 & Over'
  },
  {
    id: '16&O',
    name: '16 & Over'
  },
  {
    id: '17&O',
    name: '17 & Over'
  },
  {
    id: '18&O',
    name: '18 & Over'
  },
  {
    id: '19&O',
    name: '19 & Over'
  },
  {
    id: '20&O',
    name: '20 & Over'
  },
  {
    id: '21&O',
    name: '21 & Over'
  },
  {
    id: '22&O',
    name: '22 & Over'
  },
  {
    id: '23&O',
    name: '23 & Over'
  },
  {
    id: '5-6',
    name: '5 and 6'
  },
  {
    id: '6-7',
    name: '6 and 7'
  },
  {
    id: '7-8',
    name: '7 and 8'
  },
  {
    id: '8-9',
    name: '8 and 9'
  },
  {
    id: '9-10',
    name: '9 and 10'
  },
  {
    id: '10-11',
    name: '10 and 11'
  },
  {
    id: '11-12',
    name: '11 and 12'
  },
  {
    id: '12-13',
    name: '12 and 13'
  },
  {
    id: '13-14',
    name: '13 and 14'
  },
  {
    id: '14-15',
    name: '14 and 15'
  },
  {
    id: '15-16',
    name: '15 and 16'
  },
  {
    id: '16-17',
    name: '16 and 17'
  },
  {
    id: '17-18',
    name: '17 and 18'
  },
  {
    id: '18-19',
    name: '18 and 19'
  },
  {
    id: '19-20',
    name: '19 and 20'
  },
  {
    id: '20-21',
    name: '20 and 21'
  },
  {
    id: '21-22',
    name: '21 and 22'
  },
  {
    id: '5-7',
    name: '5 to 7'
  },
  {
    id: '6-8',
    name: '6 to 8'
  },
  {
    id: '7-9',
    name: '7 to 9'
  },
  {
    id: '8-10',
    name: '8 to 10'
  },
  {
    id: '9-11',
    name: '9 to 11'
  },
  {
    id: '10-12',
    name: '10 to 12'
  },
  {
    id: '11-13',
    name: '11 to 13'
  },
  {
    id: '11-14',
    name: '11 - 14'
  },
  {
    id: '12-14',
    name: '12 to 14'
  },
  {
    id: '13-15',
    name: '13 to 15'
  },
  {
    id: '14-16',
    name: '14 to 16'
  },
  {
    id: '15-17',
    name: '15 to 17'
  },
  {
    id: '16-18',
    name: '16 to 18'
  },
  {
    id: '17-19',
    name: '17 to 19'
  },
  {
    id: '18-20',
    name: '18 to 20'
  },
  {
    id: '19-21',
    name: '19 to 21'
  },
  {
    id: '20-22',
    name: '20 to 22'
  },
  {
    id: '21-23',
    name: '21 to 23'
  }
];

class Grades extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileSelectField: '',
      filePickerOpen: false,
      selectingFileType: 'image',
      preview: '',
      image: '',
      createModerator: '',
      createAdmin: '',
      deleteColumn: {},
      deleteRow: {},
      deleteCompetitions: [],
      deleteType: 'deleteColumn'
    };
  }

  handleFormModal = (e) => {
    this.setState({ filePickerOpen: false });
  };

  selectFile = (file) => {
    //this.props.updateField('image', file);
    this.props.setValues({ image: file });
  };

  handleSubmit = (values, direction) => {
    let view = 'events';

    let data = new FormData();

    let params = {
      grades: values.grades,
      eventId: this.props.single.single._id,
      direction: direction
    };
    //console.log("GRAD", values.grades);

    data.set('data', JSON.stringify(params));

    if (params._id) {
      this.props.patch(view, params._id, data, this.props.auth.token._id);
    } else {
      if (this.props.single.single._id) {
        this.props.patch(
          view,
          this.props.single.single._id,
          data,
          this.props.auth.token._id,
          (id) => {
            this.props.history.push('/admin/events/' + id);
          }
        );
      } else {
        this.props.post(view, data, this.props.auth.token._id, (id) => {
          this.props.history.push('/admin/events/' + id);
        });
      }
    }
  };

  render() {
    return (
      <div className={'grades-table-view'}>
        <Formik
          validationSchema={schema}
          onSubmit={this.handleSubmit}
          enableReinitialize
          initialValues={{
            grades: this.props.single.single.grades || []
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
            setFieldValue
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <DeleteModalContainer
                deleteRow={this.state.deleteRow}
                deleteCategory={this.state.deleteCategory}
                deleteColumn={this.state.deleteColumn}
                deleteCompetitions={this.state.deleteCompetitions}
                show={this.state.showDeleteModal}
                deleteType={this.state.deleteType}
                hideModal={(e) => {
                  this.setState({
                    deleteColumn: {},
                    deleteRow: {},
                    deleteCategory: {},
                    deleteCompetitions: [],
                    showDeleteModal: e
                  });
                }}
                onDelete={(e) => {
                  //console.log("DELETING");
                  if (this.state.deleteType === 'deleteColumn') {
                    setFieldValue(
                      'grades',
                      values.grades.map((grade, gradeIndex) => {
                        if (
                          grade.columns.filter((c) => c.id === this.state.deleteColumn.id).length >
                          0
                        ) {
                          return {
                            ...grade,
                            changed: true,
                            columns: grade.columns.filter(
                              (c) => c.id !== this.state.deleteColumn.id
                            )
                          };
                        } else {
                          return grade;
                        }
                      })
                    );
                  } else if (this.state.deleteType === 'deleteCategory') {
                    setFieldValue(
                      'grades',
                      values.grades.map((grade, gradeIndex) => {
                        if (grade._id === this.state.deleteCategory._id) {
                          return {
                            ...grade,
                            changed: true,
                            deleted: true,
                            rows: grade.rows.filter((c) => c.id !== this.state.deleteRow.id)
                          };
                        } else {
                          return grade;
                        }
                      })
                    );
                  } else {
                    setFieldValue(
                      'grades',
                      values.grades.map((grade, gradeIndex) => {
                        if (grade.rows.filter((c) => c.id === this.state.deleteRow.id).length > 0) {
                          return {
                            ...grade,
                            changed: true,
                            rows: grade.rows.filter((c) => c.id !== this.state.deleteRow.id)
                          };
                        } else {
                          return grade;
                        }
                      })
                    );
                  }
                  this.setState({
                    deleteColumn: {},
                    deleteRow: {},
                    deleteCompetitions: [],
                    showDeleteModal: false
                  });
                }}
              />
              <FormButtons
                back={(e) => {
                  e.preventDefault();
                  this.handleSubmit(values, 'subtract');
                }}
                showPrevious={false}
                showRevert={false}
                showNext={false}
                showBack={true}
                confirm={(e) => {
                  e.preventDefault();
                  this.handleSubmit(values);
                }}
                showPreview={true}
                onShowPreview={(e) => {}}
                onBack={(e) => {
                  if (this.props.single.single._id) {
                    this.props.history.push(`/app/event-dashboard/${this.props.single.single._id}`);
                  } else {
                    this.props.history.push(`/app/event-dashboard`);
                  }
                }}
                showDefault={true}
              />

              {values.grades
                ? values.grades.map((grade, i) => {
                    console.log('GRADE', grade);

                    if (grade.deleted) {
                      return null;
                    }
                    return (
                      <div key={i}>
                        {i > 0 ? <div className={'divider'} /> : null}
                        <div className={'spacer'} />
                        <div>
                          <Button
                            variant={'flat raised'}
                            onClick={(e) => {
                              setFieldValue(
                                'grades',
                                values.grades.map((gra) => {
                                  if (gra._id === grade._id) {
                                    let rowId = Date.now()
                                      .toString()
                                      .split('')
                                      .reverse()
                                      .join('')
                                      .substring(1, 4);
                                    let comps = gra.competitions;
                                    let row = {
                                      id: parseInt(rowId),
                                      title: ''
                                    };
                                    gra.columns.map((r, roo) => {
                                      return comps.push({
                                        row: row.id,
                                        column: r.id,
                                        columnTitle: row.title,
                                        rowTitle: r.title,
                                        code: '',
                                        _id: Date.now().toString() + roo.toString(),
                                        hasFakeId: true
                                      });
                                    });
                                    return {
                                      ...gra,
                                      rows: gra.rows.concat(row),
                                      competitions: comps,
                                      changed: true
                                    };
                                  } else {
                                    return gra;
                                  }
                                })
                              );
                            }}>
                            <List /> Add Row
                          </Button>{' '}
                          &nbsp;
                          <Button
                            variant={'flat raised'}
                            onClick={(e) => {
                              // motdse

                              setFieldValue(
                                'grades',
                                values.grades.map((gr) => {
                                  if (gr._id === grade._id) {
                                    let colId = Date.now()
                                      .toString()
                                      .split('')
                                      .reverse()
                                      .join('')
                                      .substring(1, 4);
                                    let comps = gr.competitions;
                                    let column = {
                                      id: colId,
                                      title: ''
                                    };
                                    gr.rows.map((r, roo) => {
                                      return comps.push({
                                        row: r.id,
                                        column: column.id,
                                        columnTitle: column.title,
                                        rowTitle: r.title,
                                        code: '',
                                        _id: Date.now().toString() + roo.toString(),
                                        hasFakeId: true
                                      });
                                    });
                                    return {
                                      ...gr,
                                      columns: gr.columns.concat(column),
                                      competitions: comps,
                                      changed: true
                                    };
                                  } else {
                                    return gr;
                                  }
                                })
                              );
                            }}>
                            <Add /> Add Column
                          </Button>
                        </div>
                        <div className={'spacer'} />
                        <Row>
                          <Col>
                            <div>
                              <div className={'field-container'}>
                                <Form.Group controlId="name">
                                  <Form.Label>Competition Type</Form.Label>
                                  <Select
                                    items={[
                                      {
                                        id: 'first',
                                        name: 'First Feis'
                                      },
                                      {
                                        id: 'grades',
                                        name: 'Grades Solo'
                                      },
                                      {
                                        id: 'trophy',
                                        name: 'Grades Trophy'
                                      },
                                      {
                                        id: 'special',
                                        name: 'Grades Special'
                                      },
                                      {
                                        id: 'teams',
                                        name: 'Teams'
                                      },
                                      {
                                        id: 'adultteams',
                                        name: 'Adult Teams'
                                      },

                                      {
                                        id: 'allspecial',
                                        name: 'All Levels Special'
                                      },
                                      {
                                        id: 'championship',
                                        name: 'Championships'
                                      },
                                      {
                                        id: 'championshipspecial',
                                        name: 'Championship Special'
                                      }
                                    ]}
                                    value={grade.type ? { id: grade.type } : {} || {}}
                                    itemValue={'id'}
                                    onChange={(val) => {
                                      setFieldValue(
                                        'grades',
                                        values.grades.map((gr) => {
                                          if (gr._id === grade._id) {
                                            return {
                                              ...gr,
                                              type: val.id,
                                              changed: true
                                            };
                                          } else {
                                            return gr;
                                          }
                                        })
                                      );
                                    }}
                                    displayValue={'name'}
                                  />
                                </Form.Group>
                              </div>
                            </div>

                            <div>
                              <div className={'field-container'}>
                                <Form.Group controlId="name">
                                  <Form.Label>Recall Type</Form.Label>
                                  <Select
                                    items={[
                                      {
                                        id: 'NONE',
                                        name: 'None'
                                      },
                                      {
                                        id: 'ALL',
                                        name: 'All Recall'
                                      },
                                      {
                                        id: 'PERCENT',
                                        name: 'Percent Recall'
                                      }
                                    ]}
                                    value={grade.recallType ? { id: grade.recallType } : {} || {}}
                                    itemValue={'id'}
                                    onChange={(val) => {
                                      setFieldValue(
                                        'grades',
                                        values.grades.map((gr) => {
                                          if (gr._id === grade._id) {
                                            return {
                                              ...gr,
                                              recallType: val.id,
                                              changed: true
                                            };
                                          } else {
                                            return gr;
                                          }
                                        })
                                      );
                                    }}
                                    displayValue={'name'}
                                  />
                                </Form.Group>
                              </div>
                            </div>

                            <Col md={12}>
                              <div className={'field-container'}>
                                <Form.Check
                                  type="checkbox"
                                  className="mb-2"
                                  label="Apply Family Maximum"
                                  name="inPerson"
                                  value={grade.applyFamilyMax}
                                  checked={grade.applyFamilyMax}
                                  onChange={(val) => {
                                    setFieldValue(
                                      'grades',
                                      values.grades.map((gr) => {
                                        if (gr._id === grade._id) {
                                          return {
                                            ...gr,
                                            applyFamilyMax: gr.applyFamilyMax ? false : true,
                                            changed: true
                                          };
                                        } else {
                                          return gr;
                                        }
                                      })
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  isValid={touched.applyFamilyMax && !errors.applyFamilyMax}
                                />
                              </div>
                            </Col>
                          </Col>
                          <Col>
                            <div>
                              <div className={'field-container'}>
                                <Form.Group controlId="name">
                                  <Form.Label>Price</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="rounds"
                                    placeholder="Entry Price"
                                    value={grade.price || 0}
                                    onChange={(e) => {
                                      setFieldValue(
                                        'grades',
                                        values.grades.map((gr) => {
                                          if (gr._id === grade._id) {
                                            return {
                                              ...gr,
                                              price: e.target.value,
                                              changed: true
                                            };
                                          } else {
                                            return gr;
                                          }
                                        })
                                      );
                                    }}
                                    onBlur={handleBlur}
                                    isValid={grade.rounds !== 0}
                                    className={`form-control ${
                                      grade.rounds === '' ? 'is-invalid' : ''
                                    }`}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                            <div>
                              <div className={'field-container'}>
                                <Form.Group controlId="name">
                                  <Form.Label>Scoring System</Form.Label>

                                  <Select
                                    disableTyping={true}
                                    items={this.props.single.scoring}
                                    value={grade.scoring}
                                    itemValue={'_id'}
                                    onChange={(val) => {
                                      setFieldValue(
                                        'grades',
                                        values.grades.map((gr) => {
                                          if (gr._id === grade._id) {
                                            return {
                                              ...gr,
                                              scoring: val,
                                              changed: true
                                            };
                                          } else {
                                            return gr;
                                          }
                                        })
                                      );
                                    }}
                                    displayValue={'name'}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                          </Col>
                          <Col>
                            {grade.type ? (
                              <div>
                                <div className={'field-container'}>
                                  <Form.Group controlId="name">
                                    <Form.Label>Number of rounds</Form.Label>
                                    <Form.Control
                                      type="number"
                                      name="rounds"
                                      placeholder="Number of rounds"
                                      value={grade.rounds || 1}
                                      onChange={(e) => {
                                        setFieldValue(
                                          'grades',
                                          values.grades.map((gr) => {
                                            if (gr._id === grade._id) {
                                              return {
                                                ...gr,
                                                rounds: e.target.value,
                                                changed: true
                                              };
                                            } else {
                                              return gr;
                                            }
                                          })
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      isValid={grade.rounds !== 0}
                                      className={`form-control ${
                                        grade.rounds === '' ? 'is-invalid' : ''
                                      }`}
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                            ) : null}

                            {grade.type ? (
                              <div>
                                <div className={'field-container'}>
                                  <Form.Group controlId="name">
                                    <Form.Label>Number of judges</Form.Label>
                                    <Form.Control
                                      type="number"
                                      name="judges"
                                      placeholder="Number of judges"
                                      value={grade.judges || 1}
                                      onChange={(e) => {
                                        setFieldValue(
                                          'grades',
                                          values.grades.map((gr) => {
                                            if (gr._id === grade._id) {
                                              return {
                                                ...gr,
                                                judges: e.target.value,
                                                changed: true
                                              };
                                            } else {
                                              return gr;
                                            }
                                          })
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      isValid={grade.judges !== 0}
                                      className={`form-control ${
                                        grade.judges === '' ? 'is-invalid' : ''
                                      }`}
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                            ) : null}
                          </Col>
                        </Row>

                        <div className={'spacer'} />
                        <div className={'table-container'}>
                          <Table key={i} striped bordered hover>
                            <thead>
                              <tr>
                                <th>
                                  <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Enter table name"
                                    value={grade.title}
                                    onChange={(e) => {
                                      setFieldValue(
                                        'grades',
                                        values.grades.map((gr) => {
                                          if (gr._id === grade._id) {
                                            return {
                                              ...gr,
                                              title: e.target.value,
                                              changed: true
                                            };
                                          } else {
                                            return gr;
                                          }
                                        })
                                      );
                                    }}
                                    onBlur={handleBlur}
                                    isValid={grade.title !== ''}
                                    className={`form-control form-control-mini ${
                                      grade.title === '' ? 'is-invalid' : ''
                                    }`}
                                  />
                                </th>
                                {grade.columns.map((col, o) => {
                                  return (
                                    <th key={o} style={{ position: 'relative' }}>
                                      <Button
                                        variant={'flat'}
                                        onClick={(e) => {
                                          // DELETE COL AND COMPS
                                          // SOMEHOW PERSIST

                                          this.setState({
                                            deleteColumn: col,
                                            deleteCompetitions: grade.competitions.filter(
                                              (dcomp) => parseInt(dcomp.column) === parseInt(col.id)
                                            ),
                                            showDeleteModal: true,
                                            deleteType: 'deleteColumn'
                                          });
                                        }}>
                                        <Trash />
                                      </Button>

                                      <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder="Age Group"
                                        value={col.title}
                                        onChange={(e) => {
                                          setFieldValue(
                                            'grades',
                                            values.grades.map((gr) => {
                                              if (gr._id === grade._id) {
                                                return {
                                                  ...gr,
                                                  columns: gr.columns.map((rrr, rrri) => {
                                                    if (o === rrri) {
                                                      return {
                                                        ...rrr,
                                                        id: rrr.id,
                                                        title: e.target.value
                                                      };
                                                    } else {
                                                      return rrr;
                                                    }
                                                  }),
                                                  competitions: gr.competitions.map((com) => {
                                                    if (
                                                      parseInt(com.column) ===
                                                      parseInt(gr.columns[o].id)
                                                    ) {
                                                      return {
                                                        ...com,
                                                        columnTitle: e.target.value,
                                                        changed: true
                                                      };
                                                    } else {
                                                      return com;
                                                    }
                                                  }),
                                                  changed: true,
                                                  compChanged: true
                                                };
                                              } else {
                                                return gr;
                                              }
                                            })
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        isValid={col !== ''}
                                        className={`form-control form-control-mini ${
                                          col === '' ? 'is-invalid' : ''
                                        }`}
                                      />
                                      <br />
                                      <Select
                                        items={ages}
                                        value={col.age ? { id: col.age } : {} || {}}
                                        itemValue={'id'}
                                        onChange={(val) => {
                                          setFieldValue(
                                            'grades',
                                            values.grades.map((gr) => {
                                              console.log('Changing', val);
                                              if (gr._id === grade._id) {
                                                console.log('matched id');
                                                return {
                                                  ...gr,
                                                  columns: gr.columns.map((rrr, rrri) => {
                                                    if (o === rrri) {
                                                      return {
                                                        ...rrr,
                                                        id: rrr.id,
                                                        age: val.id
                                                      };
                                                    } else {
                                                      return rrr;
                                                    }
                                                  }),
                                                  competitions: gr.competitions.map((com) => {
                                                    console.log(com.column, gr.columns[o].id);
                                                    if (
                                                      parseInt(com.column) ===
                                                      parseInt(gr.columns[o].id)
                                                    ) {
                                                      console.log('Matched', com, val);
                                                      return {
                                                        ...com,

                                                        columnTitle: val.id,
                                                        changed: true
                                                      };
                                                    } else {
                                                      return com;
                                                    }
                                                  }),
                                                  changed: true,
                                                  compChanged: true
                                                };
                                              } else {
                                                return gr;
                                              }
                                            })
                                          );
                                        }}
                                        displayValue={'name'}
                                      />
                                    </th>
                                  );
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {grade.rows.map((row, o) => {
                                return (
                                  <tr key={o}>
                                    <td style={{ position: 'relative' }}>
                                      <Button
                                        variant={'flat'}
                                        className={'delete-row'}
                                        onClick={(e) => {
                                          // DELETE COL AND COMPS
                                          // SOMEHOW PERSIST

                                          this.setState({
                                            deleteRow: row,
                                            deleteCompetitions: grade.competitions.filter(
                                              (dcomp) => parseInt(dcomp.row) === parseInt(row.id)
                                            ),
                                            showDeleteModal: true,
                                            deleteType: 'deleteRow'
                                          });
                                        }}>
                                        <Trash />
                                      </Button>

                                      <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder="Dance"
                                        value={row.title}
                                        onChange={(e) => {
                                          setFieldValue(
                                            'grades',
                                            values.grades.map((gr) => {
                                              if (gr._id === grade._id) {
                                                return {
                                                  ...gr,
                                                  rows: gr.rows.map((rrr, rrri) => {
                                                    if (o === rrri) {
                                                      return {
                                                        id: rrr.id,
                                                        title: e.target.value
                                                      };
                                                    } else {
                                                      return rrr;
                                                    }
                                                  }),
                                                  competitions: gr.competitions.map((com) => {
                                                    if (
                                                      parseInt(com.row) === parseInt(gr.rows[o].id)
                                                    ) {
                                                      return {
                                                        ...com,
                                                        rowTitle: e.target.value,
                                                        changed: true
                                                      };
                                                    } else {
                                                      return com;
                                                    }
                                                  }),
                                                  changed: true,
                                                  compChanged: true
                                                };
                                              } else {
                                                return gr;
                                              }
                                            })
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        isValid={row !== ''}
                                        className={`form-control form-control-mini ${
                                          row === '' ? 'is-invalid' : ''
                                        }`}
                                      />
                                    </td>
                                    {grade.columns
                                      ? grade.columns.map((col, coli) => {
                                          if (grade.competitions) {
                                            return grade.competitions
                                              .filter(
                                                (c) =>
                                                  parseInt(c.row) === parseInt(row.id) &&
                                                  parseInt(c.column) === parseInt(col.id)
                                              )
                                              .map((c, co) => {
                                                return (
                                                  <td key={co}>
                                                    <Form.Control
                                                      type="text"
                                                      name="name"
                                                      placeholder="Competition"
                                                      value={c.code}
                                                      onChange={(e) => {
                                                        setFieldValue(
                                                          'grades',
                                                          values.grades.map((gr) => {
                                                            if (gr._id === grade._id) {
                                                              return {
                                                                ...gr,
                                                                competitions: gr.competitions.map(
                                                                  (com) => {
                                                                    if (com._id === c._id) {
                                                                      return {
                                                                        ...com,
                                                                        code: e.target.value,
                                                                        changed: true
                                                                      };
                                                                    } else {
                                                                      return com;
                                                                    }
                                                                  }
                                                                ),
                                                                changed: true,
                                                                compChanged: true
                                                              };
                                                            } else {
                                                              return gr;
                                                            }
                                                          })
                                                        );
                                                      }}
                                                      onBlur={handleBlur}
                                                      isValid={c.code !== ''}
                                                      className={`form-control form-control-mini ${
                                                        c.code === '' ? 'is-invalid' : ''
                                                      }`}
                                                    />
                                                  </td>
                                                );
                                              });
                                          } else {
                                            return null;
                                          }
                                        })
                                      : null}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>

                        <br />

                        <Button
                          variant={'danger raised'}
                          onClick={(e) => {
                            this.setState({
                              deleteCategory: grade,
                              deleteCompetitions: grade.competitions.filter(
                                (dcomp) => dcomp.grade === grade._id
                              ),
                              showDeleteModal: true,
                              deleteType: 'deleteCategory'
                            });
                          }}>
                          <Trash /> Delete Category
                        </Button>
                        <div className={'spacer'} />
                      </div>
                    );
                  })
                : null}
              <div className={'spacer'} />
              <div className={'divider'} />
              <div className={'spacer'} />

              <Button
                variant={'flat raised'}
                onClick={(e) => {
                  setFieldValue(
                    'grades',
                    values.grades.concat({
                      title: '',
                      rows: [],
                      columns: [],
                      competitions: [],
                      added: true,
                      _id: Date.now(),
                      hasFakeId: true
                    })
                  );
                }}>
                <Add /> Add Category
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  single: state.single,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  post: (view, data, token, redirect) => {
    dispatch(singleActions.postFormData(view, data, token, true, redirect, true));
  },
  postOnboarding: (view, data, token, redirect) => {
    dispatch(singleActions.postNextStep(view, data, token, true, redirect));
  },
  patch: (view, id, data, token) => {
    dispatch(singleActions.patchFormData(view, id, data, token, false, null, true));
  },
  get: (id, token) => {
    dispatch(singleActions.getSingle('events', id, token));
  },
  setBlankSingle: () => {
    dispatch(singleActions.setSingleEntry({ step: 0 }));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Grades));

class GradesContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default GradesContainer;
