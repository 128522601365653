import Api from '../../utils/Api';
import { NotificationManager } from 'react-notifications';

export function callRemoteObjects(data, token) {
  return (dispatch) => {
    const api = new Api(token);

    let query = data.query || {};
    let or = [];
    if (data.k) {
      or = data.k.map((ke) => {
        return {
          [ke]: data.val
        };
      });
    }
    let route = data.collection;
    if (data.urlRoute) {
      route += data.urlRoute;
    }
    api.get(route, { or, query }, (res) => {
      console.log('Get remopte obhects', res);
      if (res.status === 200) {
        if (res.data.status === 200) {
          try {
            dispatch({
              type: 'GOT_REMOTE_DATA',
              payload: {
                data: res.data,
                info: data
              }
            });
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        }
      }
    });
  };
}

export function setSingleEntry(entry) {
  return (dispatch) => {
    try {
      dispatch({
        type: 'SET_SINGLE_ENTRY_LOADING',
        payload: true
      });
      dispatch({
        type: 'SET_SINGLE_ENTRY',
        payload: entry
      });
    } catch (err) {
      NotificationManager.error('Please reload this view and try again.', 'An Error Occured');
    }
  };
}

export function setSingleEntryHard(entry) {
  return (dispatch) => {
    try {
      dispatch({
        type: 'SET_SINGLE_ENTRY_LOADING',
        payload: true
      });
      dispatch({
        type: 'SET_SINGLE_ENTRY_HARD',
        payload: entry
      });
    } catch (err) {
      NotificationManager.error('Please reload this view and try again.', 'An Error Occured');
    }
  };
}

export function getBlankSingle(view, data) {
  return (dispatch) => {
    dispatch({
      type: 'SET_SINGLE_ENTRY_LOADING',
      payload: true
    });
    const api = new Api(data.token);
    api.get(view + '/new', { ...data }, (res) => {
      if (res.status === 200) {
        try {
          dispatch({
            type: 'GOT_SINGLE_WITH_MUSICDATA',
            payload: res.data
          });
        } catch (err) {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      }
    });
  };
}

export function getSingleAlbum(id, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.get('albums', { id }, (res) => {
      if (res.status === 200) {
        if (res.data.status === 200) {
          try {
            dispatch({
              type: 'SET_SINGLE_ENTRY',
              payload: res.data.data
            });
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        } else {
          try {
            dispatch({
              type: 'SET_SINGLE_ENTRY',
              payload: {}
            });
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        }
      }
    });
  };
}

export function getSingleArtist(id, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.get('artists', { id }, (res) => {
      if (res.status === 200) {
        if (res.data.status === 200) {
          try {
            dispatch({
              type: 'SET_SINGLE_ENTRY',
              payload: res.data.data
            });
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        } else {
          try {
            dispatch({
              type: 'SET_SINGLE_ENTRY',
              payload: {}
            });
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        }
      }
    });
  };
}

export function getFullResults(id, type, token) {
  return (dispatch) => {
    const api = new Api(token);
    dispatch({
      type: 'SET_SINGLE_ENTRY_LOADING',
      payload: true
    });
    api.post('events/getMultiFullResult', { id, type }, (res) => {
      if (res.status === 200) {
        if (res.data.status === 200) {
          try {
            dispatch({
              type: 'GOT_FULL_RESULT',
              payload: res.data.data || res.data
            });
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        } else {
          try {
            dispatch({
              type: 'GOT_FULL_RESULT',
              payload: {}
            });
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        }
      }
      dispatch({
        type: 'SET_SINGLE_ENTRY_LOADING',
        payload: false
      });
    });
  };
}

export function getSingleResult(id, token) {
  return (dispatch) => {
    const api = new Api(token);
    dispatch({
      type: 'SET_SINGLE_ENTRY_LOADING',
      payload: true
    });
    api.post('events/getFullResult', { id }, (res) => {
      if (res.status === 200) {
        if (res.data.status === 200) {
          try {
            dispatch({
              type: 'GOT_FULL_RESULT',
              payload: res.data.data || res.data
            });
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        } else {
          try {
            dispatch({
              type: 'GOT_FULL_RESULT',
              payload: {}
            });
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        }
      }
      dispatch({
        type: 'SET_SINGLE_ENTRY_LOADING',
        payload: false
      });
    });
  };
}

export function getSingle(view, id, token) {
  return (dispatch) => {
    const api = new Api(token);
    dispatch({
      type: 'SET_SINGLE_ENTRY_LOADING',
      payload: true
    });
    api.get(view, { id }, (res) => {
      if (res.status === 200) {
        console.log('Got single', res.data);

        if (view === 'albums') {
          if (res.status === 200) {
            try {
              dispatch({
                type: 'GOT_SINGLE_WITH_MUSICDATA',
                payload: res.data
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          }
        } else {
          if (res.data.status === 200) {
            try {
              dispatch({
                type: 'SET_SINGLE_ENTRY_HARD',
                payload: res.data.data || res.data
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          } else {
            try {
              dispatch({
                type: 'SET_SINGLE_ENTRY',
                payload: {}
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          }
        }
      }
      dispatch({
        type: 'SET_SINGLE_ENTRY_LOADING',
        payload: false
      });
    });
  };
}

export function getMultiSingle(key, view, id, token) {
  return (dispatch) => {
    const api = new Api(token);
    dispatch({
      type: 'SET_SINGLE_ENTRY_LOADING',
      payload: true
    });
    api.get(view, { id }, (res) => {
      if (res.status === 200) {
        if (view === 'albums') {
          if (res.status === 200) {
            try {
              dispatch({
                type: 'GOT_SINGLE_WITH_MUSICDATA',
                payload: res.data
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          }
        } else {
          if (res.data.status === 200) {
            try {
              dispatch({
                type: 'GOT_REC_SINGLE',
                payload: {
                  ...(res.data.data || res.data),
                  single: res.data.data[key] || res.data[res.data],
                  k: 'entry'
                }
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          } else {
            try {
              dispatch({
                type: 'SET_SINGLE_ENTRY',
                payload: {}
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          }
        }
      }
      dispatch({
        type: 'SET_SINGLE_ENTRY_LOADING',
        payload: false
      });
    });
  };
}

export function updateField(field, val) {
  return (dispatch) => {
    try {
      dispatch({
        type: 'UPDATE_FIELD',
        payload: { field, val }
      });
    } catch (err) {
      NotificationManager.error('Please reload this view and try again', 'An Error Occured');
    }
  };
}

export function loadEntryPay(token) {
  return (dispatch) => {
    const api = new Api(token);

    dispatch({
      type: 'SET_SINGLE_ENTRY_LOADING',
      payload: true
    });
    api.get('users/loadEntryPay', { name: 'nick' }, (res) => {
      if (res.data.status === 200) {
        try {
          dispatch({
            type: 'GOT_ONBOARDING',
            payload: res.data.data
          });
        } catch (err) {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      }
      dispatch({
        type: 'SET_SINGLE_ENTRY_LOADING',
        payload: false
      });
    });
  };
}

export function loadEntryData(eventId, token) {
  return (dispatch) => {
    const api = new Api(token);

    api.post('users/loadEntryData', { eventId }, (res) => {
      if (res.data.status === 200) {
        try {
          dispatch({
            type: 'GOT_ONBOARDING',
            payload: res.data.data
          });
        } catch (err) {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      }
    });
  };
}

export function loadOnboardingNoSingle(token) {
  return (dispatch) => {
    const api = new Api(token);

    api.post('users/loadOnboarding', {}, (res) => {
      if (res.data.status === 200) {
        try {
          dispatch({
            type: 'GOT_ONBOARDING_NO_SINGLE',
            payload: res.data.data
          });
        } catch (err) {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      }
    });
  };
}

export function loadOnboarding(token) {
  return (dispatch) => {
    const api = new Api(token);

    api.post('users/loadOnboarding', {}, (res) => {
      if (res.data.status === 200) {
        try {
          dispatch({
            type: 'GOT_ONBOARDING',
            payload: res.data.data
          });
        } catch (err) {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      }
    });
  };
}

export function postNextStep(uri, obj, token, shouldRedirect, redirect, rec) {
  return (dispatch) => {
    const api = new Api(token);
    dispatch({
      type: 'SET_LOADING',
      payload: true
    });
    api.postFormData(uri, obj, (res) => {
      try {
        if (res.data.data.user) {
          dispatch({
            type: 'UPDATED_USER',
            payload: { data: res.data.data.user }
          });
        } else {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          dispatch({
            type: 'SET_LOADING',
            payload: false
          });
        }
        try {
          if (shouldRedirect) {
            redirect(res.data.data.user._id);
          }
        } catch (err) {}
      } catch (err) {
        dispatch({
          type: 'SET_LOADING',
          payload: false
        });
        NotificationManager.error('Please reload this view and try again', 'An Error Occured');
      }
      dispatch({
        type: 'SET_SINGLE_ENTRY_LOADING',
        payload: false
      });
    });
  };
}

export function postDelete(uri, obj, token, shouldRedirect, redirect, toSingle) {
  return (dispatch) => {
    const api = new Api(token);

    dispatch({
      type: 'SET_UPLOADING',
      payload: {
        id: obj.id,
        uploading: true
      }
    });
    api.post(uri + '/deleteField', obj, (res) => {
      if (res.data.status === 200) {
        try {
          dispatch({
            type: 'DELETED_FIELD_NESTED_SINGLE',
            payload: { data: res.data.data, fieldDeleted: obj, k: 'competitions' }
          });
          NotificationManager.success('Your changes have been saved');
        } catch (err) {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      }
    });
  };
}

export function cancelUpload(entry, round, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.post('entryCompetitions/cancel', { id: entry, round: round }, (res) => {
      dispatch({
        type: 'CANCEL_UPLOAD',
        payload: {
          id: entry,
          round: round
        }
      });
    });
  };
}

export function postFormDataNested(
  uri,
  id,
  obj,
  params,
  token,
  shouldRedirect,
  redirect,
  toSingle
) {
  return (dispatch) => {
    const api = new Api(token);
    dispatch({
      type: 'SET_SINGLE_ENTRY_LOADING',
      payload: true
    });
    dispatch({
      type: 'SET_UPLOADING',
      payload: {
        id: id,
        uploading: true
      }
    });
    api.postFormDataWithProgress(
      uri,
      id,
      obj,
      params,
      (res) => {
        if (res.data.status === 200) {
          try {
            /*
                                        dispatch({
                                            type: 'UPDATED_NESTED_SINGLE',
                                            payload: {data: res.data.data, k: "competitions", round: params.round}
                                        })

                     */
            //NotificationManager.success("Your changes have been saved");
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        }
      },
      (progress) => {
        console.log('UPLOADED:', progress.loaded, ' // TOTAL', progress.total);
        try {
          dispatch({
            type: 'UPDATED_NESTED_PROGRESS',
            payload: {
              data: Math.floor((progress.loaded / progress.total) * 100),
              k: 'competitions',
              id: id,
              round: params.round
            }
          });
        } catch (err) {
          dispatch({
            type: 'UPDATED_NESTED_PROGRESS',
            payload: { data: 0, k: 'competitions', id: id, round: params.round }
          });
        }
      }
    );
  };
}

export function patchFormDataNested(
  uri,
  id,
  obj,
  params,
  token,
  shouldRedirect,
  redirect,
  toSingle
) {
  return (dispatch) => {
    const api = new Api(token);
    dispatch({
      type: 'SET_SINGLE_ENTRY_LOADING',
      payload: true
    });
    dispatch({
      type: 'SET_UPLOADING',
      payload: {
        id: id,
        uploading: true
      }
    });
    api.patchFormDataWithProgress(
      uri,
      id,
      obj,
      (res) => {
        if (res.data.status === 200) {
          try {
            /*
                    dispatch({
                        type: 'UPDATED_NESTED_SINGLE',
                        payload: {data: res.data.data, k: "competitions", round: params.round}
                    })

 */
            //NotificationManager.success("Your changes have been saved");
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        }
      },
      (progress) => {
        console.log('UPLOADED:', progress.loaded, ' // TOTAL', progress.total);
        try {
          dispatch({
            type: 'UPDATED_NESTED_PROGRESS',
            payload: {
              data: Math.floor((progress.loaded / progress.total) * 100),
              k: 'competitions',
              id: id,
              round: params.round
            }
          });
        } catch (err) {
          dispatch({
            type: 'UPDATED_NESTED_PROGRESS',
            payload: { data: 0, k: 'competitions', id: id, round: params.round }
          });
        }
      }
    );
  };
}

export function patchFormData(uri, id, obj, token, shouldRedirect, redirect, toSingle, hardMode) {
  return (dispatch) => {
    const api = new Api(token);
    dispatch({
      type: 'SET_SINGLE_ENTRY_LOADING',
      payload: true
    });
    api.patchFormData(uri, id, obj, (res) => {
      console.log('RES', res);

      if (res.data.status === 200) {
        try {
          dispatch({
            type: 'UPDATED_ITEM',
            payload: { data: res.data.data.user }
          });
          NotificationManager.success('Your changes have been saved');
        } catch (err) {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }

        if (uri.indexOf('users') > -1) {
          try {
            dispatch({
              type: 'UPDATED_USER',
              payload: { data: res.data.data.user }
            });
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        } else {
          if (toSingle) {
            try {
              dispatch({
                type: 'SET_SINGLE_ENTRY',
                payload: res.data.data
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          } else {
            if (shouldRedirect) {
              redirect(res.data.data._id);
            }
            try {
              dispatch({
                type: 'UPDATED_ITEM',
                payload: res.data
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          }
        }
      }
      dispatch({
        type: 'SET_SINGLE_ENTRY_LOADING',
        payload: false
      });
    });
  };
}

export function addStripeAccountId(eventId, code, token, success, error) {
  return () => {
    const api = new Api(token);
    api.addStripeAccountId(
      'events/addStripeAccountId',
      {
        eventId,
        code
      },
      (res) => {
        if (res.data.status === 200) {
          try {
            NotificationManager.success('Your changes have been saved');
            success();
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
            error();
          }
        }
      }
    );
  };
}

export function setBlankTable() {
  return (dispatch) => {
    dispatch({
      type: 'SET_BLANK_TABLE'
    });
  };
}

export function postImport(uri, obj, token, shouldRedirect, redirect, toSingle, rec) {
  return (dispatch) => {
    const api = new Api(token);

    api.postFormData(uri, obj, (res) => {
      try {
        dispatch({
          type: 'GOT_TABLE_DATA',
          payload: {
            data: res.data.data,
            count: res.data.data.length,
            view: 'import'
          }
        });
      } catch (err) {
        NotificationManager.error('Please reload this view and try again', 'An Error Occured');
      }
    });
  };
}

export function postFormDataNoUpdate(uri, obj, token, shouldRedirect, redirect, toSingle, rec) {
  return (dispatch) => {
    const api = new Api(token);
    dispatch({
      type: 'SET_SINGLE_ENTRY_LOADING',
      payload: true
    });
    api.postFormData(uri, obj, (res) => {
      NotificationManager.success('Your changes have been saved.', 'Saved');
    });
  };
}

export function postFormData(uri, obj, token, shouldRedirect, redirect, toSingle, rec) {
  return (dispatch) => {
    const api = new Api(token);
    dispatch({
      type: 'SET_SINGLE_ENTRY_LOADING',
      payload: true
    });
    api.postFormData(uri, obj, (res) => {
      dispatch({
        type: 'SET_SINGLE_ENTRY_LOADING',
        payload: false
      });
      if (uri.indexOf('files') > -1) {
        try {
          dispatch({
            type: 'ADDED_FILE',
            payload: res.data
          });
        } catch (err) {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      } else {
        if (shouldRedirect) {
          redirect(res.data.data._id);
        }
        if (toSingle) {
          if (rec) {
            try {
              dispatch({
                type: 'GOT_REC_SINGLE',
                payload: {
                  ...res.data.data,
                  k: 'entry'
                }
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          } else {
            try {
              dispatch({
                type: 'SET_SINGLE_ENTRY',
                payload: res.data.data
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          }
        } else {
          try {
            dispatch({
              type: 'ADDED_ITEM',
              payload: res.data
            });
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        }
      }
    });
  };
}

export function entryFirstStep(id, token) {
  return (dispatch) => {
    const api = new Api(token);

    dispatch({
      type: 'SET_SINGLE_ENTRY_LOADING',
      payload: true
    });
    api.post('entries/firstStep', { id: id }, (res) => {
      if (res.status === 200) {
        if (res.data.status === 200) {
          try {
            dispatch({
              type: 'SET_SINGLE_ENTRY',
              payload: res.data.data.entry
            });
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        } else {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      } else {
        NotificationManager.error('Please reload this view and try again', 'An Error Occured');
      }
      dispatch({
        type: 'SET_SINGLE_ENTRY_LOADING',
        payload: false
      });
    });
  };
}

export function postCard(obj, token) {
  return (dispatch) => {
    const api = new Api(token);
    dispatch({
      type: 'SET_SINGLE_ENTRY_LOADING',
      payload: true
    });
    api.post('cards', obj, (res) => {
      if (res.status === 200) {
        if (res.data.status === 200) {
          try {
            dispatch({
              type: 'ADDED_CARD',
              payload: res.data
            });
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        } else {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      }
      dispatch({
        type: 'SET_SINGLE_ENTRY_LOADING',
        payload: false
      });
    });
  };
}

export function post(uri, obj, token, shouldRedirect, redirect) {
  return (dispatch) => {
    const api = new Api(token);
    dispatch({
      type: 'SET_SINGLE_ENTRY_LOADING',
      payload: true
    });
    api.post(uri, obj, (res) => {
      dispatch({
        type: 'SET_SINGLE_ENTRY_LOADING',
        payload: false
      });
      if (uri.indexOf('files') > -1) {
        try {
          dispatch({
            type: 'ADDED_FILE',
            payload: res.data
          });
        } catch (err) {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      } else {
        if (shouldRedirect) {
          //redirect(res.data.data._id);
        }
        try {
          if (res.data.status === 200) {
            dispatch({
              type: 'ADDED_ITEM',
              payload: res.data
            });
          } else {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        } catch (err) {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      }
    });
  };
}

export function patch(uri, id, obj, token) {
  return (dispatch) => {
    const api = new Api(token);
    dispatch({
      type: 'SET_SINGLE_ENTRY_LOADING',
      payload: true
    });
    api.patch(uri, id, obj, (res) => {
      dispatch({
        type: 'SET_SINGLE_ENTRY_LOADING',
        payload: false
      });
      if (uri === 'categories') {
        try {
          dispatch({
            type: 'UPDATED_CATEGORY',
            payload: res.data
          });
        } catch (err) {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      } else {
        try {
          dispatch({
            type: 'UPDATED_ITEM',
            payload: res.data
          });
        } catch (err) {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      }
    });
  };
}

export function postFile(uri, data, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.postFile(`${uri}${data.folder ? '?folder=' + data.folder : ''}`, data.formData, (res) => {
      if (res.status === 200) {
        if (res.data.status === 200) {
          try {
            dispatch({
              type: 'GOT_MORE_FILE_DATA',
              payload: res.data.data
            });
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        }
      }
    });
  };
}
