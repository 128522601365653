/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 2/1/2021
 */

import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import * as eventActions from '../../../store/actions/events';
import ScoreModal from '../../Common/ScoreModal';
import FinalizeCompetitionModal from "../../Common/FinalizeCompetitionModal";
import Nav from "react-bootstrap/Nav";
import Bound from "../../Common/Bound";
import TabulatorTable from "./TabulatorTable";
import {ArrowBack} from "react-ionicons";
import Button from "react-bootstrap/Button";

class JudgeSingleCompetition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            score: {},
            showSubmitModal: false
        }
    }

    componentDidMount(props) {
        this.props.getTabulatorCompetition({id: this.props.match.params.competition}, this.props.auth.token._id)
        //this.setTitle();
    }

    componentDidUpdate(props, state) {
        if (this.props.events.competition) {
            if (this.props.events.competition.judgeEvent) {
                if (this.props.events.competition.judgeEvent.completed) {
                    if (this.props.events.competition.judgeEvent.completed.indexOf(this.props.match.params.competition) > -1) {
                        this.props.history.push(`/app/tabulator/${this.props.match.params.id}`);
                        console.log("complete");
                    }
                }
            }
        }
        //this.setTitle();
    }

    setTitle = () => {
        if (this.props.events.competition) {
            if (this.props.events.competition.competition) {
                if (this.props.events.competition.competition._id === this.props.match.params.competition) {
                    document.title = "FeisFWD | " + this.props.events.competition.competition.rowTitle
                        + " - "
                        + this.props.events.competition.competition.columnTitle
                        + " ("
                        + this.props.events.competition.competition.code
                    +")";


                }
            }
        }
    }


    showModal = (score) => {
        this.setState({showModal: true, score: score});
    }

    hideModal = () => {
        this.setState({showModal: false, score: {}});
    }

    showSubmitModal = () => {
        this.setState({showSubmitModal: true});
    }

    hideSubmitModal = () => {
        this.setState({showSubmitModal: false});
    }

    submitCompetition = () => {
        this.props.submitCompetition(this.props.match.params.competition, this.props.auth.token._id);
    }

    menu = (rounds, judges, panelType) => {
        let list = [];
        let overViewTitle = panelType === 'SPLIT' ? 'Recall' : 'Overview'
        let tabTitle = (panelType === 'SET' && judges > 1) ? 'Judge' : 'Round'


        list.push({
            title: `${tabTitle} 1`,
            path: `/app/tabulation/${this.props.match.params.id}/${this.props.match.params.competition}/1`,
            component: <TabulatorTable round={this.props.match.params.round} />,
            dataSet: () => {
                this.props.getSingle("users", this.props.user.user._id, this.props.auth.token._id);
            }
        });




        if (rounds >= 2) {
            list.push({
                title: `${tabTitle} 2`,
                path: `/app/tabulation/${this.props.match.params.id}/${this.props.match.params.competition}/2`,
                component: <TabulatorTable round={this.props.match.params.round} />
            });
        }

        if (panelType === 'SPLIT') {
            // recall tab comes before round 3 for split comps

            list.push({
                title: 'R1 & R2 Totals',
                path: `/app/tabulation/${this.props.match.params.id}/${this.props.match.params.competition}/overview`,
                component: <TabulatorTable round={this.props.match.params.round} />
            });

            if (rounds >= 3) {
                list.push({
                    title: `${tabTitle} 3`,
                    path: `/app/tabulation/${this.props.match.params.id}/${this.props.match.params.competition}/3`,
                    component: <TabulatorTable round={this.props.match.params.round} />
                });
            }
        } else {
            if (rounds >= 3) {
                list.push({
                    title: `${tabTitle} 3`,
                    path: `/app/tabulation/${this.props.match.params.id}/${this.props.match.params.competition}/3`,
                    component: <TabulatorTable round={this.props.match.params.round} />
                });
            }
    
            list.push({
                title: overViewTitle,
                path: `/app/tabulation/${this.props.match.params.id}/${this.props.match.params.competition}/overview`,
                component: <TabulatorTable round={this.props.match.params.round} />
            });
        }


        if (panelType === 'SPLIT') {
            list.push({
                title: 'Final',
                path: `/app/tabulation/${this.props.match.params.id}/${this.props.match.params.competition}/final`,
                component: <TabulatorTable round={this.props.match.params.round} />
            });
        }

        return list;
    }

    renderItem = (item, i) => {
        if (this.props.events.competition) {
            if (this.props.events.competition.competition) {
                if (this.props.events.competition.competition._id === this.props.match.params.competition) {
                    return (
                        <Nav.Link key={i} to={item.path} as={Link}
                                  className={`tab tab-${i} ${this.props.location.pathname === item.path ? 'active' : ''}`}>{item.title}</Nav.Link>
                    )
                }
            }
        }
    }

    renderContent = () => {
        //console.log("Trying to render Content", this.props.match.params.competition);
        let menuItem = this.menu(
            this.props.events.competition.competition.grade.rounds, 
            this.props.events.competition.competition.grade.judges,
            this.props.events.competition.competition.grade.scoring.judge_rotation_type).filter(item => {
            //console.log("Item", item.path, this.props.location.pathname);
            return item.path === this.props.location.pathname
        });
        if (menuItem.length > 0) {
            return (
                <Bound>
                    {menuItem[0].component}
                </Bound>

            );
        } else {
            return null;
        }
    }


    render() {

        if (this.props.events.competition) {
            if (this.props.events.competition.competition) {
                if (this.props.events.competition.competition._id === this.props.match.params.competition) {
                    return (
                        <div className={'page-container page-container-fat'}>
                            <ScoreModal items={this.props.events.competition.entries.length || 0}
                                        entry={this.state.score} show={this.state.showModal}
                                        hideModal={this.hideModal}/>
                            <FinalizeCompetitionModal submitCompetition={this.submitCompetition}
                                                      show={this.state.showSubmitModal}
                                                      hideModal={this.hideSubmitModal}/>
                            <div className={'page-header'}>
                                <Row>
                                    <Col>
                                        <h1>
                                            <Button variant={"flat"} className={"btn-round"} onClick={e => {
                                                this.props.history.push(`/app/tabulator/${this.props.match.params.id}`)
                                            }}><ArrowBack /></Button>
                                            {this.props.events.competition.competition.code} |&nbsp;
                                            {this.props.events.competition.competition.grade.title} |&nbsp;
                                            {this.props.events.competition.competition.rowTitle} |&nbsp;
                                            {this.props.events.competition.competition.columnTitle}
                                        </h1>
                                    </Col>
                                </Row>
                            </div>


                            {/*
                            <TabulatorDancerColumn/>
                            */}




                            <div className={"elevated rounded"}>
                                <div className={"tab-container"}>
                                    <div className={this.props.events.competition.competition.grade.scoring.judge_rotation_type === 'SPLIT' ? "tab-row items-5" : "tab-row items-4"}>
                                        {this.menu(this.props.events.competition.competition.grade.rounds, 
                                         this.props.events.competition.competition.grade.judges,
                                         this.props.events.competition.competition.grade.scoring.judge_rotation_type).map((item, i) => {
                                            return this.renderItem(item, i)
                                        })}
                                        <div className={"tab-highlight"}/>
                                        <div className={"tab-highlight-track"}/>
                                    </div>

                                </div>
                                <div className={"tab-content ex-pad tabulator-content"}>

                                    {this.renderContent()}

                                </div>

                            </div>


                        </div>
                    )
                } else {
                    return null;
                }

            } else {
                return null;
            }
        }else {
            return null;
        }
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user,
    events: state.events
});

const mapDispatchToProps = dispatch => ({
    getTableData: (query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery("events/getTabulatorCompetition", 0, 999, {}, query, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    getTabulatorCompetition: (query, token) => {
        dispatch(eventActions.getTabulatorCompetition(query, token));
    },
    submitCompetition: (id, token) => {
        dispatch(eventActions.submitCompetition(id, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(JudgeSingleCompetition));

class JudgeSingleCompetitionContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default JudgeSingleCompetitionContainer;
