/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 11/1/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import {Formik} from "formik";
import * as yup from "yup";
import * as eventActions from "../../../store/actions/events";
import {Checkmark, OpenOutline} from "react-ionicons";
import {FormControl} from "react-bootstrap";
import PageLoader from "../../Common/PageLoader";

const schema = yup.object({
    email: yup.string().email("Please enter a valid email address").required("This field is required"),
    password: yup.string().min(8, 'Password needs to be at least 8 characters long'),
    password2: yup.mixed().test('match', 'Password does not match', function (password2) {
        return password2 === this.parent.password
    })
});


class TabulatorOverviewTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locked: [
                false,
                false,
                false,
                false
            ],
            items: []
        }
    }

    componentDidMount(props) {
        if (this.props.table.view !== "") {
            //this.props.getTableData("", 0, 999, {number: 1}, this.props.auth.token._id, 'add');
        }
    }

    componentDidUpdate(props, state) {
        if ((this.props.table.data.length !== this.state.items.length || this.props.table.updateCount > props.table.updateCount || this.props.table.data.length !== this.state.items.length) && this.props.table.view === "") {
            this.setState({items: this.props.table.data});

        }
    }

    emptyJudge = {
        name: 'Not',
        lname: 'Selected',
        email: ''
    }

    toggleLock = (val) => {
        let tab = this.props.events.competition.tabulation;
        tab["round" + this.props.match.params.round + "Locked"] = !tab["round" + this.props.match.params.round + "Locked"];
        this.props.updateTabulationObject(tab, this.props.auth.token.token._id);
    }

    render() {

        let entriesMain = this.props.events.competition.entries;
        let rounds = this.props.events.competition.competition.grade.rounds;
        let judgeCount = this.props.events.competition.competition.grade.judges;
        let panelType = this.props.events.competition.competition.grade.scoring.judge_rotation_type;
        let final = this.props.match.params.round === 'final';
        let rotating  = panelType === 'ROTATING';

        let entries = [];
        let scores = JSON.parse(JSON.stringify(this.props.events.competition.template.rounds["1"].scores[0]));
        let scores3 = JSON.parse(JSON.stringify(this.props.events.competition.template.rounds["1"].scores[0]));
        if (rounds === 1) {
            if (judgeCount === 1) {
                scores = scores.sort((a, b) => b.round1Score - a.round1Score);
            } else {
                scores = scores.sort((a, b) => b.finalIp - a.finalIp);
            }
            scores.forEach(item => {
                let entry = entriesMain.filter(it => it._id === item.entryCompetition)[0];
                entries.push(entry);
            })
        } else {
            if (!final && panelType === 'SPLIT') {
                scores3 = scores3.sort((a, b) => b.finalTwoRoundIp - a.finalTwoRoundIp);
            } else {
                scores3 = scores3.sort((a, b) => a.finalRank - b.finalRank);
            }
            scores3.forEach(item => {
                let entry = entriesMain.filter(it => it._id === item.entryCompetition)[0];
                entries.push(entry);
            })
        }

        console.log("ENT", entries);
        let pageTitle = panelType !== 'SPLIT' ? 'Overview' :
            final ? 'Final Overview' : 'Recall Overview'

        // We're gonna build the html here and use dangerouslySetInnerhtml... I hate this but I hate React even more
        let judge1Headers = [];
        let judge2Headers = [];
        let judge3Headers = [];
        let judge4Headers = [];
        let judge5Headers = [];
        let finalHeaders = [];

        if (rotating) {
            let judgeIterator = 1;
            for(let i = 1; i <= rounds; i++) {
                for (let j = 0; j < judgeCount; j ++) {
                    // Ok this is extra bad because we're just dumping everything into the judge1Headers array. This whole thing needs to be fixed at some point
                    judge1Headers.push('<span className="tabulator-big-text">Judge ' + judgeIterator + '<br /></span>Raw Score<i className="tabulator-small-text"><hr />' +
                    this.props.events.competition.template["rounds"][i].judges[j].name + '&nbsp;' +
                    this.props.events.competition.template["rounds"][i].judges[j].lname || 'Last Name' + '</i>');
                    judge1Headers.push('<span className="tabulator-big-text">Judge ' + judgeIterator + '<br /></span>IP<i className="tabulator-small-text"><hr />' +
                    this.props.events.competition.template["rounds"][i].judges[j].name + '&nbsp;' +
                    this.props.events.competition.template["rounds"][i].judges[j].lname || 'Last Name' + '</i>');
                    judge1Headers.push('<span className="tabulator-big-text">Judge ' + judgeIterator + '<br /></span>Rank<i className="tabulator-small-text"><hr />' +
                    this.props.events.competition.template["rounds"][i].judges[j].name + '&nbsp;' +
                    this.props.events.competition.template["rounds"][i].judges[j].lname || 'Last Name' + '</i>');

                    judgeIterator++;
                }

                switch (i) {
                    case 1: 
                        judge1Headers.push('Total IP R1');
                        judge1Headers.push('Rank After R1');
                        break;
                    case 2: 
                        judge1Headers.push('Total IP R2');
                        judge1Headers.push('Total IP R1 + R2');
                        judge1Headers.push('Rank After R2');
                        break;
                    case 3: 
                        judge1Headers.push('Total IP R3');
                        break;
                }
            }

            finalHeaders.push('Total IP All Rds');
            finalHeaders.push('Rank');
    
            if(this.props.events.competition.competition.grade.recallType !== "NONE") {
                finalHeaders.push('Recall Y/N');
            }
            finalHeaders.push('Placed Y/N');
            finalHeaders.push('World Qualifier Y/N');
            finalHeaders.push('World Medal Holder');
            finalHeaders.push('NA Qualifier Y/N');

        } else if (final) {
            // Post recall for a split
            let judgeNum = judgeCount + 1;
            judge1Headers.push('<span className="tabulator-big-text">Judge ' + judgeNum + '<br /></span>R3 Score <i className="tabulator-small-text"><hr />' +
            this.props.events.competition.template["rounds"]["3"].judges[0].name + '&nbsp;' +
            this.props.events.competition.template["rounds"]["3"].judges[0].lname || 'Last Name' + '</i>');

            judge1Headers.push('<span className="tabulator-big-text">Judge ' + judgeNum + '<br /></span>R3 Rank <i className="tabulator-small-text"><hr />' +
            this.props.events.competition.template["rounds"]["3"].judges[0].name + '&nbsp;' +
            this.props.events.competition.template["rounds"]["3"].judges[0].lname || 'Last Name' + '</i>')

            judge1Headers.push('<span className="tabulator-big-text">Judge ' + judgeNum + '<br /></span>R3 IP <i className="tabulator-small-text"><hr />' +
            this.props.events.competition.template["rounds"]["3"].judges[0].name + '&nbsp;' +
            this.props.events.competition.template["rounds"]["3"].judges[0].lname || 'Last Name' + '</i>');

            if (judgeCount >= 2) {
                judgeNum++;
                judge2Headers.push('<span className="tabulator-big-text">Judge ' + judgeNum + '<br /></span>R3 Score <i className="tabulator-small-text"><hr />' +
                this.props.events.competition.template["rounds"]["3"].judges[1].name + '&nbsp;' +
                this.props.events.competition.template["rounds"]["3"].judges[1].lname || 'Last Name' + '</i>');
    
                judge2Headers.push('<span className="tabulator-big-text">Judge ' + judgeNum + '<br /></span>R3 Rank <i className="tabulator-small-text"><hr />' +
                this.props.events.competition.template["rounds"]["3"].judges[1].name + '&nbsp;' +
                this.props.events.competition.template["rounds"]["3"].judges[1].lname || 'Last Name' + '</i>')
    
                judge2Headers.push('<span className="tabulator-big-text">Judge ' + judgeNum + '<br /></span>R3 IP <i className="tabulator-small-text"><hr />' +
                this.props.events.competition.template["rounds"]["3"].judges[1].name + '&nbsp;' +
                this.props.events.competition.template["rounds"]["3"].judges[1].lname || 'Last Name' + '</i>');
            }
            if (judgeCount >= 3) {
                judgeNum++;
                judge3Headers.push('<span className="tabulator-big-text">Judge ' + judgeNum + '<br /></span>R3 Score <i className="tabulator-small-text"><hr />' +
                this.props.events.competition.template["rounds"]["3"].judges[2].name + '&nbsp;' +
                this.props.events.competition.template["rounds"]["3"].judges[2].lname || 'Last Name' + '</i>');
    
                judge3Headers.push('<span className="tabulator-big-text">Judge ' + judgeNum + '<br /></span>R3 Rank <i className="tabulator-small-text"><hr />' +
                this.props.events.competition.template["rounds"]["3"].judges[2].name + '&nbsp;' +
                this.props.events.competition.template["rounds"]["3"].judges[2].lname || 'Last Name' + '</i>')
    
                judge3Headers.push('<span className="tabulator-big-text">Judge ' + judgeNum + '<br /></span>R3 IP <i className="tabulator-small-text"><hr />' +
                this.props.events.competition.template["rounds"]["3"].judges[2].name + '&nbsp;' +
                this.props.events.competition.template["rounds"]["3"].judges[2].lname || 'Last Name' + '</i>');
            }
            if (judgeCount >= 4) {
                judgeNum++;
                judge4Headers.push('<span className="tabulator-big-text">Judge ' + judgeNum + '<br /></span>R3 Score <i className="tabulator-small-text"><hr />' +
                this.props.events.competition.template["rounds"]["3"].judges[3].name + '&nbsp;' +
                this.props.events.competition.template["rounds"]["3"].judges[3].lname || 'Last Name' + '</i>');
    
                judge4Headers.push('<span className="tabulator-big-text">Judge ' + judgeNum + '<br /></span>R3 Rank <i className="tabulator-small-text"><hr />' +
                this.props.events.competition.template["rounds"]["3"].judges[3].name + '&nbsp;' +
                this.props.events.competition.template["rounds"]["3"].judges[3].lname || 'Last Name' + '</i>')
    
                judge4Headers.push('<span className="tabulator-big-text">Judge ' + judgeNum + '<br /></span>R3 IP <i className="tabulator-small-text"><hr />' +
                this.props.events.competition.template["rounds"]["3"].judges[3].name + '&nbsp;' +
                this.props.events.competition.template["rounds"]["3"].judges[3].lname || 'Last Name' + '</i>');
            }
            if (judgeCount >= 5) {
                judgeNum++;
                judge5Headers.push('<span className="tabulator-big-text">Judge ' + judgeNum + '<br /></span>R3 Score <i className="tabulator-small-text"><hr />' +
                this.props.events.competition.template["rounds"]["3"].judges[4].name + '&nbsp;' +
                this.props.events.competition.template["rounds"]["3"].judges[4].lname || 'Last Name' + '</i>');
    
                judge5Headers.push('<span className="tabulator-big-text">Judge ' + judgeNum + '<br /></span>R3 Rank <i className="tabulator-small-text"><hr />' +
                this.props.events.competition.template["rounds"]["3"].judges[4].name + '&nbsp;' +
                this.props.events.competition.template["rounds"]["3"].judges[4].lname || 'Last Name' + '</i>')
    
                judge5Headers.push('<span className="tabulator-big-text">Judge ' + judgeNum + '<br /></span>R3 IP <i className="tabulator-small-text"><hr />' +
                this.props.events.competition.template["rounds"]["3"].judges[4].name + '&nbsp;' +
                this.props.events.competition.template["rounds"]["3"].judges[4].lname || 'Last Name' + '</i>');
            }           

            finalHeaders.push('IP R1&2');
            finalHeaders.push('IP R3');
            finalHeaders.push('Final IP');
            finalHeaders.push('Rank');
    
            if(this.props.events.competition.competition.grade.recallType !== "NONE") {
                finalHeaders.push('Recall Y/N');
            }
            // TODO: Hide this based on toggle
            // finalHeaders.push('World Qualifier Y/N');
            finalHeaders.push('Placed Y/N');


        } else {
            // Normal Overview for set and first part for split
            if (rounds > 1) {
                judge1Headers.push('<span className="tabulator-big-text">Judge 1<br /></span>R1 Score <i className="tabulator-small-text"><hr />' +
                this.props.events.competition.template["rounds"]["1"].judges[0].name + '&nbsp;' +
                this.props.events.competition.template["rounds"]["1"].judges[0].lname || 'Last Name' + '</i>');
                
                judge1Headers.push('<span className={"tabulator-big-text"}>Judge 1<br /></span>R2 Score <i className="tabulator-small-text"><hr />' + 
                this.props.events.competition.template['rounds']["1"].judges[0].name + '&nbsp;' +
                this.props.events.competition.template['rounds']["1"].judges[0].lname || 'Last Name' + '</i>');
            } else {
                judge1Headers.push('<span className="tabulator-big-text"></span>Judge 1 Score <i className="tabulator-small-text"><hr />' +
                this.props.events.competition.template['rounds']["1"].judges[0].name + '&nbsp;' +
                this.props.events.competition.template['rounds']["1"].judges[0].lname || 'Last Name' + '</i>');
            }
    
            if (rounds >= 3 && panelType !== 'SPLIT') {
                judge1Headers.push('<span className="tabulator-big-text">Judge 1<br /></span>R3 Score <i className="tabulator-small-text"><hr />' +
                this.props.events.competition.template['rounds']["1"].judges[0].name + '&nbsp;' +
                this.props.events.competition.template['rounds']["1"].judges[0].lname || 'Last Name' + '</i>');
            }
            if(panelType !== 'SPLIT') {
                if (rounds > 1) {
                    judge1Headers.push('Judge 1 Total Score');
                }
                // Hiding IP columns for now
                // if (panelType !== 'SET' || rounds === 1) {
                //     judge1Headers.push('Judge 1 IP');
                // }
                if (judgeCount > 1) {
                    judge1Headers.push('Judge 1 Rank');
                }
            }
                else {
                judge1Headers.push('Judge 1 Subtotal Raw Score (2 Rounds)');
                judge1Headers.push('Judge 1 Rank (2 Rounds)');
                judge1Headers.push('Judge 1 IP (2 Rounds)');
            }
    
            if (judgeCount >= 2) {
                if (rounds > 1) {
                    judge2Headers.push('<span className="tabulator-big-text">Judge 2<br /></span>R1 Score <i className="tabulator-small-text"><hr />' +
                    this.props.events.competition.template["rounds"]["1"].judges[1].name + '&nbsp;' +
                    this.props.events.competition.template["rounds"]["1"].judges[1].lname || 'Last Name' + '</i>');
                    
                    judge2Headers.push('<span className={"tabulator-big-text"}>Judge 2<br /></span>R2 Score <i className="tabulator-small-text"><hr />' + 
                    this.props.events.competition.template['rounds']["1"].judges[1].name + '&nbsp;' +
                    this.props.events.competition.template['rounds']["1"].judges[1].lname || 'Last Name' + '</i>');
                } else {
                    judge2Headers.push('<span className="tabulator-big-text"></span>Judge 2 Score <i className="tabulator-small-text"><hr />' +
                    this.props.events.competition.template['rounds']["1"].judges[1].name + '&nbsp;' +
                    this.props.events.competition.template['rounds']["1"].judges[1].lname || 'Last Name' + '</i>');
                }
    
                if (rounds >= 3 && panelType !== 'SPLIT') {
                    judge2Headers.push('<span className="tabulator-big-text">Judge 2<br /></span>R3 Score <i className="tabulator-small-text"><hr />' +
                    this.props.events.competition.template['rounds']["1"].judges[1].name + '&nbsp;' +
                    this.props.events.competition.template['rounds']["1"].judges[1].lname || 'Last Name' + '</i>');
                }
                if(panelType !== 'SPLIT') {
                    if (rounds > 1) {
                        judge2Headers.push('Judge 2 Total Score');
                    }
                    if (panelType !== 'SET' || rounds === 1) {
                        judge2Headers.push('Judge 2 IP');
                    }
                    judge2Headers.push('Judge 2 Rank');
                }
                 else {
                    judge2Headers.push('Judge 2 Subtotal Raw Score (2 Rounds)');
                    judge2Headers.push('Judge 2 Rank (2 Rounds)');
                    judge2Headers.push('Judge 2 IP (2 Rounds)');
                }
            }
    
            if (judgeCount >= 3) {
                if (rounds > 1) {
                    judge3Headers.push('<span className="tabulator-big-text">Judge 3<br /></span>R1 Score <i className="tabulator-small-text"><hr />' +
                    this.props.events.competition.template["rounds"]["1"].judges[2].name + '&nbsp;' +
                    this.props.events.competition.template["rounds"]["1"].judges[2].lname || 'Last Name' + '</i>');
                    
                    judge3Headers.push('<span className={"tabulator-big-text"}>Judge 3<br /></span>R2 Score <i className="tabulator-small-text"><hr />' + 
                    this.props.events.competition.template['rounds']["1"].judges[2].name + '&nbsp;' +
                    this.props.events.competition.template['rounds']["1"].judges[2].lname || 'Last Name' + '</i>');
                } else {
                    judge3Headers.push('<span className="tabulator-big-text"></span>Judge 3 Score <i className="tabulator-small-text"><hr />' +
                    this.props.events.competition.template['rounds']["1"].judges[2].name + '&nbsp;' +
                    this.props.events.competition.template['rounds']["1"].judges[2].lname || 'Last Name' + '</i>');
                }
    
                if (rounds >= 3 && panelType !== 'SPLIT') {
                    judge3Headers.push('<span className="tabulator-big-text">Judge 3<br /></span>R3 Score <i className="tabulator-small-text"><hr />' +
                    this.props.events.competition.template['rounds']["1"].judges[2].name || 'Name' +
                    this.props.events.competition.template['rounds']["1"].judges[2].lname || 'Last Name' + '</i>');
                }
                if(panelType !== 'SPLIT') {
                    if (rounds > 1) {
                        judge3Headers.push('Judge 3 Total Score');
                    }
                    if (panelType !== 'SET' || rounds === 1) {
                        judge5Headers.push('Judge 3 IP');
                    }
                    judge3Headers.push('Judge 3 Rank');
                }
                 else {
                    judge3Headers.push('Judge 3 Subtotal Raw Score (2 Rounds)');
                    judge3Headers.push('Judge 3 Rank (2 Rounds)');
                    judge3Headers.push('Judge 3 IP (2 Rounds)');
                }
            }
    
            if (judgeCount >= 4) {
                if (rounds > 1) {
                    judge4Headers.push('<span className="tabulator-big-text">Judge 4<br /></span>R1 Score <i className="tabulator-small-text"><hr />' +
                    this.props.events.competition.template["rounds"]["1"].judges[3].name + '&nbsp;' +
                    this.props.events.competition.template["rounds"]["1"].judges[3].lname || 'Last Name' + '</i>');
                    
                    judge4Headers.push('<span className={"tabulator-big-text"}>Judge 4<br /></span>R2 Score <i className="tabulator-small-text"><hr />' + 
                    this.props.events.competition.template['rounds']["1"].judges[3].name + '&nbsp;' +
                    this.props.events.competition.template['rounds']["1"].judges[3].lname || 'Last Name' + '</i>');
                } else {
                    judge4Headers.push('<span className="tabulator-big-text"></span>Judge 4 Score <i className="tabulator-small-text"><hr />' +
                    this.props.events.competition.template['rounds']["1"].judges[3].name + '&nbsp;' +
                    this.props.events.competition.template['rounds']["1"].judges[3].lname || 'Last Name' + '</i>');
                }
    
                if (rounds >= 3 && panelType !== 'SPLIT') {
                    judge4Headers.push('<span className="tabulator-big-text">Judge 4<br /></span>R3 Score <i className="tabulator-small-text"><hr />' +
                    this.props.events.competition.template['rounds']["1"].judges[3].name + '&nbsp;' +
                    this.props.events.competition.template['rounds']["1"].judges[3].lname || 'Last Name' + '</i>');
                }
                if(panelType !== 'SPLIT') {
                    if (rounds > 1) {
                        judge4Headers.push('Judge 4 Total Score');
                    }
                    if (panelType !== 'SET' || rounds === 1) {
                        judge5Headers.push('Judge 4 IP');
                    }
                    judge4Headers.push('Judge 4 Rank');
                }
                 else {
                    judge4Headers.push('Judge 4 Subtotal Raw Score (2 Rounds)');
                    judge4Headers.push('Judge 4 Rank (2 Rounds)');
                    judge4Headers.push('Judge 4 IP (2 Rounds)');
                }
            }
    
            if (judgeCount >= 5) {
                if (rounds > 1) {
                    judge5Headers.push('<span className="tabulator-big-text">Judge 5<br /></span>R1 Score <i className="tabulator-small-text"><hr />' +
                    this.props.events.competition.template["rounds"]["1"].judges[4].name + '&nbsp;' +
                    this.props.events.competition.template["rounds"]["1"].judges[4].lname || 'Last Name' + '</i>');
                    
                    judge5Headers.push('<span className={"tabulator-big-text"}>Judge 5<br /></span>R2 Score <i className="tabulator-small-text"><hr />' + 
                    this.props.events.competition.template['rounds']["1"].judges[4].name + '&nbsp;' +
                    this.props.events.competition.template['rounds']["1"].judges[4].lname || 'Last Name' + '</i>');
                } else {
                    judge5Headers.push('<span className="tabulator-big-text"></span>Judge 5 Score <i className="tabulator-small-text"><hr />' +
                    this.props.events.competition.template['rounds']["1"].judges[4].name + '&nbsp;' +
                    this.props.events.competition.template['rounds']["1"].judges[4].lname || 'Last Name' + '</i>');
                }
    
                if (rounds >= 3 && panelType !== 'SPLIT') {
                    judge5Headers.push('<span className="tabulator-big-text">Judge 5<br /></span>R3 Score <i className="tabulator-small-text"><hr />' +
                    this.props.events.competition.template['rounds']["1"].judges[4].name + '&nbsp;' +
                    this.props.events.competition.template['rounds']["1"].judges[4].lname || 'Last Name' + '</i>');
                }
                if(panelType !== 'SPLIT') {
                    if (rounds > 1) {
                        judge5Headers.push('Judge 5 Total Score');
                    }
                    if (panelType !== 'SET' || rounds === 1) {
                        judge5Headers.push('Judge 5 IP');
                    }
                    judge5Headers.push('Judge 5 Rank');
                }
                 else {
                    judge5Headers.push('Judge 5 Subtotal Raw Score (2 Rounds)');
                    judge5Headers.push('Judge 5 Rank (2 Rounds)');
                    judge5Headers.push('Judge 5 IP (2 Rounds)');
                }
            }
    
    
            if (panelType === 'SPLIT') {
                finalHeaders.push('Final 2 Round IP');
                finalHeaders.push('Final 2 Round Rank');
            } else {
                if (rounds > 1) {
                    finalHeaders.push('Total IP');
                }
                finalHeaders.push('Rank');
                // TODO: Determine if/when this is needed
                // if (rounds > 1) {
                //     finalHeaders.push('Final Score');
                // }
            }
    
            if(this.props.events.competition.competition.grade.recallType !== "NONE") {
                finalHeaders.push('Recall Y/N');
            }
            if (panelType !== 'SPLIT') {
                finalHeaders.push('Placed Y/N');

                // TODO: Hide this based on toggle
                //finalHeaders.push('World Qualifier Y/N');
            }
        }


        return (
            <PageLoader loaded={!this.props.events.loading}>
                <Formik
                    validationSchema={schema}
                    onSubmit={this.handleSubmit}
                    enableReinitialize
                    initialValues={{
                        template: this.props.events.competition.template
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>

                            <div>
                                <div className={"tabulator-page-header"}>
                                <Row>
                                    <Col>
                                        <h2>{pageTitle}</h2>
                                        <h4>
                                            {this.props.events.competition ?
                                                this.props.events.competition.competition ?
                                                    this.props.events.competition.competition.grade ?
                                                        this.props.events.competition.competition.grade.rounds === 1 ||
                                                        this.props.events.competition.competition.grade.rounds === 2 ?
                                                            <span>
                                                            <a href={'/app/pdfresults/false'} target="_blank" rel="noopener noreferrer" >Full Results</a> |&nbsp;
                                                            <a href={'/app/pdfoneroundsimple/4/1'} target="_blank" rel="noopener noreferrer" >Print Wall Results</a> |&nbsp;
                                                            <a href={`/app/pdfround3placing/true`} target="_blank" rel="noopener noreferrer">Podium Results</a>
                                                            {/* <a href={`/app/pdfround3placing/true`} target="_blank" rel="noopener noreferrer">Podium Results</a> |&nbsp;
                                                            <a href={'/app/pdfrecall'} target="_blank" rel="noopener noreferrer" >Award Callback</a> */}
                                                        </span>

                                                            : 
                                                        <span>
                                                            ({final ?
                                                                <span>
                                                                    <a href={`/app/pdfresults/true`} target="_blank" rel="noopener noreferrer">Full Results</a>|&nbsp;
                                                                    <a href={`/app/pdfround3placing/true`} target="_blank" rel="noopener noreferrer">Podium Results</a>
                                                                </span>
                                                                : <a href={`/app/pdfresults/false`} target="_blank" rel="noopener noreferrer">Full Results</a> 
                                                            }
                                                            {/* |&nbsp; */}
                                                                {/*<a href={`/app/pdfallresults`} target="_blank" rel="noopener noreferrer">Print Wall Results</a> |&nbsp;*/}
                                                            {/* <a href={`/app/pdfround3placing/true`} target="_blank" rel="noopener noreferrer">Podium Results</a>|&nbsp;
                                                            <a href={`/app/pdfround3placing/false`} target="_blank" rel="noopener noreferrer">Hon Mention</a>|&nbsp; */}
                                                            {this.props.events.competition.competition.grade.recallType !== "NONE" && rounds > 1 ?
                                                            <a href={`/app/pdfrecall`} target="_blank" rel="noopener noreferrer" >Recall Printout</a>
                                                            : null})
                                                            </span>
                                                        : null
                                                    : null
                                                : null}
                                        </h4>

                                    </Col>
                                    <Col>
                                        <div className={"text-right ex-pad"}>
                                            <Button
                                                variant={"primary"}
                                                disabled={this.props.events.competition.competition.generatingResults}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.props.generateCompetitionResults(this.props.match.params.id, this.props.match.params.competition, this.props.auth.token._id);
                                                }}

                                            >{this.props.events.competition.competition.generatingResults ? 'Calculating...' : this.props.events.competition.competition.hasPublicResults ? 'Regenerate Results' :
                                                'Calculate Results'}</Button><br /><br />
                                          {/*  <Button
                                                variant={"primary"}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.props.deleteCompetitionResults(this.props.match.params.competition, this.props.auth.token._id);
                                                }}
                                            >{'Delete Results'}</Button><br /><br /> */}
                                            {this.props.events.competition.competition.hasPublicResults ?
                                                <Button variant={"link"} href={`/public/results/event/${this.props.match.params.id}/${this.props.match.params.competition}`} target={"_blank"}>View <OpenOutline /> </Button>
                                                : null}
                                        </div>
                                    </Col>
                                </Row>
                                </div>


                                <Row className={"tabulator-table-pad"}>
                                    <Col className={"no-padding"} key={"entry"} style={{ overflowX: "scroll" }}>
                                        <Table striped bordered hover>
                                            {rounds === 1 ?
                                                <React.Fragment>
                                                <colgroup>
                                                    <col />
                                                    <col />
                                                    <col />
                                                    <col />
                                                    <col style={{ backgroundColor: "#FFE08C" }} />
                                                    
                                                    {judgeCount === 1 ?
                                                        <col style={{ backgroundColor: "#F7E9F1" }} />
                                                        : null}
                                                    {judgeCount === 1 ?
                                                        <col style={{ backgroundColor: "#FFCCE0" }} />
                                                        : null}
                                                    {judgeCount === 1 ?
                                                        <col style={{ backgroundColor: "#F7A8C7" }} />
                                                        : null}
                                                    {judgeCount === 1 ?
                                                        <col style={{ backgroundColor: "#FF6EA5" }} />
                                                        : null}
                                                    {judgeCount === 1 ?
                                                        <col style={{ backgroundColor: "#FC1C97" }} />
                                                        : null}
                                                    <col style={{ backgroundColor: "#FFD45E" }} />
                                                    <col />
                                                    <col style={{ backgroundColor: "#DBF7CD" }} />
                                                    <col style={{ backgroundColor: "#C5F7AD" }} />
                                                    {judgeCount > 2 ?
                                                        <col />
                                                        : null}
                                                    {judgeCount > 2 ?
                                                        <col style={{ backgroundColor: "#D3F5F1" }} />
                                                        : null}
                                                    {judgeCount > 2 ?
                                                        <col style={{ backgroundColor: "#B9FAF3" }} />
                                                        : null}
                                                    {judgeCount > 3 ?
                                                        <col />
                                                        : null}
                                                    {judgeCount > 3 ?
                                                        <col style={{ backgroundColor: "#C7EBFC" }} />
                                                        : null}
                                                    {judgeCount > 3 ?
                                                        <col style={{ backgroundColor: "#ABDEF7" }} />
                                                        : null}
                                                    {judgeCount > 4 ?
                                                        <col />
                                                        : null}
                                                    {judgeCount > 4 ?
                                                        <col style={{ backgroundColor: "#ECD4FA" }} />
                                                        : null}
                                                    {judgeCount > 4 ?
                                                        <col style={{ backgroundColor: "#DEB2F7" }} />
                                                        : null}
                                                    <col style={{ backgroundColor: "#F7E9F1" }} />
                                                    <col style={{ backgroundColor: "#FFCCE0" }} />
                                                    <col style={{ backgroundColor: "#F7A8C7" }} />
                                                    <col style={{ backgroundColor: "#FF6EA5" }} />
                                                    <col style={{ backgroundColor: "#FC1C97" }} />
                                                </colgroup>
                                                </React.Fragment>
                                            :
                                            <colgroup>
                                                {rotating ? 
                                                    <React.Fragment>
                                                        <col />
                                                        <col />
                                                        <col />
                                                        <col />
                                                        <col />
                                                        <col />
                                                        {judgeCount >= 2 ?
                                                            <React.Fragment>
                                                                <col />
                                                                <col />
                                                                <col />
                                                            </React.Fragment>
                                                        : null}
                                                        {judgeCount >= 3 ?
                                                            <React.Fragment>
                                                                <col />
                                                                <col />
                                                                <col />
                                                            </React.Fragment>
                                                        : null}
                                                        {judgeCount >= 4 ?
                                                            <React.Fragment>
                                                                <col />
                                                                <col />
                                                                <col />
                                                            </React.Fragment>
                                                        : null}
                                                        {judgeCount >= 5 ?
                                                            <React.Fragment>
                                                                <col />
                                                                <col />
                                                                <col />
                                                            </React.Fragment>
                                                        : null}
                                                        <col style={{ backgroundColor: "#FFE08C" }} />
                                                        <col style={{ backgroundColor: "#FFD45E" }} />
                                                        {rounds >= 2 ?
                                                            <React.Fragment>
                                                                <col />
                                                                <col />
                                                                <col />
                                                            </React.Fragment>
                                                        : null}
                                                        {rounds >= 2 && judgeCount >= 2 ?
                                                            <React.Fragment>
                                                                <col />
                                                                <col />
                                                                <col />
                                                            </React.Fragment>
                                                        : null}
                                                        {rounds >= 2 && judgeCount >= 3 ?
                                                            <React.Fragment>
                                                                <col />
                                                                <col />
                                                                <col />
                                                            </React.Fragment>
                                                        : null}
                                                        {rounds >= 2 && judgeCount >= 4 ?
                                                            <React.Fragment>
                                                                <col />
                                                                <col />
                                                                <col />
                                                            </React.Fragment>
                                                        : null}
                                                        {rounds >= 2 && judgeCount >= 5 ?
                                                            <React.Fragment>
                                                                <col />
                                                                <col />
                                                                <col />
                                                            </React.Fragment>
                                                        : null}
                                                        {rounds >= 2 ?
                                                            <React.Fragment>
                                                                <col style={{ backgroundColor: "#C7EBFC" }} />
                                                                <col style={{ backgroundColor: "#ABDEF7" }} />
                                                                <col style={{ backgroundColor: "#6EC5F0" }} />
                                                            </React.Fragment>
                                                            : null}
                                                        {rounds >= 3 ?
                                                            <React.Fragment>
                                                                <col />
                                                                <col />
                                                                <col />
                                                            </React.Fragment>
                                                        : null}
                                                        {rounds >= 3 && judgeCount >= 2 ?
                                                            <React.Fragment>
                                                                <col />
                                                                <col />
                                                                <col />
                                                            </React.Fragment>
                                                        : null}
                                                        {rounds >= 3 && judgeCount >= 3 ?
                                                            <React.Fragment>
                                                                <col />
                                                                <col />
                                                                <col />
                                                            </React.Fragment>
                                                        : null}
                                                        {rounds >= 3 && judgeCount >= 4 ?
                                                            <React.Fragment>
                                                                <col />
                                                                <col />
                                                                <col />
                                                            </React.Fragment>
                                                        : null}
                                                        {rounds >= 3 && judgeCount >= 5 ?
                                                            <React.Fragment>
                                                                <col />
                                                                <col />
                                                                <col />
                                                            </React.Fragment>
                                                        : null}
                                                        {rounds >= 3 ?
                                                            <React.Fragment>
                                                                <col style={{ backgroundColor: "#FFCCE0" }} />
                                                            </React.Fragment>
                                                            : null}
                                                        <col style={{ backgroundColor: "#F7A8C7" }} />
                                                        <col style={{ backgroundColor: "#FF6EA5" }} />
                                                        <col />
                                                        <col />
                                                        <col />
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <col />
                                                        <col />
                                                        <col />
                                                        <col />
                                                        {!final ?
                                                        <col />
                                                            : null}
                                                        {!final && panelType !== 'SPLIT' ?
                                                        <col />
                                                            : null}
                                                        <col style={{ backgroundColor: "#FFE08C" }} />
                                                        <col style={{ backgroundColor: "#FFD45E" }} />
                                                        {!final && panelType === 'SPLIT' ?
                                                        <col style={{ backgroundColor: "#F7BB16" }} />
                                                            : null}
                                                        {judgeCount >= 2 ?
                                                            <React.Fragment>
                                                                <col />
                                                                {!final ?
                                                                <col />
                                                                    : null}
                                                                {!final && panelType !== 'SPLIT' ?
                                                                <col />
                                                                    : null}
                                                                <col style={{ backgroundColor: "#DBF7CD" }} />
                                                                <col style={{ backgroundColor: "#C5F7AD" }} />
                                                                {!final && panelType === 'SPLIT' ?
                                                                <col style={{ backgroundColor: "#B6FF94" }} />
                                                                    : null}
                                                            </React.Fragment>
                                                            : null
                                                        }
                                                        {judgeCount >= 3 ?
                                                            <React.Fragment>
                                                                <col />
                                                                {!final ?
                                                                <col />
                                                                    : null}
                                                                {!final && panelType !== 'SPLIT' ?
                                                                <col />
                                                                    : null}
                                                                <col style={{ backgroundColor: "#D3F5F1" }} />
                                                                <col style={{ backgroundColor: "#B9FAF3" }} />
                                                                {!final && panelType === 'SPLIT' ?
                                                                <col style={{ backgroundColor: "#90F5EB" }} />
                                                                    : null}
                                                            </React.Fragment>
                                                            : null
                                                        }
                                                        {judgeCount >= 4 ?
                                                            <React.Fragment>
                                                                <col />
                                                                {!final ?
                                                                <col />
                                                                    : null}
                                                                {!final && panelType !== 'SPLIT' ?
                                                                <col />
                                                                    : null}
                                                                <col style={{ backgroundColor: "#C7EBFC" }} />
                                                                <col style={{ backgroundColor: "#ABDEF7" }} />
                                                                {!final && panelType === 'SPLIT' ?
                                                                <col style={{ backgroundColor: "#6EC5F0" }} />
                                                                    : null}
                                                            </React.Fragment>
                                                            : null
                                                        }
                                                        {judgeCount >= 5 ?
                                                            <React.Fragment>
                                                                <col />
                                                                {!final ?
                                                                <col />
                                                                    : null}
                                                                {!final && panelType !== 'SPLIT' ?
                                                                <col />
                                                                    : null}
                                                                <col style={{ backgroundColor: "#ECD4FA" }} />
                                                                <col style={{ backgroundColor: "#DEB2F7" }} />
                                                                {!final && panelType === 'SPLIT' ?
                                                                <col style={{ backgroundColor: "#D797FC" }} />
                                                                    : null}
                                                            </React.Fragment>
                                                            : null
                                                        }
                                                        <col style={{ backgroundColor: "#F7E9F1" }} />
                                                        <col style={{ backgroundColor: "#FFCCE0" }} />
                                                        <col style={{ backgroundColor: "#F7A8C7" }} />
                                                        <col style={{ backgroundColor: "#FF6EA5" }} />
                                                        <col style={{ backgroundColor: "#FC1C97" }} />
                                                    </React.Fragment>
                                                }
                                            </colgroup>
                                            }
                                            <thead>

                                            <tr className={"tabulator-centered"} >

                                                <th style={{ minWidth: 100 }}>Dancer Number</th>
                                                <th>Dancer Name</th>
                                                <th>School</th>
                                                {}

                                                {judge1Headers.map((item, index) => {
                                                    return(<th dangerouslySetInnerHTML={{ __html: item }}/>);
                                                })}
                                                {judge2Headers.map((item, index) => {
                                                    return(<th dangerouslySetInnerHTML={{ __html: item }}/>);
                                                })}
                                                {judge3Headers.map((item, index) => {
                                                    return(<th dangerouslySetInnerHTML={{ __html: item }}/>);
                                                })}
                                                {judge4Headers.map((item, index) => {
                                                    return(<th dangerouslySetInnerHTML={{ __html: item }}/>);
                                                })}
                                                {judge5Headers.map((item, index) => {
                                                    return(<th dangerouslySetInnerHTML={{ __html: item }}/>);
                                                })}

                                                {finalHeaders.map((item, index) => {
                                                    return(<th dangerouslySetInnerHTML={{ __html: item }}/>);
                                                })}



                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.props.events.competition &&
                                                this.props.events.competition.entries &&
                                                this.props.events.competition.entries.map &&
                                                entries.map((comp, entryNumber) => {

                                                    let r1 = []

                                                    this.props.events.competition.template.rounds["1"].scores.forEach((item, index) => {
                                                        item.forEach(en => {
                                                            if (en.entryCompetition === comp._id) {
                                                                r1.push(en);
                                                            }
                                                        })
                                                    });

                                                    let r2 = []
                                                    this.props.events.competition.template.rounds["2"].scores.forEach((item, index) => {
                                                        item.forEach(en => {
                                                            if (en.entryCompetition === comp._id) {
                                                                r2.push(en);
                                                            }
                                                        })
                                                    });

                                                    let r3 = [];

                                                    this.props.events.competition.template.rounds["3"].scores.forEach((item, index) => {
                                                        item.forEach(en => {
                                                            if (en.entryCompetition === comp._id) {
                                                                r3.push(en);
                                                            }
                                                        })
                                                    });
                                                    // console.log("COMP", comp)
                                                    const dancer = comp.dancer;
                                                    if (!dancer) {
                                                        console.error("no dancer")
                                                        return null;
                                                    }
                                                    return (
                                                        <tr key={comp._id + entryNumber}>
                                                            <td style={{ textAlign: "center", fontSize: 20 }}>
                                                                <b>{comp.dancerNumber || 0}</b>
                                                            </td>
                                                            <td style={{ borderRight: "3px" }}>
                                                                {dancer.name} {dancer.lname}
                                                            </td>
                                                            <td>
                                                                {dancer.school ? dancer.school.name : "-"}
                                                            </td>

                                                            {rotating ? 
                                                                <React.Fragment>
                                                                    <td>{`${r1[0].round1Score > -1 ? r1[0].round1Score : ''}`}</td>
                                                                    <td>{`${r1[0].judgeRound1Ip > -1 ? r1[0].judgeRound1Ip : ''}`}</td>
                                                                    <td>{`${r1[0].round1Rank > -1 ? r1[0].round1Rank : ''}`}
                                                                    {`${r1[0].judgeRound1Tied ? 'T' : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            {rotating && judgeCount >= 2 ? 
                                                                <React.Fragment>
                                                                    <td>{`${r1[1].round1Score > -1 ? r1[1].round1Score : ''}`}</td>
                                                                    <td>{`${r1[1].judgeRound1Ip > -1 ? r1[1].judgeRound1Ip : ''}`}</td>
                                                                    <td>{`${r1[1].round1Rank > -1 ? r1[1].round1Rank : ''}`}
                                                                    {`${r1[1].judgeRound1Tied ? 'T' : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            {rotating && judgeCount >= 3 ? 
                                                                <React.Fragment>
                                                                    <td>{`${r1[2].round1Score > -1 ? r1[2].round1Score : ''}`}</td>
                                                                    <td>{`${r1[2].judgeRound1Ip > -1 ? r1[2].judgeRound1Ip : ''}`}</td>
                                                                    <td>{`${r1[2].round1Rank > -1 ? r1[2].round1Rank : ''}`}
                                                                    {`${r1[2].judgeRound1Tied ? 'T' : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            {rotating && judgeCount >= 4 ? 
                                                                <React.Fragment>
                                                                    <td>{`${r1[3].round1Score > -1 ? r1[3].round1Score : ''}`}</td>
                                                                    <td>{`${r1[3].judgeRound1Ip > -1 ? r1[3].judgeRound1Ip : ''}`}</td>
                                                                    <td>{`${r1[3].round1Rank > -1 ? r1[3].round1Rank : ''}`}
                                                                    {`${r1[3].judgeRound1Tied ? 'T' : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            {rotating && judgeCount >= 5 ? 
                                                                <React.Fragment>
                                                                    <td>{`${r1[4].round1Score > -1 ? r1[4].round1Score : ''}`}</td>
                                                                    <td>{`${r1[4].judgeRound1Ip > -1 ? r1[4].judgeRound1Ip : ''}`}</td>
                                                                    <td>{`${r1[4].round1Rank > -1 ? r1[4].round1Rank : ''}`}
                                                                    {`${r1[4].judgeRound1Tied ? 'T' : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            {rotating ? 
                                                                <React.Fragment>
                                                                    <td>{`${r1[0].totalRound1Ip > -1 ? r1[0].totalRound1Ip : ''}`}</td>
                                                                    <td>{`${r1[0].totalRound1Rank > -1 ? r1[0].totalRound1Rank : ''}`}
                                                                    {`${r1[0].tied ? 'T' : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            {rotating && rounds >= 2 ? 
                                                                <React.Fragment>
                                                                    <td>{`${r2[0].round2Score > -1 ? r2[0].round2Score : ''}`}</td>
                                                                    <td>{`${r2[0].judgeRound2Ip > -1 ? r2[0].judgeRound2Ip : ''}`}</td>
                                                                    <td>{`${r2[0].round2Rank > -1 ? r2[0].round2Rank : ''}`}
                                                                    {`${r2[0].judgeRound2Tied ? 'T' : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            {rotating && rounds >= 2 && judgeCount >= 2 ? 
                                                                <React.Fragment>
                                                                    <td>{`${r2[1].round2Score > -1 ? r2[1].round2Score : ''}`}</td>
                                                                    <td>{`${r2[1].judgeRound2Ip > -1 ? r2[1].judgeRound2Ip : ''}`}</td>
                                                                    <td>{`${r2[1].round2Rank > -1 ? r2[1].round2Rank : ''}`}
                                                                    {`${r2[1].judgeRound2Tied ? 'T' : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            {rotating && rounds >= 2 && judgeCount >= 3 ? 
                                                                <React.Fragment>
                                                                    <td>{`${r2[2].round2Score > -1 ? r2[2].round2Score : ''}`}</td>
                                                                    <td>{`${r2[2].judgeRound2Ip > -1 ? r2[2].judgeRound2Ip : ''}`}</td>
                                                                    <td>{`${r2[2].round2Rank > -1 ? r2[2].round2Rank : ''}`}
                                                                    {`${r2[2].judgeRound2Tied ? 'T' : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            {rotating && rounds >= 2 && judgeCount >= 4 ? 
                                                                <React.Fragment>
                                                                    <td>{`${r2[3].round2Score > -1 ? r2[3].round2Score : ''}`}</td>
                                                                    <td>{`${r2[3].judgeRound2Ip > -1 ? r2[3].judgeRound2Ip : ''}`}</td>
                                                                    <td>{`${r2[3].round2Rank > -1 ? r2[3].round2Rank : ''}`}
                                                                    {`${r2[3].judgeRound2Tied ? 'T' : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            {rotating && rounds >= 2 && judgeCount >= 5 ? 
                                                                <React.Fragment>
                                                                    <td>{`${r2[4].round2Score > -1 ? r2[4].round2Score : ''}`}</td>
                                                                    <td>{`${r2[4].judgeRound2Ip > -1 ? r2[4].judgeRound2Ip : ''}`}</td>
                                                                    <td>{`${r2[4].round2Rank > -1 ? r2[4].round2Rank : ''}`}
                                                                    {`${r2[4].judgeRound2Tied ? 'T' : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            {rotating && rounds >= 2 ? 
                                                                <React.Fragment>
                                                                    <td>{`${r2[0].totalRound2Ip > -1 ? r2[0].totalRound2Ip : ''}`}</td>
                                                                    <td>{`${r2[0].finalTwoRoundIp > -1 ? r2[0].finalTwoRoundIp : ''}`}</td>
                                                                    <td>{`${r2[0].totalTwoRoundRank > -1 ? r2[0].totalTwoRoundRank : ''}`}
                                                                    {`${r2[0].tied ? 'T' : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }
                                                            
                                                            {rotating && rounds >= 3 ? 
                                                                <React.Fragment>
                                                                    <td>{`${r3[0].round3Score > -1 ? r3[0].round3Score : ''}`}</td>
                                                                    <td>{`${r3[0].judgeRound3Ip > -1 ? r3[0].judgeRound3Ip : ''}`}</td>
                                                                    <td>{`${r3[0].round3Rank > -1 ? r3[0].round3Rank : ''}`}
                                                                    {`${r3[0].judgeRound3Tied ? 'T' : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            {rotating && rounds >= 3 && judgeCount >= 2 ? 
                                                                <React.Fragment>
                                                                    <td>{`${r3[1].round3Score > -1 ? r3[1].round3Score : ''}`}</td>
                                                                    <td>{`${r3[1].judgeRound3Ip > -1 ? r3[1].judgeRound3Ip : ''}`}</td>
                                                                    <td>{`${r3[1].round3Rank > -1 ? r3[1].round3Rank : ''}`}
                                                                    {`${r3[1].judgeRound3Tied ? 'T' : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            {rotating && rounds >= 3 && judgeCount >= 3 ? 
                                                                <React.Fragment>
                                                                    <td>{`${r3[2].round3Score > -1 ? r3[2].round3Score : ''}`}</td>
                                                                    <td>{`${r3[2].judgeRound3Ip > -1 ? r3[2].judgeRound3Ip : ''}`}</td>
                                                                    <td>{`${r3[2].round3Rank > -1 ? r3[2].round3Rank : ''}`}
                                                                    {`${r3[2].judgeRound3Tied ? 'T' : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            {rotating && rounds >= 3 && judgeCount >= 4 ? 
                                                                <React.Fragment>
                                                                    <td>{`${r3[3].round3Score > -1 ? r3[3].round3Score : ''}`}</td>
                                                                    <td>{`${r3[3].judgeRound3Ip > -1 ? r3[3].judgeRound3Ip : ''}`}</td>
                                                                    <td>{`${r3[3].round3Rank > -1 ? r3[3].round3Rank : ''}`}
                                                                    {`${r3[3].judgeRound3Tied ? 'T' : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            {rotating && rounds >= 3 && judgeCount >= 5 ? 
                                                                <React.Fragment>
                                                                    <td>{`${r3[4].round3Score > -1 ? r3[4].round3Score : ''}`}</td>
                                                                    <td>{`${r3[4].judgeRound3Ip > -1 ? r3[4].judgeRound3Ip : ''}`}</td>
                                                                    <td>{`${r3[4].round3Rank > -1 ? r3[4].round3Rank : ''}`}
                                                                    {`${r3[4].judgeRound3Tied ? 'T' : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            {rotating && rounds >= 3 ? 
                                                                <React.Fragment>
                                                                    <td>{`${r3[0].totalRound3Ip > -1 ? r3[0].totalRound3Ip : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            {rotating ? 
                                                                <React.Fragment>
                                                                    <td>{`${r3[0].finalIp > -1 ? r3[0].finalIp : ''}`}</td>
                                                                    <td>{`${r3[0].finalRank > -1 ? r3[0].finalRank : ''}`}
                                                                    {`${r3[0].tied ? 'T' : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            {!final && !rotating ? 
                                                                <td>{`${r1[0].round1Score > -1 ? r1[0].round1Score : ''}`}</td>
                                                                : null
                                                            }

                                                            {!final && !rotating && rounds >= 2 ?
                                                                <React.Fragment>
                                                                    <td>{`${r2[0].round2Score > -1 ? r2[0].round2Score : ''}`}</td>
                                                                    {rounds >= 3 && panelType !== 'SPLIT'?
                                                                    <td>{`${r3[0].round3Score > -1 ? r3[0].round3Score : ''}`}</td> : null}
                                                                </React.Fragment>

                                                                : null}

                                                            {!final && !rotating && rounds > 1 ?
                                                                <React.Fragment>
                                                                    <td>{`${r1[0].judgeTotalScore > -1 ? r1[0].judgeTotalScore : ''}`}</td>
                                                                </React.Fragment> : null

                                                            }

                                                            {!final && !rotating && judgeCount > 1 && rounds > 1 ?
                                                                <React.Fragment>
                                                                    <td>{`${r1[0].judgeFinalRank > -1 ? r1[0].judgeFinalRank : ''}`}
                                                                    {`${r1[0].judgeTied ? 'T' : ''}`}</td>
                                                                </React.Fragment> : null

                                                            }      
                                                            {!final && !rotating && judgeCount > 1 && rounds === 1 ?
                                                                <React.Fragment>
                                                                    <td>{`${r1[0].judgeFinalIp > -1 ? r1[0].judgeFinalIp : ''}`}</td>
                                                                    <td>{`${r1[0].judgeFinalRank > -1 ? r1[0].judgeFinalRank : ''}`}
                                                                    {`${r1[0].judgeTied ? 'T' : ''}`}</td>
                                                                </React.Fragment> : null

                                                            }                                                
                                                            {!final && !rotating && judgeCount > 1 && panelType === 'SPLIT' ?
                                                                <React.Fragment>
                                                                    <td>{`${r1[0].judgeFinalIp > -1 ? r1[0].judgeFinalIp : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            {!final && !rotating && judgeCount > 1 ?
                                                                <td>{`${r1[1].round1Score > -1 ? r1[1].round1Score : ''}`}</td>
                                                            : null
                                                            }


                                                            {!final && !rotating && rounds >= 2 && judgeCount > 1 ?
                                                                <React.Fragment>
                                                                    <td>{`${r2[1].round2Score > -1 ? r2[1].round2Score : ''}`}</td>
                                                                    {rounds >= 3 && judgeCount > 1 && panelType !== 'SPLIT'?
                                                                    <td>{`${r3[1].round3Score > -1 ? r3[1].round3Score : ''}`}</td> : null}
                                                                </React.Fragment>

                                                                : null}

                                                            {!final && !rotating && judgeCount > 1 && rounds > 1 ?
                                                                <React.Fragment>
                                                                    <td>{`${r1[1].judgeTotalScore > -1 ? r1[1].judgeTotalScore : ''}`}</td>
                                                                    <td>{`${r1[1].judgeFinalRank > -1 ? r1[1].judgeFinalRank : ''}`}
                                                                    {`${r1[1].judgeTied ? 'T' : ''}`}</td>
                                                                </React.Fragment> : null

                                                            }      
                                                            {!final && !rotating && judgeCount > 1 && rounds === 1 ?
                                                                <React.Fragment>
                                                                    <td>{`${r1[1].judgeFinalIp > -1 ? r1[1].judgeFinalIp : ''}`}</td>
                                                                    <td>{`${r1[1].judgeFinalRank > -1 ? r1[1].judgeFinalRank : ''}`}
                                                                    {`${r1[1].judgeTied ? 'T' : ''}`}</td>
                                                                </React.Fragment> : null

                                                            }                                                
                                                            {!final && !rotating && judgeCount > 1 && panelType === 'SPLIT' ?
                                                                <React.Fragment>
                                                                    <td>{`${r1[1].judgeFinalIp > -1 ? r1[1].judgeFinalIp : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            
                                                            {!final && !rotating && judgeCount > 2 ?
                                                                <td>{`${r1[2].round1Score > -1 ? r1[2].round1Score : ''}`}</td>
                                                            : null
                                                            }


                                                            {!final && !rotating && rounds >= 2 && judgeCount > 2 ?
                                                                <React.Fragment>
                                                                    <td>{`${r2[2].round2Score > -1 ? r2[2].round2Score : ''}`}</td>
                                                                    {rounds >= 3 && judgeCount > 1 && panelType !== 'SPLIT'?
                                                                    <td>{`${r3[2].round3Score > -1 ? r3[2].round3Score : ''}`}</td> : null}
                                                                </React.Fragment>

                                                                : null}

                                                            {!final && !rotating && judgeCount > 2 && rounds > 1 ?
                                                                <React.Fragment>
                                                                    <td>{`${r1[2].judgeTotalScore > -1 ? r1[2].judgeTotalScore : ''}`}</td>
                                                                    <td>{`${r1[2].judgeFinalRank > -1 ? r1[2].judgeFinalRank : ''}`}
                                                                    {`${r1[2].judgeTied ? 'T' : ''}`}</td>
                                                                </React.Fragment> : null

                                                            }   
                                                            {!final && !rotating && judgeCount > 2 && rounds === 1 ?
                                                                <React.Fragment>
                                                                    <td>{`${r1[2].judgeFinalIp > -1 ? r1[2].judgeFinalIp : ''}`}</td>
                                                                    <td>{`${r1[2].judgeFinalRank > -1 ? r1[2].judgeFinalRank : ''}`}
                                                                    {`${r1[2].judgeTied ? 'T' : ''}`}</td>
                                                                </React.Fragment> : null

                                                            }                                                  
                                                            {!final && !rotating && judgeCount > 2 && panelType === 'SPLIT' ?
                                                                <React.Fragment>
                                                                    <td>{`${r1[2].judgeFinalIp > -1 ? r1[2].judgeFinalIp : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            
                                                            {!final && !rotating && judgeCount > 3 ?
                                                                <td>{`${r1[3].round1Score > -1 ? r1[3].round1Score : ''}`}</td>
                                                            : null
                                                            }


                                                            {!final && !rotating && rounds >= 2 && judgeCount > 3 ?
                                                                <React.Fragment>
                                                                    <td>{`${r2[3].round2Score > -1 ? r2[3].round2Score : ''}`}</td>
                                                                    {rounds >= 3 && judgeCount > 1 && panelType !== 'SPLIT'?
                                                                    <td>{`${r3[3].round3Score > -1 ? r3[3].round3Score : ''}`}</td> : null}
                                                                </React.Fragment>

                                                                : null}

                                                            {!final && !rotating && judgeCount > 3 && rounds > 1 ?
                                                                <React.Fragment>
                                                                    <td>{`${r1[3].judgeTotalScore > -1 ? r1[3].judgeTotalScore : ''}`}</td>
                                                                    <td>{`${r1[3].judgeFinalRank > -1 ? r1[3].judgeFinalRank : ''}`}
                                                                    {`${r1[3].judgeTied ? 'T' : ''}`}</td>
                                                                </React.Fragment> : null

                                                            }         
                                                            {!final && !rotating && judgeCount > 3 && rounds === 1 ?
                                                                <React.Fragment>
                                                                    <td>{`${r1[3].judgeFinalIp > -1 ? r1[3].judgeFinalIp : ''}`}</td>
                                                                    <td>{`${r1[3].judgeFinalRank > -1 ? r1[3].judgeFinalRank : ''}`}
                                                                    {`${r1[3].judgeTied ? 'T' : ''}`}</td>
                                                                </React.Fragment> : null

                                                            }                                              
                                                            {!final && !rotating && judgeCount > 3 && panelType === 'SPLIT' ?
                                                                <React.Fragment>
                                                                    <td>{`${r1[3].judgeFinalIp > -1 ? r1[3].judgeFinalIp : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            
                                                            {!final && !rotating && judgeCount > 4 ?
                                                                <td>{`${r1[4].round1Score > -1 ? r1[4].round1Score : ''}`}</td>
                                                            : null
                                                            }


                                                            {!final && !rotating && rounds >= 2 && judgeCount > 4 ?
                                                                <React.Fragment>
                                                                    <td>{`${r2[4].round2Score > -1 ? r2[4].round2Score : ''}`}</td>
                                                                    {rounds >= 3 && judgeCount > 1 && panelType !== 'SPLIT'?
                                                                    <td>{`${r3[4].round3Score > -1 ? r3[4].round3Score : ''}`}</td> : null}
                                                                </React.Fragment>

                                                                : null}

                                                            {!final && !rotating && judgeCount > 4 && rounds > 1 ?
                                                                <React.Fragment>
                                                                    <td>{`${r1[4].judgeTotalScore > -1 ? r1[4].judgeTotalScore : ''}`}</td>
                                                                    <td>{`${r1[4].judgeFinalRank > -1 ? r1[4].judgeFinalRank : ''}`}
                                                                    {`${r1[4].judgeTied ? 'T' : ''}`}</td>
                                                                </React.Fragment> : null

                                                            }      
                                                            {!final && !rotating && judgeCount > 4 && rounds === 1 ?
                                                                <React.Fragment>
                                                                    <td>{`${r1[4].judgeFinalIp > -1 ? r1[4].judgeFinalIp : ''}`}</td>
                                                                    <td>{`${r1[4].judgeFinalRank > -1 ? r1[4].judgeFinalRank : ''}`}
                                                                    {`${r1[4].judgeTied ? 'T' : ''}`}</td>
                                                                </React.Fragment> : null

                                                            }                                                    
                                                            {!final && !rotating && judgeCount > 4 && panelType === 'SPLIT' ?
                                                                <React.Fragment>
                                                                    <td>{`${r1[4].judgeFinalIp > -1 ? r1[4].judgeFinalIp : ''}`}</td>
                                                                </React.Fragment>
                                                                : null
                                                            }

                                                            {!final && !rotating && rounds === 1 ?

                                                            <React.Fragment>
                                                                {/* <td>{`${r1[0].finalIp > -1 ? r1[0].finalIp.toFixed(2) : ''}`}</td> */}
                                                                <td>{`${r1[0].finalRank > -1 ? r1[0].finalRank : ''}`}
                                                                {`${r1[0].tied ? 'T' : ''}`}</td>
                                                            </React.Fragment>
                                                            : null
                                                            }


                                                            {!final && !rotating && rounds > 1 && panelType !== 'SPLIT' ?
                                                                <React.Fragment>
                                                                    <td>{`${r1[0].finalIp > -1 ? r1[0].finalIp.toFixed(2) : ''}`}</td>
                                                                    <td>{`${r1[0].finalRank > -1 ? r1[0].finalRank : ''}`}
                                                                        {`${r3[0].tied ? 'T' : ''}`}</td>
                                                                    {/* <td>{`${r1[0].finalScore > -1 ? r1[0].finalScore : ''}`}</td> */}
                                                                </React.Fragment>
                                                                    : null
                                                            }

                                                            {!final && !rotating && rounds > 1 && panelType === 'SPLIT' ?
                                                                <React.Fragment>
                                                                    <td>{`${r1[0].finalTwoRoundIp > -1 ? r1[0].finalTwoRoundIp.toFixed(2) : ''}`}</td>
                                                                    <td>{`${r1[0].totalTwoRoundRank > -1 ? r1[0].totalTwoRoundRank : ''}`}
                                                                        {`${r1[0].final2roundTied ? 'T' : ''}`}</td>
                                                                </React.Fragment>
                                                                    : null
                                                            }

                                                            {final ?
                                                                <React.Fragment>
                                                                    <td>{`${r3[0].round3Score > -1 ? r3[0].round3Score : ''}`}</td>
                                                                    <td>{`${r3[0].round3Rank > -1 ? r3[0].round3Rank : ''}`}
                                                                        {`${r3[0].judgeRound3Tied ? 'T' : ''}`}</td>
                                                                    <td>{`${r3[0].round3Ip > -1 ? r3[0].round3Ip : ''}`}</td>
                                                                </React.Fragment>
                                                                    : null
                                                            }

                                                            {final && judgeCount > 1 ?
                                                                <React.Fragment>
                                                                    <td>{`${r3[1].round3Score > -1 ? r3[1].round3Score : ''}`}</td>
                                                                    <td>{`${r3[1].round3Rank > -1 ? r3[1].round3Rank : ''}`}
                                                                        {`${r3[1].judgeRound3Tied ? 'T' : ''}`}</td>
                                                                    <td>{`${r3[1].round3Ip > -1 ? r3[1].round3Ip : ''}`}</td>
                                                                </React.Fragment>
                                                                    : null
                                                            }

                                                            {final && judgeCount > 2 ?
                                                                <React.Fragment>
                                                                    <td>{`${r3[2].round3Score > -1 ? r3[2].round3Score : ''}`}</td>
                                                                    <td>{`${r3[2].round3Rank > -1 ? r3[2].round3Rank : ''}`}
                                                                        {`${r3[2].judgeRound3Tied ? 'T' : ''}`}</td>
                                                                    <td>{`${r3[2].round3Ip > -1 ? r3[2].round3Ip : ''}`}</td>
                                                                </React.Fragment>
                                                                    : null
                                                            }

                                                            {final && judgeCount > 3 ?
                                                                <React.Fragment>
                                                                    <td>{`${r3[3].round3Score > -1 ? r3[3].round3Score : ''}`}</td>
                                                                    <td>{`${r3[3].round3Rank > -1 ? r3[3].round3Rank : ''}`}
                                                                        {`${r3[3].judgeRound3Tied ? 'T' : ''}`}</td>
                                                                    <td>{`${r3[3].round3Ip > -1 ? r3[3].round3Ip : ''}`}</td>
                                                                </React.Fragment>
                                                                    : null
                                                            }

                                                            {final && judgeCount > 4 ?
                                                                <React.Fragment>
                                                                    <td>{`${r3[4].round3Score > -1 ? r3[4].round3Score : ''}`}</td>
                                                                    <td>{`${r3[4].round3Rank > -1 ? r3[4].round3Rank : ''}`}
                                                                        {`${r3[4].judgeRound3Tied ? 'T' : ''}`}</td>
                                                                    <td>{`${r3[4].round3Ip > -1 ? r3[4].round3Ip : ''}`}</td>
                                                                </React.Fragment>
                                                                    : null
                                                            }

                                                            {final ?
                                                                <React.Fragment>
                                                                    <td>{`${r3[0].finalTwoRoundIp > -1 ? r3[0].finalTwoRoundIp.toFixed(2) : ''}`}</td>
                                                                    <td>{`${r3[0].totalRound3Ip > -1 ? r3[0].totalRound3Ip.toFixed(2) : ''}`}</td>
                                                                    <td>{`${r3[0].finalIp > -1 ? r3[0].finalIp.toFixed(2) : ''}`}</td>
                                                                    <td>{`${r3[0].finalRank > -1 ? r3[0].finalRank : ''}`}
                                                                        {`${r3[0].tied ? 'T' : ''}`}</td>
                                                                </React.Fragment>
                                                                    : null
                                                            }

                                                            {this.props.events.competition.competition.grade.recallType !== "NONE" ?
                                                                <td><FormControl
                                                                    disabled={this.props.locked || final}
                                                                    type="checkbox"
                                                                    name="recall"
                                                                    checked={r1[0].recall || false}
                                                                    onChange={e => {
                                                                        this.props.updateEntryScore(r1[0], 3, "recall", !r1[0].recall)
                                                                    }}
                                                                    className={"no-width float-right"}
                                                                /></td>
                                                                : null
                                                            }
                                                            
                                                            {final || panelType !== 'SPLIT' ?
                                                                <td><FormControl
                                                                    disabled={this.props.locked}
                                                                    type="checkbox"
                                                                    name="placed"
                                                                    checked={r1[0].placed || false}
                                                                    onChange={e => {
                                                                        this.props.updateEntryScore(r1[0], 3, "placed", !r1[0].placed)
                                                                    }}
                                                                    className={"no-width float-right"}
                                                                /></td>
                                                                : null
                                                            }

                                                            {/* {final || panelType !== 'SPLIT' ?
                                                                <td><FormControl
                                                                    disabled={this.props.locked}
                                                                    type="checkbox"
                                                                    name="worldQualifier"
                                                                    checked={r1[0].wq || false}
                                                                    onChange={e => {
                                                                        this.props.updateEntryScore(r1[0], 3, "wq", !r1[0].wq)
                                                                    }}
                                                                    className={"no-width float-right"}
                                                                /></td>
                                                                : null
                                                            } */}

                                                            {panelType === 'ROTATING' ?
                                                                <td><FormControl
                                                                    disabled={this.props.locked}
                                                                    type="checkbox"
                                                                    name="wmh"
                                                                    checked={r1[0].wmh || false}
                                                                    onChange={e => {
                                                                        this.props.updateEntryScore(r1[0], 3, "wmh", !r1[0].wmh)
                                                                    }}
                                                                    className={"no-width float-right"}
                                                                /></td>
                                                                : null
                                                            }

                                                            {rotating ?         // This should really be comp driven but showing it for rotating to make sure it shows for WCRO
                                                                <td><FormControl
                                                                    disabled={this.props.locked}
                                                                    type="checkbox"
                                                                    name="naQualifier"
                                                                    checked={r1[0].nq || false}
                                                                    onChange={e => {
                                                                        this.props.updateEntryScore(r1[0], 3, "nq", !r1[0].nq)
                                                                    }}
                                                                    className={"no-width float-right"}
                                                                /></td>
                                                                : null
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <div className={"text-right ex-pad"}>
                                    <Button variant={"primary"} onClick={e => {
                                        e.preventDefault();
                                        this.props.updateTabScore(
                                            this.props.events.competition.template, 
                                            this.props.events.competition.tabulation, 
                                            this.props.auth.token._id, 
                                            false,
                                            this.props.events.competition.competition._id,
                                            this.props.events.competition.competition.grade.rounds);
                                    }}><Checkmark />{this.props.events.competition.competition.saving ? 'Saving...' :  'Save'}</Button>
                                </div>

                            </div>
                        </Form>
                    )}
                </Formik>
            </PageLoader>

        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user,
    events: state.events,
    single: state.single,
    tabulator: state.tabulator
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    updateTabScore: (template, tabulation, token, autosave, competitionId, rounds) => {
        dispatch(eventActions.updateTabulatorScore(template, tabulation, token, autosave, competitionId, rounds));
    },
    updateScore: (entry, value, judgeIndex, round) => {
        dispatch(eventActions.updateScore(entry, value, judgeIndex, round));
    },
    updateJudge: (round, judge, val) => {
        dispatch(eventActions.updateJudge(round, judge, val));
    },
    updateJudgeLabel: (round, judge, val) => {
        dispatch(eventActions.updateJudgeLabel(round, judge, val));
    },
    updateTabulationObject: (tab, token) => {
        dispatch(eventActions.updateTabulationObject(tab, token));
    },
    updateEntryScore: (entry, round, key, val) => {
        dispatch(eventActions.updateEntryScore(entry, round, key, val));
    },
    generateCompetitionResults: (eventId, competitionId, token) => {
        dispatch(eventActions.generateCompetitionResults(eventId, competitionId, token));
    },
    deleteCompetitionResults: (competitionId, token) => {
        dispatch(eventActions.deleteCompetitionResults(competitionId, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(TabulatorOverviewTable));

class TabulatorOverviewTableContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default TabulatorOverviewTableContainer;
