import React from 'react';

import PDF from "./ResultPDFComponent";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as tableActions from '../../../store/actions/table';

import moment from 'moment';
import { isPlaceholderType } from '@babel/types';

const PDFPage = (props) => {
  const roundNumber = 1; // These are final results, r1 will have all competitors, so use r1 scores for the map function
  const judgeNumber = 0;  // Not dependent on any specific judge
  const final = props.match.params.final === "true" ? true : false;


  const events = props.events;
  if (!events) return null;
  const rounds = events.competition.template.rounds;
  const round = rounds[roundNumber];
  var scores = round.scores[judgeNumber];
  // var scores = round.scores[judgeNumber].filter(item => item.recall);
  var judges = []

  Object.values(rounds).forEach((round) => round.judges.forEach((judge) => { if (judge.name) {judges.push(`${judge.name} ${judge.lname}`)}}));

  let grade = events.competition.competition.grade;
  let comp = events.competition.competition;
  let panelType = events.competition.competition.grade.scoring.judge_rotation_type;
  let judgeCount = events.competition.competition.grade.judges;
  let roundCount = events.competition.competition.grade.rounds;
  let recall = events.competition.competition.grade.recallType !== 'NONE';

  let championship = events.competition.competition.grade.type === 'championship' ||
                     events.competition.competition.grade.type === 'championshipspecial';

  let ageTitle = '';

  for (var col of grade.columns) {
    if (col.id.toString() === comp.column) {
      ageTitle = col.title;
    }
  }

  const competitionName = events.competition.competition.code + " | " +
    events.competition.competition.grade.title + " | " +
    events.competition.competition.rowTitle + " | " +
    ageTitle

  const titles = [
    // event.name,
    competitionName,
    `${moment(events.event.judgingEnd).format('MMMM Do YYYY')}`
  ]

  // Pretty sure this is not used, but it is passed in to PDF
  const headers = [
    "Dancer Number",
    "Name",
    "School",
    "Final IP All rounds",
    "Final Rank",
    "WQ",
    "NQ",
  ]


  let r = props.events.competition.competition.grade.rounds;
  let entriesMain = events.competition.entries;
  let entries = [];
  let scores1 = JSON.parse(JSON.stringify(events.competition.template.rounds["1"].scores[0]));
  let scores3 = JSON.parse(JSON.stringify(events.competition.template.rounds["3"].scores[0]));
  if (events.competition.competition.grade.rounds === 1) {
    scores1 = scores1.sort((a, b) => b.finalIp - a.finalIp);
    scores1.forEach(item => {
      let entry = entriesMain.filter(it => it._id === item.entryCompetition)[0];
      entries.push(entry);
    })
  } else {
    scores3 = scores3.sort((a, b) => b.finalIp - a.finalIp);
    scores3.forEach(item => {
      let entry = entriesMain.filter(it => it._id === item.entryCompetition)[0];
      entries.push(entry);
    })
  }

  var content = scores.map((score, i) => {
    let rel = entries.filter(item => item._id === score.entryCompetition);
    let s = {};

    if (rel.length > 0) {
      s = rel[0];
    }

        let r1 = []

        events.competition.template.rounds["1"].scores.forEach((item, index) => {
          item.forEach(en => {
            if (en.entryCompetition === s._id) {
              r1.push(en);
            }
          })
        });

        let r2 = []
        events.competition.template.rounds["2"].scores.forEach((item, index) => {
          item.forEach(en => {
            if (en.entryCompetition === s._id) {
              r2.push(en);
            }
          })
        });

        let r3 = [];

        events.competition.template.rounds["3"].scores.forEach((item, index) => {
          item.forEach(en => {
            if (en.entryCompetition === s._id) {
              r3.push(en);
            }
          })
        });
        // console.log("COMP", comp)
        const dancer = s.dancer;
        let res = [
          s.dancerNumber ? s.dancerNumber : 0,
          s.dancer ? s.dancer.name + " " + s.dancer.lname : "",
          s.dancer ? s.dancer.school?.name : "",];
          let rd3totalString;

        if (final) {
          for(let i = 0; i < judgeCount; i++) {
            rd3totalString = recall && r3[i].recall === false ? "N/A-0" : r3[i].judgeFinalRank + "/" + r3[i].judgeFinalIp;
            res.push(`${r3[i].round3Score > 0 ? r3[i].round3Score : 0}`);
            res.push(`${rd3totalString}`);
          }

          res = res.concat([
            `${r3[0].finalTwoRoundIp.toFixed(2)}`,
            `${r3[0].totalRound3Ip.toFixed(2)}`,
            `${r3[0].finalIp.toFixed(2)}`,
            `${r3[0].finalRank}`,
            `${r3[0].placed ? 'Y' : 'N'}`
          ])
        } else if (panelType === 'ROTATING') {
          for(let r = 1; r <= roundCount; r++) {
            let roundArray;
            let scoreField;
            let ipField;
            let rankField;
            let totalIPField;
            switch (r) {
              case 1:
                roundArray = r1;
                scoreField = "round1Score";
                ipField = "round1Ip";
                rankField = "totalRound2Ip";
                totalIPField = "totalRound1Ip";
                break;
              case 2:
                roundArray = r2;
                scoreField = "round2Score";
                ipField = "round2Ip";
                rankField = "totalRound2Ip";
                totalIPField = "totalRound2Ip";
                break;
              case 3:
                roundArray = r3;
                scoreField = "round3Score";
                ipField = "round3Ip";
                rankField = "totalRound2Ip";
                totalIPField = "totalRound3Ip";
                break;
            }
            for(let i = 0; i < judgeCount; i++) {
              if (r ===  3) {
                rd3totalString = r3[i].recall === false ? "" : r3[i].round3Score + "/" + r3[i].round3Ip;
                res.push(`${rd3totalString}`);
              } else {
                res.push(`${roundArray[i][scoreField] + "/" + roundArray[i][ipField]}`);
              }
            }
            
            // Total IP + rank for the round
            res.push(`${roundArray[0][totalIPField]}`);

            // Add additional IP fields (rounds 2 and 3 only) and rank
            switch (r) {
              case 1:
                res.push(`${roundArray[0]['totalRound1Rank'] + ((roundArray[0]['totalRound1Tied'] === true) ? 'T' : '')}`);
                break;
              case 2:
                res.push(`${roundArray[0]['finalTwoRoundIp']}`);
                res.push(`${roundArray[0]['totalTwoRoundRank'] + ((roundArray[0]['final2roundTied'] === true) ? 'T' : '')}`);
                res.push(`${(roundArray[0]['recall'] === true) ? 'Y' : 'N'}`);
                break;
              case 3:
                res.push(`${roundArray[0]['finalIp']}`);
                res.push(`${roundArray[0]['finalRank'] + ((roundArray[0]['tied'] === true) ? 'T' : '')}`);
                res.push(`${((roundArray[0]['wq'] === true) || (roundArray[0]['wmh'] === true)) ? 'Y' : 'N'}`);
                res.push(`${(roundArray[0]['nq'] === true) ? 'Y' : 'N'}`);
                console.log("recall", r1[0].recall);
                break;
            }
          }
        } else if (panelType === "SET") {
          // Per judge fields
          for(let v = 0; v < judgeCount; v++) {

            res.push(`${r1[v].round1Score}`);

            if (r >= 2) {
              res.push(`${r2[v].round2Score}`);
            }
            if (r >= 3) {
              res.push(`${r3[v].round3Score}`);
            }

            // Add judge total if this was more than 1 rd
            if (r >= 2) {
              res.push(`${r1[v].judgeTotalScore}`);
              if (championship) {
                res.push(`${r1[v].judgeFinalIp}`);      // TODO: This should be shown/hidden based on a show IP toggle at the category level
              }
            }
            

          }

          // Final IP, Rank, and Placed
          if (judgeCount >= 2 && championship) {
            res.push(`${r1[0].finalIp}`);
          }
          res.push(`${r1[0].finalRank + ((r1[0].tied === true) ? 'T' : '')}`);
          if (judgeCount >= 2 || r >= 2) {
            res.push(`${r1[0].placed ? 'Y' : 'N'}`);
          }

          // TODO: Based on toggles, add in Recall, Placed, WQ, and NQ columns

        } else {  // Split panel, rounds 1 + 2
          for(let i = 0; i < judgeCount; i++) {
            if (r === 1) {
              res.push(`${r1[i].round1Score}`);
            } else {
              res.push(`${r1[i].round1Score}` + "/" +  `${r1[i].round2Score}`);
            }
              
            if (r >= 3 && panelType !== 'SPLIT') {
              res.push(`${r3[i].round3Score}`);
            }
  
            if (r > 1) {
              res.push(`${r1[i].judgeTotalScore}`);
            }
            res.push(`${r1[i].judgeFinalRank}` + "/" +  `${r1[i].judgeFinalIp}`);
  
          }
          if (panelType === 'SPLIT') {
            res = res.concat([
              `${r1[0].finalTwoRoundIp.toFixed(2)}`,
              `${r1[0].totalTwoRoundRank}`
            ])
            if (recall) {
              res.push(`${r1[0].recall ? 'Y' : 'N'}`);
            }
          } else {
            res = res.concat([
              `${r1[0].finalIp.toFixed(2)}`,
              `${r1[0].finalRank}`,
              `${r1[0].placed ? 'Y' : 'N'}`
            ])
          }
        }


    console.log("RES", res);
    return res;

  });

  //console.log("SCORES", scores)

/*
  Object.values(rounds).forEach((round, i) => {
    round.scores.forEach((judgeScore) => {
      judgeScore.forEach((entry, j) => {
        content[j].push(`${entry["round" + (i + 1) + "Score"]} / ${entry["round" + (i + 1) + "Ip"]}`)
      })
    }
    )
    round.scores[0].forEach((entry, j) => {
      content[j].push(entry["round" + (i + 1) + "Ip"]).toFixed(2)
      if (i !== 0) {
        content[j].push(entry["finalIp"].toFixed(2))

      }
      content[j].push(entry["finalRank"])
      if (i === 1) {
        content[j].push(entry["recall"] ? "Y" : "N")
      }
    })

  })

  rounds[1].scores[0].forEach((entry, j) => {
    content[j].push(`${entry.wq ? 'Y' : 'N'}`)
  })
*/

// TODO: Fix this whole disaster
let sortIndex = final ? (judgeCount * 2) + 6 : (judgeCount * 3) + 4;    // We'll do this better later but for now we need the reports out
let offset = 1;                     // Recall or Placed col is usually right after rank column
if ( r === 1) {
  if (judgeCount === 1) {
    sortIndex = 4;    // 1 rd 1 judge only has 5 columns
  } else {
    sortIndex = (judgeCount * 2) + 4;
  }
} else if (r === 2 && judgeCount === 1) {
  sortIndex = 6;
} else if (panelType === 'SET') {
  sortIndex = (judgeCount * 5) + 4;
} else if (panelType === 'ROTATING') {
  sortIndex = (judgeCount * 3) + 11;
  offset = -3 - judgeCount;

}
  content = content.sort((a, b) => {
    let val1 = a[sortIndex].replace('T','');
    let val2 = b[sortIndex].replace('T','');
    return val1 - val2
  })

  if (r === 2) {
    content = content.sort((a, b) => {
      return a[16] - b[16]
    })
  }
  let addedPlacedText = false;
  let textVal = (final || panelType === 'SET') ? 'Dancers below this line did not place' : 'Dancers below this line did not recall'

  for(let c of content) {
    let index = content.indexOf(c);
    if (r === 3) {

      if (content[index + 1]) {
        if (c[sortIndex + offset] === "Y" && content[index + 1][sortIndex + offset] === "N" && !addedPlacedText) {
          console.log("C", c);
          addedPlacedText = true;
          content.splice(index + 1, 0, [textVal]);
        }
      }
    } else {
      if (content[index + 1]) {
        if (c[16] === "Y" && content[index + 1][16] === "N" && !addedPlacedText) {
          console.log("C", c);
          addedPlacedText = true;
          content.splice(index + 1, 0, ['Dancers below this line did not place']);
        }
      }
    }
  }

  console.log("CONTENTS", content);


  let tables = [];
  var i,j, temporary, chunk = 15;
  for (i = 0,j = content.length; i < j; i += chunk) {
    temporary = content.slice(i, i + chunk);
    tables.push({
      headers,
      content: temporary
    });
    // do whatever
  }


  console.log("Tables", tables);



  const table = {
    headers,
    content
  }

  const pdfData = {
    table,
    titles,
    tables,
    events: props.events,
    image: props.events.competition.competition.grade.event.image.path,
    eventName: props.events.competition.competition.grade.event.name,
    final: final
  }

  return (
    <div style={{ height: "100vh" }}>

      <PDF pdfData={pdfData} judges={judges} />
    </div>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  table: state.table,
  user: state.user,
  events: state.events
});


const mapDispatchToProps = dispatch => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  },
});


const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(PDFPage));

class PDFPageContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    )
  }
}

export default PDFPageContainer;
