/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 6/6/2020
 */

const defaultState = {
    data: [],
    files: [],
    favorites: [],
    tableLoading: false,
    tableCount: 0,
    tableSorted: {},
    view: '',
    subView: '',
    updateCount: 0,
    instruments: [],
    dancers: [],
    events: [],
    entries: [],
    studentEntries: [],
    students: [],
    judgeEvents: [],
    judgeEvent: {}

};

const table = (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_BLANK_TABLE':
            return {
                ...state,
                data: []
            }
        case 'DISABLED_COMPETITIONS':
            return {
                ...state,
                data: state.data.filter(item => item.count > 0)
            }
        case 'GOT_STUDENTS':
            return {
                ...state,
                students: action.payload.data
            }
        case 'GOT_STUDENT_ENTRIES':
            return {
                ...state,
                studentEntries: action.payload.data
            }
        case 'GOT_HOME_DATA':
            return {
                ...state,
                dancers: action.payload.dancers || [],
                events: action.payload.events || [],
                entries: action.payload.entries || [],
                judgeEvents: action.payload.judgeEvents || [],
                tableLoading: false
            }
        case 'GOT_JUDGE_DATA':
            return {
                ...state,
                judgeEvents: action.payload.judgeEvents || []
            }
        case 'LOGOUT':
            return defaultState;
        case 'SET_LOAD_STATE':
            return {
                ...state,
                tableLoading: action.payload
            }
        case 'SET_TABLE_VIEW':
            return {
                ...state,
                view: action.payload
            }
        case 'GOT_FILE_DATA':
            return {
                ...state,
                tableLoading: false,
                fileCount: action.payload.count,
                files: action.payload.data,
                favorites: action.payload.favorites,
                view: action.payload.view,
                subView: action.payload.subView || '',
            }
        case 'GOT_MORE_FILE_DATA':
            return {
                ...state,
                tableLoading: false,
                fileCount: state.fileCount + action.payload.count,
                files: state.files.concat(action.payload)
            }
        case 'GOT_TABLE_NESTED_DATA':
            return {
                ...state,
                tableLoading: false,
                tableCount: action.payload.count,
                data: action.payload.data.data,
                judgeEvent: action.payload.data.judgeEvent ? action.payload.data.judgeEvent : state.judgeEvent,
                hasMore: action.payload.hasMore || false,
                view: action.payload.view,
                updateCount: state.updateCount + 1,

            }
        case 'GOT_TABLE_DATA':
            return {
                ...state,
                tableLoading: false,
                tableCount: action.payload.count,
                data: action.payload.data,
                hasMore: action.payload.hasMore || false,
                view: action.payload.view,
                updateCount: state.updateCount + 1
            }
        case 'GOT_MORE_TABLE_DATA':
            return {
                ...state,
                tableLoading: false,
                tableCount: state.tableCount + action.payload.count,
                data: state.data.concat(action.payload.data.filter(d => {
                    return state.data.filter(f => f._id === d._id).length === 0;
                })),
                view: action.payload.view,
                hasMore: action.payload.hasMore || false,
                updateCount: state.updateCount + 1
            }
        case 'FILES_DELETED':
            return {
                ...state,
                files: state.files.filter(f => action.payload.indexOf(f._id) === -1)
            }
        case 'ADDED_FILE':

            return {
                ...state,
                files: state.files.concat(action.payload.data)
            }
        case 'FILE_DELETED':
            return {
                ...state,
                files: state.files.filter(d => d._id !== action.payload)
            }
        case 'ITEM_DELETED':
            return {
                ...state,
                data: state.data.filter(d => d._id !== action.payload)
            }
        case 'SORTED_TABLE':
            return {
                ...state,
                tableSorted: action.payload
            }
        case 'ADDED_ITEM':
            return {
                ...state,
                updateCount: state.updateCount + 1,
                data: state.data.concat({
                    ...action.payload.data,
                    id: state.data.length
                }),
                tableCount: state.tableCount + 1
            }
        case 'ADDED_ITEMS':
            return {
                ...state,
                data: state.data.concat(action.payload),
                updateCount: state.updateCount + action.payload.length,
                tableCount: state.tableCount + action.payload.length
            }
        case 'UPDATED_ITEM':
            return {
                ...state,
                updateCount: state.updateCount + 1,
                data: state.data.map(d => {
                    if (action.payload.data) {
                        if (d._id === action.payload.data._id) {
                            return {
                                ...d,
                                ...action.payload.data
                            }
                        } else {
                            return d
                        }
                    }else {
                        return d
                    }
                })
            }
        case 'SET_DATA':
            return {
                ...state,
                data: action.payload
            }
        default:
            return {
                ...state
            };
    }
}

export default table;