/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 1/13/2021
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import urls from '../../../../utils/urls';
import * as singleActions from '../../../../store/actions/single';
import FormButtons from '../../../Common/FormButtons';
import 'react-quill/dist/quill.snow.css';

const schema = yup.object({
  name: yup.string().required('This field is required')
});

class EditScoring extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileSelectField: '',
      filePickerOpen: false,
      selectingFileType: 'image',
      pdf: '',
      preview: '',
      image: '',
      createModerator: '',
      createAdmin: ''
    };
  }

  handleFormModal = (e) => {
    this.setState({ filePickerOpen: false });
  };

  selectFile = (file) => {
    //this.props.updateField('image', file);
    this.props.setValues({ image: file });
  };

  componentDidMount() {
    this.props.get(this.props.match.params.id, this.props.auth.token._id);
    this.init();
  }

  componentDidUpdate(props, state) {
    if (props.single.single !== this.props.single.single) {
      this.init();
    }
  }

  init = () => {
    if (this.props.single.single.image) {
      if (this.props.single.single.image.path) {
        this.setState({
          preview: urls.getS3Url(
            this.props.single.single.image.thumb || this.props.single.single.image.path
          )
        });
      }
    } else if (this.state.preview) {
      this.setState({ preview: '' });
    }
  };

  handleSubmit = (values) => {
    let view = 'scoring';
    let params = values;

    if (this.props.match.params.id !== 'new') {
      params._id = this.props.match.params.id;
    }

    if (params._id) {
      this.props.patch(view, params._id, params, this.props.auth.token._id);
    } else {
      this.props.post(view, params, this.props.auth.token._id, (id) => {
        this.props.history.push('/app/scoring/' + id);
      });
    }
  };

  types = [
    {
      id: 'app',
      name: 'App'
    },
    {
      id: 'api',
      name: 'API'
    }
  ];

  /*
        name: {type: String},
    description: {type: String},
    ipPlacement: {type: Number},
    ip: {type: Boolean},
    judges: {type: Number},
    rounds: {type: Number}
     */

  render() {
    return (
      <div className={'elevated rounded ex-pad'}>
        <Formik
          validationSchema={schema}
          onSubmit={this.handleSubmit}
          enableReinitialize
          initialValues={{
            name: this.props.single.single.name || '',
            ip: this.props.single.single.ip || false,
            ipPlacement: this.props.single.single.ipPlacement || 3,
            judges: this.props.single.single.judges || 1,
            judgeFromComp: this.props.single.single.judgeFromComp || false,
            rounds: this.props.single.single.rounds || 1,
            roundsFromComp: this.props.single.single.roundsFromComp || false,
            description: this.props.single.single.description || ''
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
            setFieldValue
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <FormButtons
                back={(e) => {
                  e.preventDefault();
                  this.step(values, 'previous');
                }}
                showPrevious={false}
                showBack={true}
                onBack={(e) => {
                  this.props.history.push(`/app/admin/scoring`);
                }}
                showRevert={false}
                showNext={false}
                confirm={(e) => {
                  e.preventDefault();
                  this.handleSubmit(values);
                }}
                showDefault={true}
              />
              <Row>
                <Col md={6}>
                  <div className={'field-container'}>
                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Enter Name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.name && !errors.name}
                        className={`form-control ${
                          touched.name ? (errors.name ? 'is-invalid' : 'is-valid') : ''
                        }`}
                      />
                    </Form.Group>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      name="description"
                      placeholder="Summary"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.description && !errors.description}
                      className={`form-control ${
                        touched.description ? (errors.description ? 'is-invalid' : 'is-valid') : ''
                      }`}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div className={'spacer'} />
              <div className={'divider'} />
              <div className={'spacer'} />
              <Row>
                <Col md={6}>
                  <div className={'field-container'}>
                    <Form.Check
                      type="checkbox"
                      className="mb-2"
                      label="Use Irish Points"
                      name="ip"
                      value={values.ip}
                      checked={values.ip}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.ip && !errors.ip}
                    />
                  </div>
                </Col>
              </Row>

              {values.ip ? (
                <Row>
                  <Col md={6}>
                    <div className={'field-container'}>
                      <Form.Group>
                        <Form.Label>Irish Points placed after round</Form.Label>
                        <Form.Control
                          type="number"
                          name="ipPlacement"
                          placeholder="Enter Title"
                          value={values.ipPlacement}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.ipPlacement && !errors.ipPlacement}
                          className={`form-control ${
                            touched.ipPlacement
                              ? errors.ipPlacement
                                ? 'is-invalid'
                                : 'is-valid'
                              : ''
                          }`}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              ) : null}

              <Row>
                <Col md={6}>
                  <div className={'field-container'}>
                    <Form.Check
                      type="checkbox"
                      className="mb-2"
                      label="Use judge count from competition (dynamic)"
                      name="judgeFromComp"
                      value={values.judgeFromComp}
                      checked={values.judgeFromComp}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.judgeFromComp && !errors.judgeFromComp}
                    />
                  </div>
                </Col>
              </Row>

              {!values.judgeFromComp ? (
                <Row>
                  <Col md={6}>
                    <div className={'field-container'}>
                      <Form.Group>
                        <Form.Label>Judges</Form.Label>
                        <Form.Control
                          type="number"
                          name="judges"
                          placeholder=""
                          value={values.judges}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.judges && !errors.judges}
                          className={`form-control ${
                            touched.judges ? (errors.judges ? 'is-invalid' : 'is-valid') : ''
                          }`}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              ) : null}

              <Row>
                <Col md={6}>
                  <div className={'field-container'}>
                    <Form.Check
                      type="checkbox"
                      className="mb-2"
                      label="Use round count from competition (dynamic)"
                      name="roundsFromComp"
                      value={values.roundsFromComp}
                      onChange={handleChange}
                      checked={values.roundsFromComp}
                      onBlur={handleBlur}
                      isValid={touched.roundsFromComp && !errors.roundsFromComp}
                    />
                  </div>
                </Col>
              </Row>

              {!values.roundsFromComp ? (
                <Row>
                  <Col md={6}>
                    <div className={'field-container'}>
                      <Form.Group>
                        <Form.Label>Rounds</Form.Label>
                        <Form.Control
                          type="number"
                          name="rounds"
                          placeholder=""
                          value={values.rounds}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.rounds && !errors.rounds}
                          className={`form-control ${
                            touched.rounds ? (errors.rounds ? 'is-invalid' : 'is-valid') : ''
                          }`}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              ) : null}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  single: state.single,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  post: (view, data, token, redirect) => {
    dispatch(singleActions.post(view, data, token, true, redirect, true));
  },
  postOnboarding: (view, data, token, redirect) => {
    dispatch(singleActions.postNextStep(view, data, token, true, redirect));
  },
  patch: (view, id, data, token) => {
    dispatch(singleActions.patch(view, id, data, token, false, null, true));
  },
  get: (id, token) => {
    dispatch(singleActions.getSingle('scoring', id, token));
  },
  setBlankSingle: () => {
    dispatch(singleActions.setSingleEntry({ step: 0 }));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(EditScoring));

class EditScoringContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default EditScoringContainer;
