/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NewspaperOutline, Add } from 'react-ionicons';
import Button from 'react-bootstrap/Button';

import * as tableActions from '../../../store/actions/table';
import EventTile from '../../Common/EventTile';
import placeholder2 from '../../../assets/images/placeholder1.png';
import * as singleActions from '../../../store/actions/single';
import EventModal from '../../Common/EventModal';

class Events extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      showModal: false,
      ev: {}
    };
  }

  componentDidMount(props) {
    this.props.getTableData('events', 0, 999, { number: 1 }, this.props.auth.token._id, 'add');
  }

  componentDidUpdate(props, state) {
    if (
      (this.props.table.data.length !== this.state.items.length ||
        this.props.table.updateCount > props.table.updateCount ||
        this.props.table.data.length !== this.state.items.length) &&
      this.props.table.view === 'events'
    ) {
      this.setState({ items: this.props.table.data });
    }
  }

  showModal = (event) => {
    this.setState({ showModal: true, ev: event });
  };

  hideModal = () => {
    this.setState({ showModal: false, ev: {} });
  };

  render() {
    return (
      <div className={'page-container'}>
        <EventModal event={this.state.ev} show={this.state.showModal} hideModal={this.hideModal} />
        <div className={'page-header'}>
          <Row>
            <Col>
              <h1>
                <NewspaperOutline /> Events
              </h1>
            </Col>
            <Col>
              {this.props.user.user ? (
                this.props.user.user.roles ? (
                  this.props.user.user.roles.indexOf('organizer') > -1 ||
                  this.props.user.user.role === 'admin' ? (
                    <Col>
                      <div className={'text-right'}>
                        <Button
                          variant={'primary'}
                          onClick={(e) => {
                            this.props.setBlankSingle();
                            this.props.history.push('/app/event-dashboard/new/edit/1');
                          }}>
                          <Add fontSize={'18px'} color={'#fff'} /> &nbsp; Create Event
                        </Button>
                      </div>
                    </Col>
                  ) : null
                ) : null
              ) : null}
            </Col>
          </Row>
        </div>

        <div className={'section'}>
          <div className={'tile-grid'}>
            <Row>
              {this.props.table.data.length > 0 ? (
                this.props.table.data.map((d, i) => {
                  return (
                    <EventTile
                      showModal={(event) => this.showModal(event)}
                      {...d}
                      data={d}
                      key={i}
                      placeholder={placeholder2}
                    />
                  );
                })
              ) : (
                <h4 className={'no-data'}>No Events Found</h4>
              )}
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  table: state.table,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  },
  setBlankSingle: () => {
    dispatch(singleActions.setSingleEntry({ step: 0 }));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Events));

class EventsContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default EventsContainer;
