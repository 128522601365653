import React from 'react';

import PDF from "./PDFComponent";
import { parseInt } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as tableActions from '../../../store/actions/table';


const PDFPage = (props) => {

  const judgeNumber =  0; // Recall is not specific to a judge

  const events = props.events;
  if (!events) return null;
  const event = events.event;
  const rounds = events.competition.competition.grade.rounds;
  const label = rounds === 1 ? 'AWARD CALLBACK' : 'RECALLS';
  const roundNumber = rounds === 1 ? 1 : 2;   // No clue why this is defaulting to 2
  const roundName = "round" + (roundNumber)
  const round = events.competition.template.rounds[roundNumber];
  var scores = round.scores[judgeNumber];
  const judge = round.judges[judgeNumber];
  const set = events.competition.competition.grade.scoring.judge_rotation_type === 'SET';
  const compType = events.competition.competition.grade.type;
  const teamComp = compType === "teams" || compType === "adultteams";


  const competitionName = events.competition.competition.code + " | " +
    events.competition.competition.grade?.title + " | " +
    events.competition.competition.rowTitle + " | " +
    events.competition.competition.columnTitle

  const titles = [
    event?.name,
    competitionName
  ]

  let subtitles = [];

  if (rounds === 1) {
    subtitles.push('The following competitors placed in this competition and are requested to be present');
    subtitles.push('for this evening\'s awards ceremony');
  } else {
    subtitles.push(`Total Competitors: ${scores.length}`)
  }

  const headers = teamComp ?
  [
    "Team",
    "Name",
    "School"
  ]
  :
  [
    "Dancer",
    "Name",
    "School"
  ];

  const filterField = rounds === 1 ? 'placed' : 'recall';


  var content = scores.filter(item => item[filterField]).map((score, i) => {
    let rel = events.competition.entries.filter(item => item._id === score.entryCompetition);
    //console.log("Rel", rel, events.competition.entries[i]);
    let s = {};

    if (rel.length > 0) {
      s = rel[0];
    }


  return [
    s.dancerNumber ? s.dancerNumber : 0,
    s.dancer ? s.dancer.name + " " + s.dancer.lname : "",
    s.dancer ? s.dancer.school?.name : ""
  ]
});
  content = content.sort((a, b) => {

    return a[0] - b[0]
  })
/*
  if (sorting === "rank") {
    content = content.sort((a, b) => {
      return a[2] - b[2]
    })
  } else if (sorting === "dancer") {
    content = content.sort((a, b) => {

      return a[0] - b[0]
    })
  }
*/
  const table = {
    headers,
    content
  }

  const pdfData = {
    table,
    titles,
    image: props.events.competition.competition.grade.event.image.path,
    label: label,
    subtitles: subtitles
  }

  return (
    <div style={{ height: "100vh" }}>

      <PDF pdfData={pdfData} />
    </div>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  table: state.table,
  user: state.user,
  events: state.events
});


const mapDispatchToProps = dispatch => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  },
});


const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(PDFPage));

class PDFPageContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    )
  }
}

export default PDFPageContainer;
