/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import { withRouter, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import * as SingleActions from '../../store/actions/single';
import Home from './Home/Home';
import Users from './Users/Users';
import SingleSchool from './Schools/Single';
import Logout from './Auth/Logout';
import Events from './Events/Events';
import AdminWrapper from './Admin/AdminWrapper';
import Schools from './Schools/Schools';
import Settings from './Settings/Settings';
import SingleDancer from './Dancers/SingleDancer';
import Dancers from './Dancers/Dancers';
import Event from './Events/Event';
import SingleEvent from './Events/SingleEvent';
import SingleEntry from './Entries/SingleEntry/SingleEntry';
import Bound from '../Common/Bound';
import Entries from './Entries/Entries';
import Results from './Results/Results';
import * as socketActions from '../../store/actions/socket';
import Entry from './Entries/Entry';
import LogoutModal from '../Common/LogoutModal';
import '../../../node_modules/animate.css/animate.compat.css';
import EventDash from './Dash/EventDash';
import JudgeCompetitions from './Judge/JudgeCompetitions';
import JudgeSingleCompetition from './Judge/JudgeSingleCompetition';
import JudgePage from './Judge/JudgePage';
import ResultView from './Results/ResultView';
import FullResultView from './Results/FullResultView';
import ResultTypes from './Results/ResultTypes';
import Updates from './Updates/Updates';
import SingleUpdate from './Updates/SingleUpdate';
import EditUpdate from './Updates/EditUpdate';
import Notifications from '../Notifications/Notifications';
import ReactTooltip from 'react-tooltip';
import Teams from './Teams/Teams';
import SingleTeam from './Teams/SingleTeam';
import TabulatorPage from './Tabulators/TabulatorPage';
import TabulatorCompetitions from './Tabulators/TabulatorCompetitions';
import TabulatorSingleCompetition from './Tabulators/TabulatorSingleCompetition';
import PDF from './PDF/PDFSignoffPage';
import ResultsPDF from './PDF/PDFResultsPage';
import RecallPDF from './PDF/PDFRecallPage';
import Round1Final from './PDF/PDFRound1Final';
import Round1Simple from './PDF/PDFRound1Simple';
import AllResultsPDF from './PDF/PDFAllResults';
import PDFRound1Placing from './PDF/PDFRound1Placing';
import PDFRound3Placing from './PDF/PDFRound3Placing';
import DashEntries from './Dash/Entries';

class PageContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTheme: false,
      showFormModal: false,
      sidebarOpen: false,
      showLogoutModal: false
    };
  }

  handleThemeModal = (show) => {
    this.setState({ showTheme: show });
  };

  handleFormModal = (show) => {
    this.setState({ showFormModal: show });
  };

  handleFormModalWithData = (d) => {
    this.setState({ showFormModal: true });
    this.props.setSingle(d);
  };

  componentDidMount() {
    this.props.connect(this.props.auth.token);
  }

  handleNewItem = (e) => {
    e.preventDefault();
    let splitted = this.props.location.pathname.split('/');
    let view = splitted[2];
    if (['artists', 'albums', 'tracks', 'users', 'news', 'pages', 'help'].indexOf(view) > -1) {
      this.props.setSingle({ _id: 21 });
      this.props.history.push('/app/' + view + '/new');
    } else {
      this.handleFormModalWithData({});
    }
  };

  toggleSidebar = () => {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  };

  setLogoutModal = (mode) => {
    this.setState({ showLogoutModal: mode });
  };

  render() {
    return (
      <div className={`feis-page-container`}>
        <LogoutModal show={this.state.showLogoutModal} hideModal={this.setLogoutModal} />
        <Bound>
          <Header
            handleThemeModal={this.handleThemeModal}
            toggleSidebar={this.toggleSidebar}
            setLogoutModal={this.setLogoutModal}
          />
        </Bound>

        <Bound>
          <Sidebar open={this.state.sidebarOpen} setLogoutModal={this.setLogoutModal} />
        </Bound>

        <div
          className={`feis-page ${this.props.auth.sidebarOpen ? 'sidebar-open' : ''} ${
            this.props.location.pathname.indexOf('/app/j/') > -1 ? 'judge-view' : ''
          } ${this.props.location.pathname.indexOf('/app/tabulation/') > -1 ? 'judge-view' : ''}`}>
          <Route
            path={`/app/`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <Home />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/users`}
            render={(props) => {
              return (
                <Bound>
                  <Users handleFormModalWithData={(d) => this.handleFormModalWithData(d)} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/judge/:id/:competition`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <JudgeSingleCompetition {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/tabulator`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <TabulatorPage {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/judge`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <JudgePage {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/tabulator/:id`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <TabulatorCompetitions {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/tabulation/:id/:competition/:round`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <TabulatorSingleCompetition {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/judge/:id`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <JudgeCompetitions {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/j/:round/:id`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <Entry {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/events/view/:id/details`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <SingleEvent {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/events/view/:id/competitions`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <SingleEvent {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/events/:id/edit/:tab`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <Event {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/events/:id/:view`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <SingleEntry {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/entries/:id`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <SingleEntry {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/event-dashboard`}
            render={(props) => {
              return (
                <Bound>
                  <EventDash {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/events/:id`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <Event {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/results/dancers/:id`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <FullResultView {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/results/event/:id/:type`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <ResultView {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/results/event/:id`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <ResultTypes {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/results/:view`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <Results {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/updates/:id`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <SingleUpdate {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/updates`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <Updates {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/results`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <Results {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/entries`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <Entries {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/events`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <Events {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/pdf/:round/:judge/:sortby`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <PDF />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/pdfoneroundresults`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <Round1Final />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/pdfoneroundplacing`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <PDFRound1Placing />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/pdfoneroundsimple/:round/:judge`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <Round1Simple />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/pdfresults/:final`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <ResultsPDF />
                </Bound>
              );
            }}
          />
          <Route
            path={`/app/pdfround3placing/:placed`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <PDFRound3Placing />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/pdfallresults`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <AllResultsPDF />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/pdfrecall/`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <RecallPDF />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/dancers/:id/:subview`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <SingleDancer {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/dancers/:id`}
            exact={true}
            render={(props) => {
              return <Redirect to={`/app/dancers/${props.match.params.id}/info`} />;
            }}
          />

          <Route
            path={`/app/dancers`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <Dancers {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/teams/:id`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <SingleTeam {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/teams`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <Teams {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/schools/:id/:subview`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <SingleSchool {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/schools/:id`}
            exact={true}
            render={(props) => {
              return <Redirect to={`/app/schools/${props.match.params.id}/info`} />;
            }}
          />

          <Route
            path={`/app/schools`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <Schools {...props} />
                </Bound>
              );
            }}
          />

          <Route
            exact={true}
            path={'/app/notifications'}
            component={(props) => {
              return (
                <Bound>
                  <Notifications />
                </Bound>
              );
            }}
          />

          <Route
            exact={true}
            path={'/app/logout'}
            component={(props) => {
              return (
                <Bound>
                  <Logout />
                </Bound>
              );
            }}
          />

          <Route
            exact={true}
            path={'/app/settings/:view'}
            render={(props) => {
              return (
                <Bound>
                  <Settings />
                </Bound>
              );
            }}
          />

          {this.props.user ? (
            this.props.user.user ? (
              this.props.user.user.role === 'admin' ? (
                <Bound>
                  <Route path={'/app/admin'} component={AdminWrapper} />
                  <Route
                    path={`/app/updates/edit/:id`}
                    exact={true}
                    render={(props) => {
                      return (
                        <Bound>
                          <EditUpdate {...props} />
                        </Bound>
                      );
                    }}
                  />
                </Bound>
              ) : null
            ) : null
          ) : null}
        </div>
        <ReactTooltip effect={'solid'} className={'f21-tooltip'} place={'bottom'} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  single: state.single,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  setSingle: (d) => {
    dispatch(SingleActions.setSingleEntry(d));
  },
  connect: (token) => {
    dispatch(socketActions.connect(token));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(PageContainer));

class PageContainerContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected />
      </div>
    );
  }
}

export default PageContainerContainer;
