// import Image from 'next/image'
import React from 'react';

import { PDFViewer, Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';

import Logo from "../../../assets/images/logos.png";
import FeisLogo from "../../../assets/images/arrow-feisfwd-200px.png";

import PDFTableHeader from "./PDFTableHeader";
import urls from "../../../utils/urls";

const PDF = ({ pdfData, judges }) => {
    const tables = pdfData.tables;
    const titles = pdfData.titles;
    const events = pdfData.events;
    console.log("Events", events);
    let logo = urls.getS3Url(pdfData.image);
    let grade = events.competition.competition.grade;
    let eventName = pdfData.eventName;
    let r = grade.rounds;
    let panelType = events.competition.competition.grade.scoring.judge_rotation_type;
    let judgeCount = events.competition.competition.grade.judges;
    let final = pdfData.final;
    let reportTitle = panelType === 'SPLIT' ? (final ? "R3 AND FINAL RESULT" : "R1 + R2") : "";
    console.log(tables);
    console.log(titles);
    console.log(events);

    return (
        <PDFViewer style={styles.pdfWrap}>
            <Document>
                {tables ?
                    tables.map((table, i) => {
                        return (
                            <Page size="A4" orientation="landscape" key={i}>
                                <View style={styles.innerWrap}>
                                    <View style={styles.headerWrap}>
                                        <View style={styles.subheaderWrap}>
                                            <View style={styles.subheaderLeftWrap}>
                                                <Image style={styles.logoImage} src={logo} />
                                                <Text style={styles.timeStamp}>{eventName}</Text>
                                            </View>
                                            <View style={styles.subheaderRightWrap}>
                                                <Text style={styles.madeBy}>Results by FeisFWD.com</Text>
                                                <Image style={styles.feisFWDLogo} src={FeisLogo} />
                                            </View>
                                        </View>

                                        <Text style={styles.title}>{titles[0]} | {titles[1]} | {reportTitle}</Text>
                                    </View>

                                    <View style={styles.tableWrap}>
                                        <PDFTableHeader judges={judges} titles={titles} events={events} final={final} />

                                        <View style={styles.contentWrap}>
                                            {table.content.map((tr, i) =>
                                            {
                                                if (tr.length === 1) {
                                                    return (
                                                        <View key={i} style={styles.tr}>
                                                            {tr.map((td, j) => {
                                                                var border = undefined;
                                                                var bgColor = undefined;
                                                                var font = undefined;
                                                                var width = undefined;
                                                                if (j === 0) { width = { width: "100%" }; border = { borderLeft: "none" }; font = { fontSize: 13 } }
                                                                if (j === 1) width = { width: "10%" };

                                                                return (
                                                                    <View key={j} style={[styles.td, bgColor, border, width]}>
                                                                        <Text style={[styles.tdText, font]}>{td}</Text>
                                                                    </View>
                                                                )
                                                            })}
                                                        </View>
                                                    )
                                                } else {
                                                    return (
                                                        <View key={i} style={styles.tr}>
                                                            {tr.map((td, j) => {
                                                                var border = undefined;
                                                                var bgColor = undefined;
                                                                var font = undefined;
                                                                var width = undefined;
                                                                if (j === 0) { border = { borderLeft: "none" }; font = { fontSize: 13 } }
                                                                if (j === 1) width = { width: "10%" };
                                                                if (j === 2) width = { width: "10%" };
                                                                if (final) {
                                                                    if (j === 3) width = { width: "4%" }; 
                                                                    if (j === 4) { width = { width: "6%" }; bgColor = { backgroundColor: "#FFE08C" };}
                                                                    if (judgeCount >= 2 && j === 5) width = { width: "4%" }; 
                                                                    if (judgeCount >= 2 && j === 6) { width = { width: "6%" }; bgColor = { backgroundColor: "#DBF7CD" };}
                                                                    if (judgeCount >= 3 && j === 7) width = { width: "4%" };
                                                                    if (judgeCount >= 3 && j === 8) { width = { width: "6%" }; bgColor = { backgroundColor: "#D3F5F1" };}
                                                                    if (judgeCount >= 4 && j === 9) width = { width: "4%" };
                                                                    if (judgeCount >= 4 && j === 10) { width = { width: "6%" }; bgColor = { backgroundColor: "#C7EBFC" };}
                                                                    if (judgeCount >= 5 && j === 11) width = { width: "4%" };
                                                                    if (judgeCount >= 5 && j === 12) { width = { width: "6%" }; bgColor = { backgroundColor: "#ECD4FA" };}
                                                                    if (j === ((judgeCount * 2) + 3)) { width = { width: "5%" }; bgColor = { backgroundColor: "#F7E9F1" };}
                                                                    if (j === ((judgeCount * 2) + 4)) { width = { width: "5%" }; bgColor = { backgroundColor: "#FFCCE0" };}
                                                                    if (j === ((judgeCount * 2) + 5)) { width = { width: "6%" }; bgColor = { backgroundColor: "#F7A8C7" };}
                                                                    if (j === ((judgeCount * 2) + 6)) { width = { width: "4%" }; bgColor = { backgroundColor: "#FF6EA5" };}
                                                                    if (j === ((judgeCount * 2) + 7)) { width = { width: "4%" }; bgColor = { backgroundColor: "#FC1C97" };}
                                                                } else {
                                                                    if (panelType === 'SPLIT') {
                                                                        if (j === 3) width = { width: "6%" };
                                                                        if (j === 4) { width = { width: "4%" }; bgColor = { backgroundColor: "#FFE08C" };}
                                                                        if (j === 5) { width = { width: "6%" }; bgColor = { backgroundColor: "#FFD45E" };}
                                                                        if (judgeCount >= 2 && j === 6) width = { width: "6%" };
                                                                        if (judgeCount >= 2 && j === 7) { width = { width: "4%" }; bgColor = { backgroundColor: "#DBF7CD" };}
                                                                        if (judgeCount >= 2 && j === 8) { width = { width: "6%" }; bgColor = { backgroundColor: "#C5F7AD" };}
                                                                        if (judgeCount >= 3 && j === 9) width = { width: "6%" };
                                                                        if (judgeCount >= 3 && j === 10) { width = { width: "4%" }; bgColor = { backgroundColor: "#D3F5F1" };}
                                                                        if (judgeCount >= 3 && j === 11) { width = { width: "6%" }; bgColor = { backgroundColor: "#B9FAF3" };}
                                                                        if (judgeCount >= 4 && j === 12) width = { width: "6%" };
                                                                        if (judgeCount >= 4 && j === 13) { width = { width: "4%" }; bgColor = { backgroundColor: "#C7EBFC" };}
                                                                        if (judgeCount >= 4 && j === 14) { width = { width: "6%" }; bgColor = { backgroundColor: "#ABDEF7" };}
                                                                        if (judgeCount >= 5 && j === 15) width = { width: "6%" };
                                                                        if (judgeCount >= 5 && j === 16) { width = { width: "4%" }; bgColor = { backgroundColor: "#ECD4FA" };}
                                                                        if (judgeCount >= 5 && j === 17) { width = { width: "6%" }; bgColor = { backgroundColor: "#DEB2F7" };}
                                                                        if (j === ((judgeCount * 3) + 3)) { width = { width: "5%" }; bgColor = { backgroundColor: "#FFCCE0" };}
                                                                        if (j === ((judgeCount * 3) + 4)) { width = { width: "3.3%" }; bgColor = { backgroundColor: "#F7A8C7" };}
                                                                        if (j === ((judgeCount * 3) + 5)) { width = { width: "3.3%" }; bgColor = { backgroundColor: "#FC1C97" };}
                                                                    } else if (panelType === 'ROTATING') {
                                                                        if (j === 3) width = { width: "6%" };
                                                                        if (j === 4) width = { width: "6%" };
                                                                        if (j === 5) width = { width: "6%" };
                                                                        if (j === 6) { width = { width: "4%" }; bgColor = { backgroundColor: "#FFE08C" };}
                                                                        if (j === 7) { width = { width: "4%" }; bgColor = { backgroundColor: "#FFD45E" };}
                                                                        if (j === 8) width = { width: "6%" };
                                                                        if (j === 9) width = { width: "6%" };
                                                                        if (j === 10) width = { width: "6%" };
                                                                        if (j === 11) { width = { width: "4%" }; bgColor = { backgroundColor: "#DBF7CD" };}
                                                                        if (j === 12) { width = { width: "4%" }; bgColor = { backgroundColor: "#C5F7AD" };}
                                                                        if (j === 13) { width = { width: "4%" }; bgColor = { backgroundColor: "#B6FF94" };}
                                                                        if (j === 14) { width = { width: "4%" }; bgColor = { backgroundColor: "#C7EBFC" };}
                                                                        if (j === 15) width = { width: "6%" };
                                                                        if (j === 16) width = { width: "6%" };
                                                                        if (j === 17) width = { width: "6%" };
                                                                        if (j === 18) { width = { width: "4%" }; bgColor = { backgroundColor: "#FFCCE0" };}
                                                                        if (j === 19) { width = { width: "4.3%" }; bgColor = { backgroundColor: "#F7A8C7" };}
                                                                        if (j === 20) { width = { width: "4%" }; bgColor = { backgroundColor: "#FF6EA5" };}
                                                                        if (j === 21) width = { width: "4%" };
                                                                        if (j === 22) width = { width: "4%" };
                                                                    } else {
                                                                        if (r === 1) {
                                                                            if (judgeCount === 1) {
                                                                                if (j === 3) width = { width: "4%" };
                                                                                if (j === 4) { width = { width: "4%" }; bgColor = { backgroundColor: "#FFCCE0" };}
                                                                            } else {
                                                                                if (j === 3) width = { width: "6%" };
                                                                                if (j === 4) { width = { width: "6%" }; bgColor = { backgroundColor: "#FFD45E" };}
                                                                                if (judgeCount >= 2 && j === 5) width = { width: "6%" };
                                                                                if (judgeCount >= 2 && j === 6) { width = { width: "6%" }; bgColor = { backgroundColor: "#C5F7AD" };}
                                                                                if (judgeCount >= 3 && j === 7) width = { width: "6%" };
                                                                                if (judgeCount >= 3 && j === 8) { width = { width: "6%" }; bgColor = { backgroundColor: "#B9FAF3" };}
                                                                                if (judgeCount >= 4 && j === 9) width = { width: "6%" };
                                                                                if (judgeCount >= 4 && j === 10) { width = { width: "6%" }; bgColor = { backgroundColor: "#ABDEF7" };}
                                                                                if (judgeCount >= 5 && j === 11) width = { width: "6%" };
                                                                                if (judgeCount >= 5 && j === 12) { width = { width: "6%" }; bgColor = { backgroundColor: "#DEB2F7" };}
                                                                                if (j === ((judgeCount * 2) + 3)) { width = { width: "5%" }; bgColor = { backgroundColor: "#FFCCE0" };}
                                                                                if (j === ((judgeCount * 2) + 4)) { width = { width: "4%" }; bgColor = { backgroundColor: "#F7A8C7" };}
                                                                                if (j === ((judgeCount * 2) + 5)) { width = { width: "4%" }; bgColor = { backgroundColor: "#FC1C97" };}
                                                                            }
                                                                        } else if (r === 2) {
                                                                            if (j === 3) width = { width: "4%" };
                                                                            if (j === 4) width = { width: "4%" };
                                                                            if (j === 5) { width = { width: "4%" }; bgColor = { backgroundColor: "#FFE08C" };}
                                                                            if (judgeCount >= 2 && j === 6) width = { width: "4%" };
                                                                            if (judgeCount >= 2 && j === 7) width = { width: "4%" };
                                                                            if (judgeCount >= 2 && j === 8) { width = { width: "4%" }; bgColor = { backgroundColor: "#DBF7CD" };}
                                                                            if (judgeCount >= 3 && j === 9) width = { width: "4%" };
                                                                            if (judgeCount >= 3 && j === 10) width = { width: "4%" };
                                                                            if (judgeCount >= 3 && j === 11) { width = { width: "4%" }; bgColor = { backgroundColor: "#D3F5F1" };}
                                                                            if (judgeCount >= 4 && j === 12) width = { width: "4%" };
                                                                            if (judgeCount >= 4 && j === 13) width = { width: "4%" };
                                                                            if (judgeCount >= 4 && j === 14) { width = { width: "4%" }; bgColor = { backgroundColor: "#C7EBFC" };}
                                                                            if (judgeCount >= 5 && j === 15) width = { width: "4%" };
                                                                            if (judgeCount >= 5 && j === 16) width = { width: "4%" };
                                                                            if (judgeCount >= 5 && j === 17) { width = { width: "4%" }; bgColor = { backgroundColor: "#ECD4FA" };}
                                                                            if (j === ((judgeCount * 3) + 3)) { width = { width: "4%" }; bgColor = { backgroundColor: "#FFCCE0" };}
                                                                            if (j === ((judgeCount * 3) + 4)) { width = { width: "4%" }; bgColor = { backgroundColor: "#FC1C97" };}
                                                                        } else if (r >= 3) {
                                                                            if (j === 3) width = { width: "4%" };
                                                                            if (j === 4) width = { width: "4%" };
                                                                            if (j === 5) width = { width: "4%" };
                                                                            if (judgeCount >= 2 && j === 6) { width = { width: "4%" }; bgColor = { backgroundColor: "#FFE08C" };}
                                                                            if (j === 7) { width = { width: "4%" }; bgColor = { backgroundColor: "#FFD45E" };}
                                                                            if (judgeCount >= 2 && j === 8) width = { width: "4%" };
                                                                            if (judgeCount >= 2 && j === 9) width = { width: "4%" };
                                                                            if (judgeCount >= 2 && j === 10) width = { width: "4%" };
                                                                            if (judgeCount >= 2 && j === 11) { width = { width: "4%" }; bgColor = { backgroundColor: "#DBF7CD" };}
                                                                            if (judgeCount >= 2 && j === 12) { width = { width: "4%" }; bgColor = { backgroundColor: "#C5F7AD" };}
                                                                            if (judgeCount >= 3 && j === 13) width = { width: "4%" };
                                                                            if (judgeCount >= 3 && j === 14) width = { width: "4%" };
                                                                            if (judgeCount >= 3 && j === 15) width = { width: "4%" };
                                                                            if (judgeCount >= 3 && j === 16) { width = { width: "4%" }; bgColor = { backgroundColor: "#D3F5F1" };}
                                                                            if (judgeCount >= 3 && j === 17) { width = { width: "4%" }; bgColor = { backgroundColor: "#B9FAF3" };}
                                                                            if (judgeCount >= 4 && j === 18) width = { width: "4%" };
                                                                            if (judgeCount >= 4 && j === 19) width = { width: "4%" };
                                                                            if (judgeCount >= 4 && j === 20) width = { width: "4%" };
                                                                            if (judgeCount >= 4 && j === 21) { width = { width: "4%" }; bgColor = { backgroundColor: "#C7EBFC" };}
                                                                            if (judgeCount >= 4 && j === 22) { width = { width: "4%" }; bgColor = { backgroundColor: "#ABDEF7" };}
                                                                            if (judgeCount >= 5 && j === 23) width = { width: "4%" };
                                                                            if (judgeCount >= 5 && j === 24) width = { width: "4%" };
                                                                            if (judgeCount >= 5 && j === 25) width = { width: "4%" };
                                                                            if (judgeCount >= 5 && j === 26) { width = { width: "4%" }; bgColor = { backgroundColor: "#ECD4FA" };}
                                                                            if (judgeCount >= 5 && j === 27) { width = { width: "4%" }; bgColor = { backgroundColor: "#DEB2F7" };}
                                                                            if (j === ((judgeCount * 5) + 3)) { width = { width: "5%" }; bgColor = { backgroundColor: "#FFCCE0" };}
                                                                            if (j === ((judgeCount * 5) + 4)) { width = { width: "4%" }; bgColor = { backgroundColor: "#F7A8C7" };}
                                                                            if (j === ((judgeCount * 5) + 5)) { width = { width: "4%" }; bgColor = { backgroundColor: "#FC1C97" };}
                                                                        }
                                                                    }
                                                                }
                                                                if (i === 0) border = { borderTop: "none" };
                                                                if (i === 0 && j === 0) border = { borderLeft: "none", borderTop: "none" };
                                                                if (i === 0 && j === 22) border = { borderRight: "none", borderTop: "none" };

                                                                console.log("TD", td);
                                                                return (
                                                                    <View key={j} style={[styles.td, bgColor, border, width]}>
                                                                        <Text style={[styles.tdText, font]}>{td}</Text>
                                                                    </View>
                                                                )
                                                            })}
                                                        </View>
                                                    )
                                                }

                                            }

                                            )}
                                        </View>
                                    </View>
                                </View>
                            </Page>
                        )
                    })
                : null}

            </Document>
        </PDFViewer>
    )
}

Font.register({
    family: "Roboto",
    src:
        "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf"
});


// Create styles
const styles = StyleSheet.create({
    pdfWrap: {
        //fontFamily: "Roboto",
        backgroundColor: "green",
        fontSize: 9,
        height: "100%",
        width: "100%",
    },
    innerWrap: {
        border: "2px solid black",
        display: "flex",
        flexDirection: "column",
        margin: "3% 3% 0 3%",
        width: "94%",
    },

    // HEADER WRAP
    headerWrap: {
        display: "flex",
        flexDirection: "column",
    },

    // SUBHEADER
    subheaderWrap: {
        alignItems: "flex-end",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingTop: "3px",
    },
    subheaderLeftWrap: {
        alignItems: "flex-end",
        display: "flex",
        flexDirection: "row",
        marginLeft: "3px",
    },
    subheaderRightWrap: {
        alignItems: "flex-end",
        display: "flex",
        flexDirection: "row",
        marginRight: "8px",
    },
    timeStamp: {
        ////fontFamily: "Roboto",
        //fontSize: 11,
        //marginLeft: "10px",
    },
    madeBy: {
        ////fontFamily: "Roboto",
        //fontSize: 6,
    },

    // LOGOS
    logoImage: {
        height: "60px",
        marginRight: "5px",
    },
    feisFWDLogo: {
        height: "25px",
        marginLeft: "5px",
        width: "25px",
    },

    // TITLE
    title: {
        ////fontFamily: "Roboto",
        borderTop: "2px solid black",
        borderBottom: "2px solid black",
        backgroundColor: "#e7e6e6",
        fontSize: 13,
        fontWeight: 700,
        marginBottom: "15px",
        marginTop: "3px",
        padding: "3px 3px 0 3px",
    },




    //TABLE
    tableWrap: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    contentWrap: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    tr: {
        display: "flex",
        flexDirection: "row",
        height: "auto",
        width: "100%",
    },
    td: {
        alignItems: "center",
        borderRight: "1px solid black",
        borderTop: "1px solid black",
        display: "flex",
        justifyContent: "center",
        height: "25px",
        width: "5%",
    },
    tdText: {
        //fontFamily: "Roboto",
        fontSize: 8,
        fontWeight: 900,
    },
});

export default PDF;
