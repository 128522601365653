import React from 'react';

import PDF from "./PDFComponent";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as tableActions from '../../../store/actions/table';
import PublicPDF from "./PublicPDFComponent";


const PDFPage = (props) => {

    const judgeNumber = 0;      // This is not judge specific

    const events = props.events;
    if (!events) return null;
    const event = events.event;
    const rounds = events.competition.competition.grade.rounds;
    const round = events.competition.template.rounds[rounds];   // need to grab the final round for a podium results report
    var scores = round.scores[judgeNumber];
    // Assume this is the podium report unless the value is explicitly "false"
    const placed = props.match.params.placed === "false" ? false : true;


    let grade = events.competition.competition.grade;
    let comp = events.competition.competition;

    let ageTitle = '';

    for(var col of grade.columns){
        if (col.id.toString() === comp.column) {
            ageTitle = col.title;
        }
    }

    const competitionName = events.competition.competition.grade.title + " " +
        events.competition.competition.rowTitle + " | " +
        ageTitle;
    const code = events.competition.competition.code;

    const reportTitle = placed ? "Podium Results" : "Honorary Mention"
    const titles = [
        reportTitle,
        event.name,
        code,
        competitionName
    ]

    const headers = placed ? [
        "Rank",
        "Dancer #",
        "Name",
        "School"
    ] : [
        "Dancer #",
        "Name",
        "School",
    ];

    // Turning this off, needs to be a toggle later
    // if (placed && rounds === 3) {       // We will have better logic for this after migrating, but for now just showing WQ for 3 round events
    //     headers.push("World Qualifier");
    // }

    scores = scores.map(s => {
        let rel = events.competition.entries.filter(item => item._id === s.entryCompetition);
        //console.log("Rel", rel, events.competition.entries[i]);
        let d = {};
    
        if (rel.length > 0) {
          d = rel[0];
        }
        return {
          ...s,
          dancerNumber: d.dancerNumber
        }
      });


    // Sort by rank
    scores = scores.sort((a, b) => {
        return b["finalIp"] - a["finalIp"];
    });

    // var content = [];

    // let chunks = [];
    // var i,j, temporary, chunk2 = 20;
    // for (i = 0,j = scores.length; i < j; i += chunk2) {
    //     temporary = scores.slice(i, i + chunk2);
    //     chunks.push(temporary);
    //     // do whatever
    // }

    //console.log("tem", chunks, scores);

    // for(var chunk of chunks) {
    var content = scores.filter(scoreItem => scoreItem.placed === placed && (placed || scoreItem.recall === true)).map((score, i) => {
        let rel = events.competition.entries.filter(item => item._id === score.entryCompetition);
        console.log("Rel", rel, events.competition.entries[i]);
        let s = {};

        if (rel.length > 0) {
            s = rel[0];
        }

        console.log("score: ");
        console.log(score);
        let rankString = score["finalRank"] && score["finalRank"] > -1 ?
            score["finalRank"].toString() + (score["tied"] ? "T" : "") 
            : "";

        let returnArray = placed ? [
            rankString,
            s.dancerNumber ? s.dancerNumber : 0,
            s.dancer ? s.dancer.name + " " + s.dancer.lname : "",
            s.dancer ? s.dancer.school?.name : ""
        ] : [
            s.dancerNumber ? s.dancerNumber : 0,
            s.dancer ? s.dancer.name + " " + s.dancer.lname : "",
            s.dancer ? s.dancer.school?.name : ""
        ]

        // Turning this off, needs to be a toggle later
        // if (placed && rounds === 3) {
        //     returnArray.push(score["wq"] ? "Yes" : "No");
        // }

        return returnArray;
    });

        // tables.push({
        //     headers,
        //     content: content2
        // });
    //}

    // Sort by dancerNumer and not score if this is the 'honorable mention' report
    if (!placed) {
        content = content.sort((a, b) => {

            return a[0] - b[0]
          })
    }

    const table = {
        headers,
        content
    }

    let tables = [table];

    const pdfData = {
        table,
        tables,
        titles,
        judges: props.events.competition.template.rounds["1"].judges.filter(item => item.name).map(item => item.name + " " + item.lname),
        image: props.events.competition.competition.grade.event.image.path,
    }

    return (
        <div style={{ height: "100vh" }}>

            <   PDF pdfData={pdfData} />
        </div>
    );
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user,
    events: state.events
});


const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});


const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(PDFPage));

class PDFPageContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default PDFPageContainer;
