/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 2/1/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';

class TabulatorCompetitions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {
        this.props.getTableData({id: this.props.match.params.id}, this.props.auth.token._id)
    }

    componentDidUpdate(props, state) {

    }

    render() {
        return (
            <div className={'page-container'}>
                <div className={'page-header'}>
                    <Row>
                        <Col>
                            <h1>Competitions</h1>
                        </Col>
                    </Row>
                </div>

                {this.props.table.view === "events/getTabulatorCompetitions" ?
                    this.props.table.data ?
                        this.props.table.data.length > 0 ?
                            this.props.table.data.map((d, i) => {
                                let completed = false;
                                if(this.props.table.judgeEvent){
                                    if(this.props.table.judgeEvent.event === this.props.match.params.id ) {
                                        if(this.props.table.judgeEvent.completed.indexOf(d._id) > -1) {
                                            completed = true;
                                        }
                                    }
                                }

                                return (
                                    <div className={`competition-tile ${completed ? 'disabled' : ''}`} onClick={e => {
                                        this.props.history.push(`/app/tabulation/${this.props.match.params.id}/${d._id}/1`)
                                    }}>

                                        <h1>{d.code}</h1>
                                        <h4>{d.rowTitle} {d.columnTitle}</h4>

                                        {
                                            completed ?
                                                <h5 className={``}>Completed</h5>
                                                :
                                                <h5 className={`${d.count > 0 ? '' : 'text-danger'}`}>{d.count} entries</h5>

                                        }


                                    </div>
                                )
                            })
                            : null
                        : null
                    : null}


            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table
});

const mapDispatchToProps = dispatch => ({
    getTableData: (query, token, mode) => {
        dispatch(tableActions.getTableDataWithQueryWithData("events/getTabulatorCompetitions", 0, 999, {}, query, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(TabulatorCompetitions));

class TabulatorCompetitionsContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default TabulatorCompetitionsContainer;
