/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 6/6/2020
 */
import Api from '../../utils/Api';
import { NotificationManager } from 'react-notifications';

export function getDancersEntries(id, skip, limit, sort, token, mode = 'add') {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOAD_STATE',
      payload: true
    });
    const api = new Api(token);
    api.get(`entries/getDancersEntries`, { id, skip, limit, token, sort }, (res) => {
      if (res.data.status === 200) {
        if (res.data.status === 200) {
          if (mode === 'add') {
            try {
              dispatch({
                type: 'GOT_STUDENT_ENTRIES',
                payload: {
                  ...res.data
                }
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          } else {
            try {
              dispatch({
                type: 'GOT_STUDENT_ENTRIES',
                payload: {
                  ...res.data
                }
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          }
        }
      }
    });
  };
}

export function disableEmptyCompetitions(id, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.post('events/disableEmptyCompetitions', { id }, (res) => {
      if (res.data) {
        if (res.data.status === 200) {
          dispatch({
            type: 'DISABLED_COMPETITIONS',
            payload: {}
          });
          NotificationManager.success('All empty competitions have been disabled', 'success');
        } else {
          NotificationManager.error(
            'Please reload this view and try again',
            'Could not perform action'
          );
        }
      }
    });
  };
}

export function getTabulatorPage(token) {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOAD_STATE',
      payload: true
    });
    const api = new Api(token);
    api.get(`users/getTabulatorPage`, {}, (res) => {
      if (res.data.status === 200) {
        if (res.data.data) {
          if (res.data.data.judgeEvents) {
            try {
              dispatch({
                type: 'GOT_JUDGE_DATA',
                payload: {
                  ...res.data.data
                }
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          }
        }
      }
    });
  };
}

export function getJudgePage(token) {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOAD_STATE',
      payload: true
    });
    const api = new Api(token);
    api.get(`users/getJudgePage`, {}, (res) => {
      if (res.data.status === 200) {
        if (res.data.data) {
          if (res.data.data.judgeEvents) {
            try {
              dispatch({
                type: 'GOT_JUDGE_DATA',
                payload: {
                  ...res.data.data
                }
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          }
        }
      }
    });
  };
}

export function getHomePage(token) {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOAD_STATE',
      payload: true
    });
    const api = new Api(token);
    api.get(`users/getHomePage`, {}, (res) => {
      if (res.data.status === 200) {
        if (res.data.data) {
          //console.log
          if ((res.data.data.dancers && res.data.data.events) || res.data.data.judgeEvents) {
            try {
              dispatch({
                type: 'GOT_HOME_DATA',
                payload: {
                  ...res.data.data
                }
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          }
        }
      }
    });
  };
}

export function getStudents(skip, limit, sort, token, mode = 'add') {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOAD_STATE',
      payload: true
    });
    const api = new Api(token);
    api.get(`dancers/getStudents`, { skip, limit, token, sort }, (res) => {
      if (res.data.status === 200) {
        if (res.data.status === 200) {
          if (mode === 'add') {
            try {
              dispatch({
                type: 'GOT_STUDENTS',
                payload: {
                  ...res.data
                }
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          } else {
            try {
              dispatch({
                type: 'GOT_STUDENTS',
                payload: {
                  ...res.data
                }
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          }
        }
      }
    });
  };
}

export function getAdminDancers(skip, limit, sort, userId, token, mode = 'add') {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOAD_STATE',
      payload: true
    });
    const api = new Api(token);
    api.get(`dancers/getForAdmin`, { skip, limit, userId, token, sort }, (res) => {
      if (res.data.status === 200) {
        if (res.data.status === 200) {
          if (mode === 'add') {
            try {
              dispatch({
                type: 'GOT_STUDENTS',
                payload: {
                  ...res.data
                }
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          } else {
            try {
              dispatch({
                type: 'GOT_STUDENTS',
                payload: {
                  ...res.data
                }
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          }
        }
      }
    });
  };
}

export function getAdminEntries(skip, limit, sort, userId, token, mode = 'add') {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOAD_STATE',
      payload: true
    });
    const api = new Api(token);
    api.get(`entries/getForAdmin`, { skip, limit, userId, token, sort }, (res) => {
      if (res.data.status === 200) {
        if (res.data.status === 200) {
          if (mode === 'add') {
            try {
              dispatch({
                type: 'GOT_STUDENT_ENTRIES',
                payload: {
                  ...res.data
                }
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          } else {
            try {
              dispatch({
                type: 'GOT_STUDENT_ENTRIES',
                payload: {
                  ...res.data
                }
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          }
        }
      }
    });
  };
}

export function getStudentEntries(skip, limit, sort, token, mode = 'add') {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOAD_STATE',
      payload: true
    });
    const api = new Api(token);
    api.get(`entries/getStudentEntries`, { skip, limit, token, sort }, (res) => {
      if (res.data.status === 200) {
        if (res.data.status === 200) {
          if (mode === 'add') {
            try {
              dispatch({
                type: 'GOT_STUDENT_ENTRIES',
                payload: {
                  ...res.data
                }
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          } else {
            try {
              dispatch({
                type: 'GOT_STUDENT_ENTRIES',
                payload: {
                  ...res.data
                }
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          }
        }
      }
    });
  };
}

export function getTableData(view, skip, limit, sort, token, mode = 'add') {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOAD_STATE',
      payload: true
    });
    const api = new Api(token);
    api.get(`${view}`, { skip, limit, token, sort }, (res) => {
      if (res.data.status === 200) {
        if (res.data.status === 200) {
          if (mode === 'add') {
            if (view === 'files') {
              try {
                dispatch({
                  type: 'GOT_FILE_DATA',
                  payload: {
                    ...res.data,
                    view: view
                  }
                });
              } catch (err) {
                NotificationManager.error(
                  'Please reload this view and try again',
                  'An Error Occured'
                );
              }
            } else {
              try {
                dispatch({
                  type: 'GOT_TABLE_DATA',
                  payload: {
                    ...res.data,
                    view: view
                  }
                });
              } catch (err) {
                NotificationManager.error(
                  'Please reload this view and try again',
                  'An Error Occured'
                );
              }
            }
          } else {
            if (view === 'files') {
              try {
                dispatch({
                  type: 'GOT_MORE_FILE_DATA',
                  payload: {
                    ...res.data,
                    view: view
                  }
                });
              } catch (err) {
                NotificationManager.error(
                  'Please reload this view and try again',
                  'An Error Occured'
                );
              }
            } else {
              try {
                dispatch({
                  type: 'GOT_MORE_TABLE_DATA',
                  payload: {
                    ...res.data,
                    view: view
                  }
                });
              } catch (err) {
                NotificationManager.error(
                  'Please reload this view and try again',
                  'An Error Occured'
                );
              }
            }
          }
        }
      }
    });
  };
}

export function setTableView(view) {
  return (dispatch) => {
    try {
      dispatch({
        type: 'SET_TABLE_VIEW',
        payload: view
      });
    } catch (err) {
      NotificationManager.error('Please reload this view and try again', 'An Error Occured');
    }
  };
}

export function getTableDataWithQueryWithData(view, skip, limit, sort, query, token, mode = 'add') {
  return (dispatch) => {
    const api = new Api(token);
    api.get(`${view}`, { skip, limit, token, sort, query }, (res) => {
      if (res.status === 200) {
        if (mode === 'add') {
          try {
            dispatch({
              type: 'GOT_TABLE_NESTED_DATA',
              payload: {
                data: res.data.data,
                view: view
              }
            });
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        } else {
          try {
            dispatch({
              type: 'GOT_MORE_TABLE_DATA',
              payload: {
                ...res.data,
                view: view
              }
            });
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        }
      }
    });
  };
}

export function getTableDataWithQuery(view, skip, limit, sort, query, token, mode = 'add') {
  return (dispatch) => {
    const api = new Api(token);
    api.get(`${view}`, { skip, limit, token, sort, query }, (res) => {
      if (res.status === 200) {
        if (mode === 'add') {
          if (view === 'files') {
            try {
              dispatch({
                type: 'GOT_FILE_DATA',
                payload: {
                  ...res.data,
                  favorites: res.data.favorites,
                  view: view,
                  subView: query.parent || ''
                }
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          } else {
            try {
              dispatch({
                type: 'GOT_TABLE_DATA',
                payload: {
                  ...res.data,
                  view: view
                }
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          }
        } else {
          if (view === 'files') {
            try {
              dispatch({
                type: 'GOT_MORE_FILE_DATA',
                payload: {
                  ...res.data,
                  view: view,
                  subView: query.parent || ''
                }
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          } else {
            try {
              dispatch({
                type: 'GOT_MORE_TABLE_DATA',
                payload: {
                  ...res.data,
                  view: view
                }
              });
            } catch (err) {
              NotificationManager.error(
                'Please reload this view and try again',
                'An Error Occured'
              );
            }
          }
        }
      }
    });
  };
}

export function sortTable(sort) {
  return (dispatch) => {
    try {
      dispatch({
        type: 'SORTED_TABLE',
        payload: sort
      });
    } catch (err) {
      NotificationManager.error('Please reload this view and try again', 'An Error Occured');
    }
  };
}

export function reorderNoUpdate(view, data, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.post(view + '/reorder', { data }, (res) => {
      if (res.status === 200) {
        NotificationManager.success('Your changed have been saved.');
      } else {
        NotificationManager.error('Could not save changes');
      }
    });
  };
}

export function setSingleEntry(data) {
  return (dispatch) => {
    dispatch({
      type: 'SET_DATA',
      payload: data
    });
  };
}

export function reorder(view, data, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.post(view + '/reorder', { data }, (res) => {
      if (res.status === 200) {
        try {
          dispatch({
            type: 'GOT_TABLE_DATA',
            payload: {
              data: data,
              view: view
            }
          });
          NotificationManager.success('Your changed have been saved.');
        } catch (err) {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      }
    });
  };
}

export function reorderWithPage(view, page, data, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.post(view + '/reorder', { data, page }, (res) => {
      if (res.status === 200) {
        try {
          dispatch({
            type: 'GOT_TABLE_DATA',
            payload: {
              data: data,
              view: view
            }
          });
          NotificationManager.success('Your changed have been saved.');
        } catch (err) {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      }
    });
  };
}

export function deleteForever(ids, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.post('files/deleteForever', { ids }, (res) => {
      if (res.status === 200) {
        // Remove the file from the current table data:
        try {
          dispatch({
            type: 'FILES_DELETED',
            payload: res.data.data.deleted
          });
        } catch (err) {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      }
    });
  };
}

export function restoreFiles(ids, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.post('files/restoreFiles', { ids }, (res) => {
      if (res.status === 200) {
        // Remove the file from the current table data:
        try {
          dispatch({
            type: 'FILES_DELETED',
            payload: res.data.data.deleted
          });
        } catch (err) {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      }
    });
  };
}

export function updateItemField(view, data, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.patch(view, data._id, data, (res) => {
      if (res.status === 200) {
        if (res.data.status === 200) {
          try {
            dispatch({
              type: 'UPDATED_ITEM',
              payload: res.data
            });
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        }
        // Remove the file from the current table data:
      }
    });
  };
}

export function deleteFiles(ids, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.post('files/markDeleted', { ids }, (res) => {
      if (res.status === 200) {
        // Remove the file from the current table data:
        try {
          dispatch({
            type: 'FILES_DELETED',
            payload: res.data.data.deleted
          });
        } catch (err) {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      }
    });
  };
}

export function moveFile(fileId, folderId, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.post('files/moveFile', { fileId, folderId }, (res) => {
      if (res.status === 200) {
        // Remove the file from the current table data:
        try {
          dispatch({
            type: 'FILE_DELETED',
            payload: fileId
          });
        } catch (err) {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      }
    });
  };
}

export function deleteItem(view, id, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.delete(view, { id }, (res) => {
      if (res.status === 200) {
        try {
          dispatch({
            type: 'ITEM_DELETED',
            payload: id
          });
        } catch (err) {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      }
    });
  };
}
