import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CategoryPicker from "../../Common/CategoryPicker";
import {FormControl} from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import * as tableActions from "../../../store/actions/table";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import {Trash} from "react-ionicons";
import * as eventActions from "../../../store/actions/events";

class JudgeTableRow extends React.Component {

    render() {
        let judgeMap = Array(this.props.events.competition.competition.grade.judges + 1).fill(1, 0);
        let roundMap = Array(this.props.events.competition.competition.grade.rounds + 1).fill(1, 0);

        let judges = this.props.events.competition.competition.grade.judges;
        let {r1Rel, i, self, comp, setFieldValue, editing} = this.props;


        return (
            <tr key={r1Rel[0]?._id + i} className={(this.props.match.params.round === "3" && !r1Rel[0]?.recall) ? 'semi-transparent' : ''}>
                <td colSpan={4} width={400} data-tip={"Click to assign to another dancer"} onClick={e => {

                    this.props.setEditing([comp._id]);

                }}>

                    {this.props.editing.indexOf(comp._id) > -1 ?
                        <Row>
                            <Col>
                                <CategoryPicker
                                    value={{}}
                                    tabIndex={(1 * this.props.events.competition.entries.length) + 1}

                                    itemValue={"_id"}
                                    onChange={val => {


                                        if (val) {
                                            if (this.props.events.competition.entries.filter(en => en._id === val._id).length === 0) {
                                                this.props.swapDancer(val._id, comp._id, this.props.match.params.competition, this.props.auth.token._id, res => {
                                                    //console.log("RES", res);

                                                    setTimeout(() => {
                                                        try {
                                                            document.getElementById(res.rounds["1"].scores[0][0]._id).focus();
                                                            document.getElementById(res.rounds["1"].scores[0][0]._id).select();
                                                        } catch(e) {

                                                        }

                                                    }, 50);
                                                });
                                            }
                                        }



                                        //this.props.updateJudge(this.props.match.params.round, ni, val);
                                        /*
                                        setFieldValue('template', {
                                            ...this.props.events.competition.template,
                                            rounds: {
                                                ...this.props.events.competition.template.rounds,
                                                [this.props.match.params.round.toString()]: {
                                                    ...this.props.events.competition.template.rounds[this.props.match.params.round.toString()],
                                                    judges: this.props.events.competition.template.rounds[this.props.match.params.round.toString()].judges.map((item, i) => {
                                                        if (i === n - 1) {
                                                            return val;
                                                        } else {
                                                            return item;
                                                        }
                                                    })
                                                }
                                            }
                                        })
                                        */
                                    }}
                                    remote={true}
                                    remoteKey={'dancers'}
                                    urlRoute={`/searchDancerNumber?competition=${this.props.match.params.competition}&event=${this.props.match.params.id}`}
                                    displayValue={"dancer.name"}
                                    displayValue3={""}
                                    imageKey={"image"}
                                    localState={true}
                                    displayValue2={"dancer.lname"}
                                    showDelete={false}
                                    onDelete={e => {
                                        this.props.setEditing([])
                                    }}
                                    showCancel={true}
                                    onCancel={e => {
                                        this.props.setEditing([])
                                    }}
                                    disableUpdateFetch={true}
                                    selectOnBlur={true}


                                />

                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col xs={3}>
                                {comp.dancer.name} {comp.dancer.lname}
                            </Col>
                            <Col xs={3}>
                                {comp.dancer.school ? comp.dancer.school.name : ''}
                            </Col>
                            <Col xs={4}>
                                <div className={"text-center"}>
                                    <b>
                                        {comp.dancerNumber || 0}
                                    </b>
                                </div>
                            </Col>
                            <Col xs={2}>
                                <Button variant={'flat'} className={"btn-small-padding"} onClick={(e) => {

                                    e.stopPropagation();
                                    e.preventDefault();
                                    // Remove dancer from comp
                                    this.props.removeDancer(comp._id, comp.competition._id, this.props.auth.token._id, null)

                                }}><Trash fontSize={'18px'} {...{color: 'rgba(0,0,0,.5)'}} /></Button>
                            </Col>
                        </Row>
                    }

                    {
                        /*
                        <Col xs={3}>
                        <FormControl
                            disabled={this.props.locked}
                            type="checkbox"
                            name="recall"
                            checked={r1Rel[0] ? r1Rel[0].wmh || false : false}
                            onChange={e => {
                                this.props.updateEntryScore(r1Rel[0], 3, "wmh", !r1Rel[0].wmh)
                            }}
                            className={"no-width float-right"}
                        />
                    </Col>
                    <Col xs={3}>
                        {comp.dancerNumber || 0}
                    </Col>
                         */
                    }
                    <Col xs={6}>
                        <div className={"text-center"}>



                        </div>

                    </Col>

                </td>
                {roundMap.map((n, ni) => {
                    //console.log("JudgeMap", n);
                    let r = this.props.match.params.round.toString();


                    let judge = parseInt(this.props.match.params.round) - 1;



                    if (ni === roundMap.length - 1) {
                        let rel = [];
                        if (parseInt(this.props.match.params.round) === 4) {
                            rel = this.props.events.competition.template.rounds["1"].scores[judge].filter((item, index) => {
                                return item.entryCompetition === comp._id;
                            });
                        } else {

                            rel = this.props.events.competition.template.rounds["1"].scores[judge].filter((item, index) => {
                                return item.entryCompetition === comp._id;
                            });



                        }

                        if (parseInt(this.props.match.params.round) === 4) {
                            return (
                                <td  key={i * ni + rel[0]._id} colSpan={ni === 3 && parseInt(this.props.match.params.round) === 4 ? 4 : 2}>
                                    <Row>
                                        <Col>

                                        </Col>
                                        <Col>

                                        </Col>
                                        <Col>

                                        </Col>
                                        <Col>

                                        </Col>
                                        <Col>

                                        </Col>
                                        <Col>

                                        </Col>
                                    </Row>

                                </td>
                            )
                        }else {
                            return (
                                <td  key={(1 + i) * (1 + ni)} width={"600"} colSpan={n === 4 && parseInt(this.props.match.params.round) === 2 ? 4 : 2}>
                                    <Row>
                                        <Col xs={6}>
                                            <TextareaAutosize

                                                tabIndex={((ni + 1))}
                                                type={"text"}
                                                className={`form-control microInput`}
                                                placeholder={"Comments"}
                                                id={rel[0] ? rel[0]._id : ''}
                                                disabled={this.props.events.competition.tabulation[`round${this.props.match.params.round}Locked`] ||
                                                    this.props.events.competition.tabulation[`round${this.props.match.params.round}SignedOff`][ni]
                                                }
                                                value={rel[0] ? rel[0]["round1Comments"] === '0' ? '' : rel[0]["round1Comments"] || '' : ''}
                                                onChange={(e) => {
                                                    let judge = parseInt(this.props.match.params.round) - 1;
                                                    let round = ("1").toString();

                                                    this.props.updateComments(rel[0], e.target.value, judge, round);
                                                    setFieldValue('template', {
                                                        ...this.props.events.competition.template,
                                                        // Loop through the rounds in the rounds object and assign the new comment value whenever the correct
                                                        // id comes up. The same record exists in every round so we have to update them all or else blank/old
                                                        // values in the round 2 and 3 copies of the object will overwrite the new value when saving
                                                        rounds: Object.keys(this.props.events.competition.template.rounds).forEach((key, idx) => {
                                                            this.props.events.competition.template.rounds[key] = {
                                                                    ...this.props.events.competition.template.rounds[key],
                                                                    scores: this.props.events.competition.template.rounds[key].scores.map((score, index) => {
                                                                        return score.map(scoreItem => {
                                                                            if (scoreItem._id === rel[0]._id) {
                                                                                return {
                                                                                    ...scoreItem,
                                                                                    ["round1Comments"]: e.target.value
                                                                                }
                                                                            } else {
                                                                                return scoreItem
                                                                            }
                                                                        });
                                                                    })
                                                                }
                                                            })
                                                            // ...this.props.events.competition.template.rounds,
                                                            // [r]: {
                                                            //     ...this.props.events.competition.template.rounds[r],
                                                            //     scores: this.props.events.competition.template.rounds[r].scores.map((score, index) => {

                                                            //         return score.map(scoreItem => {
                                                            //             if (scoreItem._id === rel[0]._id) {
                                                            //                 return {
                                                            //                     ...scoreItem,
                                                            //                     ["round1Comments"]: e.target.value
                                                            //                 }
                                                            //             } else {
                                                            //                 return scoreItem
                                                            //             }
                                                            //         });

                                                            //     })
                                                            // }
                                                        
                                                    })
                                                }}
                                            />

                                        </Col>

                                        <Col xs={3}>
                                                                                     <span className={"slim"}>
                                                                                         <div className={"text-center"}>

                                                                                                        {rel[0] ? (rel[0]["judgeFinalIp"] < 0 ? 'NA' : rel[0]["judgeFinalIp"].toFixed(2)) : 'NA'}

                                                                                                </div>


                                                                                    </span>
                                        </Col>
                                        <Col xs={3}>
                                            <div className={"text-center"}>
                                                <b>
                                                    {rel[0] ? (rel[0]["judgeFinalRank"] < 0 ? 'NA' : rel[0]["judgeFinalRank"]) : 'NA'}
                                                    {rel[0] ? (rel[0]["judgeTied"] ? 'T' : '') : ''}
                                                </b>
                                            </div>

                                        </Col>


                                        {/*
                                                                                    <Col xs={2}>
                                                                                        <FormControl
                                                                                            disabled={this.props.locked}
                                                                                            type="checkbox"
                                                                                            name="recall"
                                                                                            checked={r1Rel[0] ? r1Rel[0].nq || false : false}
                                                                                            onChange={e => {
                                                                                                this.props.updateEntryScore(r1Rel[0], 3, "nq", !r1Rel[0].nq)
                                                                                            }}
                                                                                            className={"no-width float-right"}
                                                                                        />
                                                                                    </Col>
                                                                                    */}
                                    </Row>

                                </td>
                            )
                        }


                    } else {
                        if (parseInt(this.props.match.params.round) === 4) {
                            return (
                                <td  key={(1 + i) * (1 + ni)} colSpan={3}>
                                    <Row>
                                        <Col xs={4}>
                                        </Col>
                                        <Col xs={4}>
                                                                                    <span
                                                                                        className={"form-control slim"}>
                                                                                    </span>
                                        </Col>
                                        <Col xs={4}>
                                                                                    <span
                                                                                        className={"form-control slim"}>
                                                                                    </span>
                                        </Col>
                                    </Row>
                                </td>
                            )
                        } else {
                            console.log("r/n", r, ni);

                            let rel = this.props.events.competition.template.rounds[(ni + 1).toString()].scores[r - 1] ? this.props.events.competition.template.rounds[(ni + 1).toString()].scores[r - 1].filter((item, index) => {
                                return item.entryCompetition === comp._id;
                            }) : [{}];


                            let className = '';
                            let score = parseFloat(rel[0] ? rel[0][`round${ni + 1}Score`] : '0');
                            console.log("S", score);

                            if (score > -1) {
                                if (score > 50) {
                                    className = 'is-valid';
                                } else {
                                    className = 'is-invalid';
                                }

                                if (score > 100) {
                                    className = 'is-invalid';
                                }
                            } else {
                                console.log("SNE", score, "not above", -1);
                            }


                            return (
                                <td  key={(1 + i) * (1 + ni)} colSpan={3} width={"100"}>
                                    <Row>
                                        <Col xs={12}>
                                            <FormControl
                                                tabIndex={((ni + 1))}
                                                type={"number"}
                                                max={100}

                                                className={`microInput ${className}`}
                                                placeholder={"Score"}
                                                id={rel[0] ? rel[0]._id : ''}
                                                disabled={this.props.events.competition.tabulation[`round${this.props.match.params.round}Locked`] ||
                                                    this.props.events.competition.tabulation[`round${this.props.match.params.round}SignedOff`][parseInt(this.props.match.params.round) - 1]
                                                }
                                                value={score > -1 ? score : ''}
                                                onChange={(e) => {
                                                    let judge = parseInt(this.props.match.params.round) - 1;
                                                    let round = (ni + 1).toString();


                                                    this.props.updateScore(rel[0], parseFloat(e.target.value), judge, round);
                                                    setFieldValue('template', {
                                                        ...this.props.events.competition.template,
                                                        rounds: {
                                                            ...this.props.events.competition.template.rounds,
                                                            [r]: {
                                                                ...this.props.events.competition.template.rounds[r],
                                                                scores: this.props.events.competition.template.rounds[r].scores.map((score, index) => {

                                                                    return score.map(scoreItem => {
                                                                        if (scoreItem._id === rel[0]._id) {
                                                                            return {
                                                                                ...scoreItem,
                                                                                ["round" + r + "Score"]: e.target.value
                                                                            }
                                                                        } else {
                                                                            return scoreItem
                                                                        }
                                                                    });

                                                                })
                                                            }
                                                        }
                                                    })
                                                }}
                                            />
                                        </Col>




                                        {/*}
                                                                                    <Col xs={4}>
                                                                                    <span
                                                                                        className={"form-control slim"}>
                                                                                        {rel[0] ? rel[0]["round" + r + "Rank"] || 0 : 0}
                                                                                    </span>
                                                                                    </Col>

                                                                                    <Col xs={4}>
                                                                                    <span
                                                                                        className={"form-control slim"}>
                                                                                        {rel[0] ?  rel[0]["round" + r + "Ip"] || 0 : 0}
                                                                                    </span>
                                                                                    </Col>
                                                                                    */}

                                    </Row>
                                </td>
                            )
                        }
                    }
                })}

                {/*parseInt(this.props.match.params.round) === 2 ?

                                                                <td>
                                                                    <FormControl
                                                                        disabled={this.props.locked}
                                                                        type="checkbox"
                                                                        name="recall"
                                                                        checked={r1Rel[0].recall || false}
                                                                        onChange={e => {
                                                                            this.props.updateEntryScore(r1Rel[0], 2, "recall", !r1Rel[0].recall)
                                                                        }}
                                                                        className={"no-width float-right"}
                                                                    /></td>
                                                                : null*/}
            </tr>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user,
    events: state.events
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    removeDancer: (entryCompetitionId, competition, token, cb) => {
        dispatch(eventActions.tabulatorRemoveDancer(entryCompetitionId, competition, token, cb));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(JudgeTableRow));

class JudgeTableRowContainer extends React.Component {
    render() {
        return (
                <Connected {...this.props} />

        )
    }
}

export default JudgeTableRowContainer;