/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 11/1/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import {FormControl} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {Formik} from "formik";
import * as yup from "yup";
import CategoryPicker from "../../Common/CategoryPicker";
import * as eventActions from "../../../store/actions/events";
import {ArrowForward, Checkmark} from "react-ionicons";
import TextareaAutosize from 'react-textarea-autosize';
import ReactTooltip from "react-tooltip";
import JudgeTableRow from "./JudgeTableRow";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import humanizeDuration from "humanize-duration";
dayjs.extend(utc)

const schema = yup.object({
    email: yup.string().email("Please enter a valid email address").required("This field is required"),
    password: yup.string().min(8, 'Password needs to be at least 8 characters long'),
    password2: yup.mixed().test('match', 'Password does not match', function (password2) {
        return password2 === this.parent.password
    })
});


class JudgeStandardTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locked: [
                false,
                false,
                false,
                false
            ],
            items: [],
            editing: [],
            timer: null
        }
    }

    componentDidMount(props) {
        ReactTooltip.rebuild();
        if (this.props.table.view !== "") {
            //this.props.getTableData("", 0, 999, {number: 1}, this.props.auth.token._id, 'add');
        }

        document.addEventListener('keydown', (e) => {
            if (e.code === "Tab") {
                // e.preventDefault()
            }
        });

        // let self = this;
        // if (!this.state.timer) {
        //     let timer = setInterval(() => {
        //         self.props.updateTabScore(self.props.events.competition.template, self.props.events.competition.entries, self.props.auth.token.token._id, true);
        //     }, 15000);
        //     this.setState({timer: timer});
        // }

    }

    componentWillUnmount() {
        if(this.state.timer) {
            clearInterval(this.state.timer);
            this.setState({timer: null});
        }
    }

    componentDidUpdate(props, state) {
        //ReactTooltip.rebuild();
        if ((this.props.table.data.length !== this.state.items.length || this.props.table.updateCount > props.table.updateCount || this.props.table.data.length !== this.state.items.length) && this.props.table.view === "") {
            this.setState({items: this.props.table.data});
        }
    }

    emptyJudge = {
        name: 'Not',
        lname: 'Selected',
        email: ''
    }

    toggleLock = (val) => {
        let tab = this.props.events.competition.tabulation;
        tab["round" + this.props.match.params.round + "Locked"] = !tab["round" + this.props.match.params.round + "Locked"];
        this.props.updateTabulationObject(tab, this.props.auth.token.token._id);
    }

    renderSelector = () => {

        let judgeMap = Array(this.props.events.competition.competition.grade.judges + 1).fill(1, 0);
        let roundMap = Array(this.props.events.competition.competition.grade.rounds + 1).fill(1, 0);

        return (
            <tr className={(this.props.match.params.round === "3" && false) ? 'semi-transparent' : ''}>
                <td colSpan={4} width={400}>
                    <Row>


                        <Col xs={12}>
                            <CategoryPicker
                                value={{}}
                                tabIndex={(1 * this.props.events.competition.entries.length) + 1}
                                id={"dancerSelector"}
                                itemValue={"_id"}
                                onChange={val => {

                                    //console.log("Updating to", val);

                                    if (val) {
                                        if (this.props.events.competition.entries.filter(en => en._id === val._id).length === 0) {
                                            this.props.addDancer(val._id, this.props.match.params.competition, this.props.auth.token._id, res => {
                                                //console.log("RES", res);

                                                setTimeout(() => {

                                                    //document.getElementById(res.rounds["1"].scores[0][0]._id).focus();
                                                    //document.getElementById(res.rounds["1"].scores[0][0]._id).select();
                                                }, 50);
                                            });
                                        }
                                    }



                                    //this.props.updateJudge(this.props.match.params.round, ni, val);
                                    /*
                                    setFieldValue('template', {
                                        ...this.props.events.competition.template,
                                        rounds: {
                                            ...this.props.events.competition.template.rounds,
                                            [this.props.match.params.round.toString()]: {
                                                ...this.props.events.competition.template.rounds[this.props.match.params.round.toString()],
                                                judges: this.props.events.competition.template.rounds[this.props.match.params.round.toString()].judges.map((item, i) => {
                                                    if (i === n - 1) {
                                                        return val;
                                                    } else {
                                                        return item;
                                                    }
                                                })
                                            }
                                        }
                                    })
                                    */
                                }}
                                remote={true}
                                remoteKey={'dancers'}
                                urlRoute={`/searchDancerNumber?competition=${this.props.match.params.competition}&event=${this.props.match.params.id}`}
                                displayValue={"dancer.name"}
                                displayValue3={""}
                                imageKey={"image"}
                                localState={true}
                                displayValue2={"dancer.lname"}
                                showDelete={false}
                                disableUpdateFetch={true}

                            />
                        </Col>

                    </Row>
                </td>
                <td colSpan={12}></td>
                {/*parseInt(this.props.match.params.round) === 2 ?
                    <td>
                        </td>
                    : null*/}
            </tr>
        )
    }

    render() {
        let judgeMap = Array(this.props.events.competition.competition.grade.judges + 1).fill(1, 0);
        let roundMap = Array(this.props.events.competition.competition.grade.rounds + 1).fill(1, 0);

        let judges = this.props.events.competition.competition.grade.judges;


        return (
            <Formik
                validationSchema={schema}
                onSubmit={this.handleSubmit}
                enableReinitialize
                initialValues={{
                    template: this.props.events.competition.template
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting,
                      setFieldValue
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>

                        <div>
                            <div className={"tabulator-page-header"}>
                                <Row>
                                    <Col xs={9}>
                                        <h4>
                                            {this.props.events.competition ?
                                                this.props.events.competition.competition ?
                                                    this.props.events.competition.competition.grade ?
                                                        this.props.events.competition.competition.grade.rounds === 1 ?
                                                            <span>
                                                        <a href={'/app/pdfoneroundresults'} target="_blank" rel="noopener noreferrer" >Print Full Results</a> | <a href={'/app/pdfoneroundsimple'} target="_blank" rel="noopener noreferrer" >Print Wall Results</a> | <a href={'/app/pdfoneroundplacing'} target="_blank" rel="noopener noreferrer" >Print Placings</a>
                                                    </span>

                                                            : null
                                                        : null
                                                    : null
                                                : null}
                                        </h4>
                                    </Col>
                                    <Col className={"text-right"}>
                                        <button type="button" className="btn btn-flat"
                                                onClick={() => this.toggleLock(this.props.match.params.round)}>
                                            {this.props.events.competition.tabulation ? this.props.events.competition.tabulation["round" + this.props.match.params.round + "Locked"] ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="48"
                                                         fill="currentColor" className="bi bi-lock-fill" viewBox="0 0 16 16">
                                                        <path
                                                            d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>

                                                    </svg>
                                                    :
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="48"
                                                         fill="currentColor" className="bi bi-unlock-fill" viewBox="0 0 16 16">
                                                        <path
                                                            d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z"/>
                                                    </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="48"
                                                     fill="currentColor" className="bi bi-unlock-fill" viewBox="0 0 16 16">
                                                    <path
                                                        d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z"/>
                                                </svg>
                                            }
                                        </button>
                                        <div className={"autosave"}>
                                            {this.props.events.autosaved ? this.props.events.autosaved.at ?
                                                <span>Last Saved: {humanizeDuration((new Date().getTime()) - (new Date(this.props.events.autosaved.at).getTime()), { units: ['y', 'mo', 'w', 'd', 'h', 'm'], round: true })} ago {this.props.events.autosaved.type === 'autosave' ? `(autosave)` : ``}</span>
                                                : null : null}
                                        </div>
                                    </Col>
                                </Row>
                            </div>



                            <Row className={"tabulator-table-pad"}>

                                <Table striped hover bordered className={"tab-table"}>
                                    <thead>

                                    <tr>
                                        <td colSpan={4}>

                                            <b>Dancer</b>

                                        </td>
                                        <td colSpan={12}>

                                                <Row>

                                                    <Col xs={6}>
                                                        <span><b>Double Checked</b></span>
                                                        <FormControl
                                                            disabled={this.props.locked}
                                                            type="checkbox"
                                                            name="recall"
                                                            checked={this.props.events.competition.tabulation ? this.props.events.competition.tabulation["round1SignedOff"][parseInt(this.props.match.params.round) - 1] : false}
                                                            onChange={val => {
                                                                let tab = this.props.events.competition.tabulation;
                                                                tab["round1SignedOff"][parseInt(this.props.match.params.round) - 1] = !tab["round1SignedOff"][parseInt(this.props.match.params.round) - 1]
                                                                tab["round2SignedOff"][parseInt(this.props.match.params.round) - 1] = !tab["round2SignedOff"][parseInt(this.props.match.params.round) - 1]
                                                                tab["round3SignedOff"][parseInt(this.props.match.params.round) - 1] = !tab["round3SignedOff"][parseInt(this.props.match.params.round) - 1]

                                                                this.props.updateTabulationObject(tab, this.props.auth.token.token._id);
                                                            }}
                                                            className={"no-width float-right"}
                                                        />
                                                    </Col>
                                                </Row>

                                        </td>

                                        {/*parseInt(this.props.match.params.round) === 2 ?
                                        <th><a href={`/app/pdfrecall`} target="_blank" rel="noopener noreferrer" >Recall<br />printout</a></th>
                                    : null*/}
                                    </tr>

                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td colSpan={4}></td>
                                        {roundMap.map((n, ni) => {
                                            if (ni === roundMap.length - 1) {
                                                if(parseInt(this.props.match.params.round) === 2) {
                                                    return null;
                                                    return (
                                                        <td key={ni} colSpan={4}>-</td>
                                                    )
                                                } else {
                                                    return null;
                                                    return (
                                                        <td key={ni}>-</td>
                                                    )
                                                }

                                            } else {
                                                console.log("Round", parseInt(this.props.match.params.round));
                                                if (ni > 0) {

                                                    return null;
                                                    return (
                                                        <td key={ni} colSpan={2}></td>
                                                    )
                                                } else {
                                                    return (
                                                        <td key={ni} colSpan={roundMap.length > 3 ? 9 : 6}>
                                                            <Row>
                                                                <Col xs={9}>
                                                                    <CategoryPicker
                                                                        value={this.props.events.competition.template['rounds'][(ni + 1).toString()].judges[parseInt(this.props.match.params.round) - 1] ? this.props.events.competition.template['rounds'][(ni + 1).toString()].judges[parseInt(this.props.match.params.round) - 1] : {}}
                                                                        itemValue={"_id"}
                                                                        onChange={val => {

                                                                            //console.log("Updating", ni, "to", val);

                                                                            //this.props.updateJudge(this.props.match.params.round, ni, val);

                                                                            this.props.updateStandardJudge(this.props.match.params.round, ni, val);
                                                                            /*
                                                                            setFieldValue('template', {
                                                                                ...this.props.events.competition.template,
                                                                                rounds: {
                                                                                    ...this.props.events.competition.template.rounds,
                                                                                    [this.props.match.params.round.toString()]: {
                                                                                        ...this.props.events.competition.template.rounds[this.props.match.params.round.toString()],
                                                                                        judges: this.props.events.competition.template.rounds[this.props.match.params.round.toString()].judges.map((item, i) => {
                                                                                            if (i === n - 1) {
                                                                                                return val;
                                                                                            } else {
                                                                                                return item;
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                }
                                                                            })
                                                                            */
                                                                        }}
                                                                        remote={true}
                                                                        remoteKey={'users'}
                                                                        urlRoute={"/search"}
                                                                        displayValue={"name"}
                                                                        displayValue3={"email"}
                                                                        imageKey={"image"}
                                                                        localState={true}
                                                                        displayValue2={"lname"}
                                                                        showDelete={false}


                                                                    />
                                                                </Col>
                                                                <Col xs={3}>
                                                                    <FormControl
                                                                        type={"text"}

                                                                        className={"microInput"}
                                                                        placeholder={"Label"}
                                                                        disabled={this.props.events.competition.tabulation[`round${this.props.match.params.round}Locked`] ||
                                                                            this.props.events.competition.tabulation[`round${this.props.match.params.round}SignedOff`][ni]}
                                                                        value={this.props.events.competition.template.rounds[this.props.match.params.round.toString()].judges[ni] ? this.props.events.competition.template.rounds[this.props.match.params.round.toString()].judges[ni].label : ''}
                                                                        onChange={(e) => {

                                                                            this.props.updateJudgeLabel(this.props.match.params.round, ni, e.target.value);
                                                                            /*
                                                                            let r = this.props.match.params.round.toString();
                                                                            console.log("UP", this.props.events.competition.template.rounds[r]);
                                                                            setFieldValue('template', {
                                                                                ...this.props.events.competition.template,
                                                                                rounds: {
                                                                                    ...this.props.events.competition.template.rounds,
                                                                                    [r]: {

                                                                                        ...this.props.events.competition.template.rounds[r],
                                                                                        judges: this.props.events.competition.template.rounds[r].judges.map((item, itemIndex) => {
                                                                                            if (itemIndex === n - 1) {
                                                                                                return {
                                                                                                    ...item,
                                                                                                    label: e.target.value
                                                                                                }
                                                                                            } else {
                                                                                                return item;
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                }
                                                                            })
                                                                            */
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>


                                                        </td>
                                                    )
                                                }
                                            }
                                        })}
                                        <td></td>

                                        {/*parseInt(this.props.match.params.round) === 2 ?
                                            <td></td>
                                            : null*/}


                                    </tr>

                                    <tr>
                                        <td colSpan={4}>
                                            <Row>
                                                <Col xs={3}>
                                                    <b>Name</b>

                                                </Col>
                                                <Col xs={3}>
                                                    <b>School</b>

                                                </Col>
                                                <Col xs={4}>
                                                    <div className={"text-center"}>
                                                        <b>
                                                            <b>Number</b>
                                                        </b>
                                                    </div>


                                                </Col>
                                                <Col xs={2}>
                                                </Col>
                                                {/*

                                                <Col xs={3}>
                                                    <b>WMH?</b>

                                                </Col>
                                                <Col xs={3}>
                                                    <b>Number</b>

                                                </Col>
                                                */}

                                            </Row>
                                        </td>
                                        {roundMap.map((n, ni) => {
                                            if (ni === roundMap.length - 1) {
                                                return (
                                                    <td key={ni} width={300} colSpan={2}>
                                                        <Row>
                                                            <Col xs={6}>
                                                                <div className={"text-center"}>
                                                                    <b>
                                                                        <b>Comments</b>
                                                                    </b>
                                                                </div>

                                                            </Col>
                                                            <Col xs={3}>
                                                                <div className={"text-center"}>
                                                                    <b>
                                                                        <b>Total IP</b>
                                                                    </b>
                                                                </div>

                                                            </Col>
                                                            <Col xs={3}>
                                                                <div className={"text-center"}>
                                                                    <b>
                                                                        <b>Total Rank</b>
                                                                    </b>
                                                                </div>

                                                            </Col>

                                                        </Row></td>
                                                )
                                            } else {
                                                return (
                                                        <td key={ni} colSpan={3} width={100}>
                                                        <Row>
                                                            <Col xs={12}>
                                                                <b>R{ni + 1} Score</b><br />
                                                                <a href={`/app/pdfoneroundsimple/${ni + 1}/${parseInt(this.props.match.params.round)}`} target="_blank" rel="noopener noreferrer" >Rank</a>
                                                            </Col>


                                                            {/*
                                                            <Col xs={4}>
                                                                <b>IP</b>
                                                            </Col>
                                                            */}

                                                        </Row>

                                                    </td>
                                                )
                                            }

                                        })}

                                        {/*parseInt(this.props.match.params.round) === 2 ?
                                            <td><b>Recall?</b></td>
                                            : null*/}
                                    </tr>



                                    {this.props.events.competition ?
                                        this.props.events.competition.entries ?
                                            this.props.events.competition.entries.map ?
                                                this.props.events.competition.entries.map((comp, i) => {
                                                    //console.log("Mounting comp", comp);



                                                    let r1Rel = this.props.events.competition.template.rounds["1"].scores[0].filter((item, index) => {
                                                        return item.entryCompetition === comp._id;
                                                    });
                                                    let self = this;
                                                    console.log("Entry", comp);

                                                    return (
                                                        <JudgeTableRow
                                                            r1Rel={r1Rel}
                                                            self={self}
                                                            i={i}
                                                            comp={comp}
                                                            setFieldValue={setFieldValue}
                                                            touched={touched}
                                                            editing={this.state.editing}
                                                            setEditing={val => this.setState({editing: val})}
                                                            updateScore={this.props.updateScore}
                                                            updateComments={this.props.updateComments}


                                                        />
                                                    )
                                                })
                                                : null
                                            : null
                                        : null}

                                    {this.renderSelector()}

                                    </tbody>
                                </Table>


                                {/*[1, 2, 3].map(n => {
                                return (
                                    <Col className={"no-padding"}>
                                        <Table striped hover>
                                            <thead>
                                                <tr>
                                                    <th colSpan={3}>
                                                        Judge {n}
                                                    </th>
                                                </tr>

                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td>
                                                        <b>Score</b>
                                                    </td>
                                                    <td>
                                                        <b>Rank</b>
                                                    </td>
                                                    <td>
                                                        <b>IP</b>
                                                    </td>


                                                </tr>
                                                {this.props.events.competition ?
                                                    this.props.events.competition.entries ?
                                                        this.props.events.competition.entries.map ?
                                                            this.props.events.competition.entries.map((comp, i) => {
                                                                // This would be mapping over each entry object (actually this is the model EntryCompetitions, not Entry)
                                                                // Essentially, each row in the table

                                                                let r = this.props.match.params.round.toString();
                                                                let rel = this.props.events.competition.template.rounds[r].scores.filter((item, index) => {
                                                                   return item[0].entryCompetition === comp._id && item[0].judgeIndex === n;
                                                                });


                                                                if (rel.length > 0) {
                                                                    return (
                                                                        <tr>
                                                                            <td>
                                                                                <FormControl
                                                                                    type={"number"}
                                                                                    placeholder={"Score"}
                                                                                    value={rel[0][0]["round" + r + "Score"] || 0}
                                                                                    onChange={(e) => {
                                                                                        console.log("UP", this.props.events.competition.template.rounds[r]);
                                                                                        setFieldValue('template', {
                                                                                            ...this.props.events.competition.template,
                                                                                            rounds: {
                                                                                                ...this.props.events.competition.template.rounds,
                                                                                                [r]: {
                                                                                                    ...this.props.events.competition.template.rounds[r],
                                                                                                    scores: this.props.events.competition.template.rounds[r].scores.map((score, index) => {
                                                                                                        console.log("Score", score[0], rel[0][0]);
                                                                                                        return score.map(scoreItem => {
                                                                                                            if (scoreItem._id === rel[0][0]._id) {
                                                                                                                return {
                                                                                                                    ...scoreItem,
                                                                                                                    ["round" + r + "Score"]: e.target.value
                                                                                                                }
                                                                                                            } else {
                                                                                                                return scoreItem
                                                                                                            }
                                                                                                        });

                                                                                                    })
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    }}
                                                                                    className={"microInput"}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <span className={"form-control"}>
                                                                                    {rel[0][0]["round" + r + "Rank"] || 0}
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <span className={"form-control"}>
                                                                                    {rel[0][0]["round" + r + "Ip"] || 0}
                                                                                </span>
                                                                            </td>


                                                                        </tr>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <tr>
                                                                            <td>
                                                                                <FormControl
                                                                                    type={"number"}
                                                                                    placeholder={"Score"}
                                                                                    value={0}
                                                                                    className={"microInput"}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <FormControl
                                                                                    type={"number"}
                                                                                    placeholder={"Rank"}
                                                                                    value={0}
                                                                                    className={"microInput"}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <FormControl
                                                                                    type={"number"}
                                                                                    placeholder={"IP"}
                                                                                    value={0}
                                                                                    className={"microInput"}
                                                                                />
                                                                            </td>


                                                                        </tr>
                                                                    )
                                                                }

                                                                return (
                                                                    <div>{comp._id}</div>
                                                                )

                                                            })
                                                            : null
                                                        : null
                                                    : null}



                                            </tbody>

                                        </Table>
                                    </Col>

                                )
                            })*/}
                            </Row>
                            <div className={"text-right ex-pad"}>
                                <Button variant={"flat"} className={"btn-raised"} onClick={e => {
                                    e.preventDefault();

                                    let invalidCount = document.getElementsByClassName('is-invalid').length;


                                    this.props.updateTabScore(
                                        this.props.events.competition.template, 
                                        this.props.events.competition.tabulation,
                                        this.props.auth.token._id,
                                        false,
                                        this.props.events.competition.competition._id,
                                        this.props.events.competition.competition.grade.rounds);
                                }}><Checkmark /> Save</Button> &nbsp;
                                <Button variant={"primary"} onClick={e => {
                                    e.preventDefault();

                                    this.props.updateTabScore(
                                        this.props.events.competition.template, 
                                        this.props.events.competition.tabulation, 
                                        this.props.auth.token._id,
                                        false, 
                                        this.props.events.competition.competition._id,
                                        this.props.events.competition.competition.grade.rounds,
                                        res => {
                                        console.log("SAVED", res);
                                        if (res.data.status === 200) {
                                            if (parseInt(this.props.match.params.round) < 3) {
                                                this.props.history.push(`/app/tabulation/${this.props.match.params.id}/${this.props.match.params.competition}/${parseInt(this.props.match.params.round) + 1}`)
                                            } else {
                                                this.props.history.push(`/app/tabulation/${this.props.match.params.id}/${this.props.match.params.competition}/overview`)

                                            }
                                        }
                                    });
                                }}>Save & Next &nbsp; &nbsp;<ArrowForward /></Button>
                            </div>

                        </div>
                    </Form>
                )}
            </Formik>

        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user,
    events: state.events,
    single: state.single,
    tabulator: state.tabulator
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    updateTabScore: (template, tabulation, token, showAutosave, competitionId, rounds, cb) => {
        dispatch(eventActions.updateTabulatorScore(template, tabulation, token, showAutosave, competitionId, rounds, cb));
    },
    updateScore: (entry, value, judgeIndex, round) => {
        dispatch(eventActions.updateScore(entry, value, judgeIndex, round));
    },
    updateComments: (entry, value, judgeIndex, round) => {
        console.log("Round", round);
        console.log("Judge", judgeIndex);
        dispatch(eventActions.updateComments(entry, value, judgeIndex, round));
    },
    updateJudge: (round, judge, val) => {
        dispatch(eventActions.updateJudge(round, judge, val));
    },
    updateStandardJudge: (round, judge, val) => {
        dispatch(eventActions.updateStandardJudge(round, judge, val));
    },
    updateStandardScore: (entry, value, round, judgeIndex) => {
        console.log("R/J", round, judgeIndex);
        round = (parseInt(judgeIndex)).toString();
        judgeIndex = parseInt(round) - 1;
        console.log("R/J", round, judgeIndex);
        dispatch(eventActions.updateScore(entry, value, judgeIndex, round));
    },
    updateJudgeLabel: (round, judge, val) => {
        dispatch(eventActions.updateJudgeLabel(round, judge, val));
    },
    updateTabulationObject: (tab, token) => {
        dispatch(eventActions.updateTabulationObject(tab, token));
    },
    updateEntryScore: (entry, round, key, val) => {
        dispatch(eventActions.updateEntryScore(entry, round, key, val));
    },
    addDancer: (id, competition, token, cb) => {
        dispatch(eventActions.tabulatorAddDancer(id, competition, token, cb));
    },
    swapDancer: (newId, oldId, competition, token, cb) => {
        dispatch(eventActions.tabulatorSwapDancer(newId, oldId, competition, token, cb));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(JudgeStandardTable));

class JudgeStandardTableContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default JudgeStandardTableContainer;
